import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import  ReactEcharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts';
import cloneDeep from 'lodash.clonedeep';

const NotificationReports = () => {

    const datosEnvio = [
      {value:Math.round(Math.random() * 1000), name:'Enviadas y recibidas'},
      {value:Math.round(Math.random() * 1000), name:'Enviadas'},
      {value:Math.round(Math.random() * 1000), name:'Error servidor FCM'},
      {value:Math.round(Math.random() * 1000), name:'Error datos mensaje'}
    ];

    const datosVariableSO = [
        {value:34, name:'IOS'},
        {value:59, name:'Android'},
        {value:5, name:'Otros'},
    ];

    const datosVariableTopic = [
        {value:15, name:'planes_es'},
        {value:12, name:'planes_eu'},
        {value:35, name:'pl-1'},
        {value:24, name:'pl-30'},
    ];

    const datosVariableAnalytic = [
        {value:50, name:'UsuariosNokia3310'},
        {value:50, name:'UsuariosAlcatelOneTouch'},
        {value:50, name:'UsuariosBlackBerry'},
        {value:50, name:'EnvioIndividualBack'},
        {value:50, name:'EnvioMulticastBack'},
    ];

    const DEFAULT_OPTION = {
        title : {
          text: 'Estadísticas notificaciones por estado',
          subtext: '02 de Octubre de 2023\n\nNº registros: 2458',
          x:'center'
        },
        tooltip : {
        //   trigger: 'item',
          showContent: false
        //   formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: datosEnvio,
        },
        series : [
          {
          name: 'Notificaciones',
          type: 'pie',
          radius : '55%',
          center: ['50%', '60%'],
          label: {
            formatter: '{b}: {c} ({d}%)'
          },
          data: datosEnvio,
          animationEasing: 'lineal',
          animationDelay: function (idx) {
            return idx * 2;
          },
          animationDelayUpdate: function (idx) {
            return idx * 100;
          },
        //   animationDelay: function (idx) {
        //     return idx * 100;
        //   },
          itemStyle: {
            emphasis: {
            // shadowBlur: 10,
            // shadowOffsetX: 0,
            // shadowColor: 'rgba(0, 0, 0, 0.5)'
                focus: 'self'
            }
          }
          }
        ],                
        // animationEasing: 'lineal',
        // animationDelayUpdate: function (idx) {
        //   return idx * 100;
        // },
        // stateAnimation: {
        //     duration: 300,
        //     easing: "cubicOut"
        // }
      };

    const [option, setOption] = useState(DEFAULT_OPTION);

    const [datosGrafico, setDatosGrafico] = useState({
        // Datos iniciales para el gráfico
        optionPieChart: {
            title : {
              text: 'Estadísticas notificaciones por estado',
              subtext: '02 de Octubre de 2023\n\nNº registros: 2458',
              x:'center'
            },
            tooltip : {
            //   trigger: 'item',
              showContent: false
            //   formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
              orient: 'vertical',
              left: 'left',
              data: datosEnvio,
            },
            series : [
              {
              name: 'Notificaciones',
              type: 'pie',
              radius : '55%',
              center: ['50%', '60%'],
              label: {
                formatter: '{b}: {c} ({d}%)'
              },
              data: datosEnvio,
              animationEasing: 'elasticOut',
              animationDelay: function (idx) {
                return idx * 10;
              },
              animationDelayUpdate: function (idx) {
                return idx * 10;
              },
            //   animationDelay: function (idx) {
            //     return idx * 100;
            //   },
              itemStyle: {
                emphasis: {
                // shadowBlur: 10,
                // shadowOffsetX: 0,
                // shadowColor: 'rgba(0, 0, 0, 0.5)'
                    focus: 'self'
                }
              }
              }
            ],                
            // animationEasing: 'elasticOut',
            // animationDelayUpdate: function (idx) {
            //   return idx * 5000;
            // },
            // stateAnimation: {
            //     duration: 300,
            //     easing: "cubicOut"
            // }
          }
    
      });    



      const [count, setCount] = useState(0);

      function onChartReady(echarts) {
        console.log('echarts is ready', echarts);
      }
    
      function onChartClick(param, echarts) {
        console.log(param, echarts);
        setCount(count + 1);
      };
    
      function onChartLegendselectchanged(param, echarts) {
        console.log(param, echarts);
      };


      const [showLastConection, setShowLastConection] = useState(false);
    
      const handleCheckLastConectionChange = (event) => {
          setShowLastConection((showLastConection) => !showLastConection);
      };

      const onClickFiltrar = (event) => {
        actualizarDatosGrafico2();
      }

    const [activarSelectTopic, setActivarSelectTopic] = useState(false);
    const [activarSelectSO, setActivarSelectSO] = useState(false);
    const [activarSelectAnalytic, setActivarSelectAnalytic] = useState(false);

    const comprobarActivarSelectEnvio = (variableSelectValue) => {

        switch (variableSelectValue) {
            case 'topic':
                setActivarSelectSO(false)
                setActivarSelectTopic(true);
                setActivarSelectAnalytic(false);                
              break;
            case 'so':
                setActivarSelectSO(true)
                setActivarSelectTopic(false);
                setActivarSelectAnalytic(false);
              break;
            case 'analytic':
                setActivarSelectSO(false)
                setActivarSelectTopic(false);
                setActivarSelectAnalytic(true);
              break;
            default:
                setActivarSelectTopic(false);
                setActivarSelectSO(false);
                setActivarSelectAnalytic(false);
                break;
          }

    };

    const [selectedDatoResultadoEnvioOption, setSelectedDatoResultadoEnvioOption] = useState('');
    const handleSelectedDatoResultadoEnvioOption = (event) => {
        const selectedValue = event.target.value;
        setSelectedDatoResultadoEnvioOption(selectedValue);  
    };

    const [selectedTipoEstadisticaOption, setSelectedTipoEstadisticaOption] = useState('');
    const handleSelectTipoEstadisticaChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTipoEstadisticaOption(selectedValue);
        
        if(selectedValue == "envio"){
            comprobarActivarSelectEnvio(selectedVariableOption);
        } else {
            setActivarSelectTopic(false);
            setActivarSelectSO(false);
            setActivarSelectAnalytic(false);
        }
        
    };

    const [selectedVariableOption, setSelectedVariableOption] = useState('');
    const handleSelectVariableChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedVariableOption(selectedValue);
        
        if(selectedTipoEstadisticaOption == "envio"){
            comprobarActivarSelectEnvio(selectedValue);
        }

      };

      const actualizarDatosGrafico2 = () => {
        const newOption = cloneDeep(option);

        if(selectedTipoEstadisticaOption == 'variable'){
            if(selectedVariableOption == 'so'){
                newOption.title.text = 'Envío notificaciones por SO';
                newOption.series[0].data = datosVariableSO;
                newOption.legend.data = datosVariableSO;
            } else if(selectedVariableOption == 'topic'){
                newOption.title.text = 'Envío notificaciones por Topic';
                newOption.series[0].data = datosVariableTopic;
                newOption.legend.data = datosVariableTopic;
            } else if(selectedVariableOption == 'analytic'){
                newOption.title.text = 'Envío notificaciones por Analytic label';
                newOption.series[0].data = datosVariableAnalytic;
                newOption.legend.data = datosVariableAnalytic;
            }
        } else if(selectedTipoEstadisticaOption == 'envio'){
            if(selectedVariableOption == 'so'){
                newOption.title.text = 'Resultado envío notificaciones SO: ' + selectedDatoResultadoEnvioOption;
            } else if(selectedVariableOption == 'topic'){
                newOption.title.text = 'Resultado envío notificaciones Topic: ' + selectedDatoResultadoEnvioOption;
            }else if(selectedVariableOption == 'analytic'){
                newOption.title.text = 'Resultado envío notificaciones Analytic label: ' + selectedDatoResultadoEnvioOption;
            }
            const datosRandomizados = [
              {value:Math.round(Math.random() * 1000), name:'Enviadas y recibidas'},
              {value:Math.round(Math.random() * 1000), name:'Enviadas'},
              {value:Math.round(Math.random() * 1000), name:'Error servidor FCM'},
              {value:Math.round(Math.random() * 1000), name:'Error datos mensaje'}
            ];

            newOption.series[0].data = datosRandomizados;
            newOption.legend.data = datosRandomizados;
        }
        
        setOption(newOption);
      };

      const actualizarDatosGrafico = () => {
        // Nuevos datos para el gráfico
        const nuevosDatos = {
            optionPieChart: {
                title : {
                  text: 'Estadísticas notificaciones por estado',
                  subtext: '02 de Octubre de 2023\n\nNº registros: 354',
                  x:'center'
                },
                tooltip : {
                //   trigger: 'item',
                  showContent: false
                //   formatter: "{a} <br/>{b} : {c} ({d}%)"
                },
                legend: {
                  orient: 'vertical',
                  left: 'left',
                  data: datosEnvio,
                },
                series : [
                  {
                  name: 'Notificaciones',
                  type: 'pie',
                  radius : '55%',
                  center: ['50%', '60%'],
                  label: {
                    formatter: '{b}: {c} ({d}%)'
                  },
                  data: datosEnvio,
                  animationDelay: function (idx) {
                    return idx * 100;
                  },
                  itemStyle: {
                    emphasis: {
                    // shadowBlur: 10,
                    // shadowOffsetX: 0,
                    // shadowColor: 'rgba(0, 0, 0, 0.5)'
                        focus: 'self'
                    }
                  },
                //   animationEasing: 'elasticOut',
                //   animationDelay: 5000,
                //   animationDelayUpdate: 1000,
                animation: true, // Habilita la animación
                animationType: 'scale', // Tipo de animación de entrada (puede ser 'fadeIn', 'scale', etc.)
                animationDuration: 1000, // Duración de la animación en milisegundos
                animationEasing: 'cubicInOut', // Función de interpolación para la animación
                  }
                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: function (idx) {
                  return idx * 5000;
                },
                stateAnimation: {
                    duration: 300,
                    easing: "cubicOut"
                }

              }
        };
        setDatosGrafico(nuevosDatos);
      };


    return (
        <>
            <div className='mainDiv2' style={{paddingTop: 25, paddingBottom: 25}}>

                <label className="title"> 
                    <FormattedMessage id="notificacionesEstadisticas.titulo.estadisticas" defaultMessage="Estadísticas notificaciones enviadas" />
                </label>

                {/* <div style={{ marginTop: 40}}>
                    <ReactEcharts
                        option={optionBarChart}
                        style={{ height: 400 }}
                        opts={{ renderer: 'svg' }}
                    />
                </div> */}

                <div className='ui form' style={{marginTop: 15, marginLeft: 15, display: 'flex', flexWrap: 'wrap'}}>

                {showLastConection ? (
                    <>
                      <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 20}}>
                          <label htmlFor='fechaDesde'>Fecha desde:</label>
                          <input 
                              id='fechaDesde'
                              style={{marginLeft: 10, width:150}}
                              type="date" 
                              value=""
                              placeholder=""/>
                      </div>

                      <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                          <label htmlFor='fechaHasta'>Fecha hasta:</label>
                          <input 
                              id='fechaHasta'
                              style={{marginLeft: 10,  width:150}}
                              type="date" 
                              value=""
                              placeholder=""/>
                      </div>
                    </>
                 ) : 
                <>
                  <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                  <label htmlFor='fechaDesde'>Fecha:</label>
                  <input 
                      id='fechaDesde'
                      style={{marginLeft: 10, width:150}}
                      type="date" 
                      value=""
                      placeholder=""/>
                  </div>
                </>
                 }

                    <div  style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 30}}>
                        <label>
                            <input 
                            onChange={handleCheckLastConectionChange}
                            checked={showLastConection}
                            style={{marginRight: 5, marginTop: 3}}
                            type="checkbox" 
                            />
                            Rango de fechas
                        </label>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                        <label htmlFor='filtroDatos' style={{marginRight:10}} >Dato:</label>
                        <select id='filtroDatos' className="ui select" style={{width:200}} 
                            defaultValue="" value={selectedVariableOption} onChange={handleSelectVariableChange}>
                            <option key="0"  value="">Seleccione un dato</option>
                            {/* <option key="1" value="general">Resultados envío</option> */}
                            <option key="1" value="so">SO</option>
                            <option key="2" value="topic">Topic</option>
                            <option key="3" value="analytic">Analytic label</option>
                        </select>
                    </div> 

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                        <label htmlFor='filtroDatos' style={{marginRight:10}} >Tipo estadística:</label>
                        <select id='filtroDatos' className="ui select" style={{width:270}} 
                            defaultValue="" value={selectedTipoEstadisticaOption} onChange={handleSelectTipoEstadisticaChange}>
                            <option key="0"  value="">Seleccione un tipo de estadística</option>
                            {/* <option key="1" value="general">Resultados envío</option> */}
                            <option key="1" value="variable">Dato seleccionado</option>
                            <option key="2" value="envio">Resultados envío dato seleccionado</option>
                        </select>
                    </div> 

                    {activarSelectTopic && (
                    <>
                      <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                          <label htmlFor='servicio' style={{marginRight:10}}>Topic: </label>
                          <select id='servicio' className="ui select" style={{width:200}} 
                              defaultValue="" value={selectedDatoResultadoEnvioOption} onChange={handleSelectedDatoResultadoEnvioOption}>
                              <option key="0"  value="Seleccione un SO">Seleccione un topic</option>
                              <option key="1" value="planes_es">planes_es</option>
                              <option key="2" value="planes_eu">planes_eu</option>
                              <option key="3" value="pl-1">pl-1</option>
                              <option key="4" value="pl-30">pl-30</option>
                          </select>
                      </div> 
                    </>
                    )}

                    {activarSelectSO && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                            <label htmlFor='servicio' style={{marginRight:10}}>SO: </label>
                            <select id='servicio' className="ui select" style={{width:200}} 
                               defaultValue="" value={selectedDatoResultadoEnvioOption} onChange={handleSelectedDatoResultadoEnvioOption}>
                                <option key="0"  value="Seleccione un SO">Seleccione un SO</option>
                                <option key="1" value="Android">Android</option>
                                <option key="2" value="IOS">IOS</option>
                                <option key="2" value="Otros">Otros</option>
                            </select>
                        </div> 
                    </>
                    )}

                    {activarSelectAnalytic && (
                    <>
                      <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                          <label htmlFor='servicio' style={{marginRight:10}}>Analytic label: </label>
                          <select id='servicio' className="ui select" style={{width:230}} 
                              defaultValue="" value={selectedDatoResultadoEnvioOption} onChange={handleSelectedDatoResultadoEnvioOption}>
                              <option key="0"  value="Seleccione un SO">Seleccione una etiqueta</option>
                              <option key="1" value="UsuariosNokia3310">UsuariosNokia3310</option>
                              <option key="1" value="UsuariosAlcatelOneTouch">UsuariosAlcatelOneTouch</option>
                              <option key="2" value="UsuariosBlackBerry">UsuariosBlackBerry</option>
                              <option key="2" value="EnvioIndividualBack">EnvioIndividualBack</option>
                              <option key="2" value="EnvioMulticastBack">EnvioMulticastBack</option>
                          </select>
                      </div> 
                    </>
                    )}
                </div>
 
                <div style={{ marginTop:25, marginLeft:14, marginBottom: 35}}>
                    <button className="ui red button" onClick={() => onClickFiltrar()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Mostrar" />
                    </button>
                    <button className="ui noBorder button">
                        <FormattedMessage id="notificaciones.limpiar" defaultMessage="Limpiar filtro" />
                    </button>
                </div>

                <div style={{ marginTop: 40, marginLeft: 15}}>
                    <ReactEcharts   
                        //showLoading='default'
                        // option={datosGrafico.optionPieChart}
                        option={option}
                        style={{ height: 400 }}
                        onChartReady={onChartReady}
                        //notMerge={false}
                        //lazyUpdate={true}
                        //echarts={echarts}
                        onEvents={{
                        'click': onChartClick,
                        //'legendselectchanged': onChartLegendselectchanged
                        }}                   
                    />
                </div>


            </div>
        </>
    );
};

export default NotificationReports;