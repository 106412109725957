import React from 'react';
import UserService from "../services/UserService";


const AnonymousHome = () => {

    return (
        <div className="anonymous-welcome">
            <div className="prompt">
                <h1 className="ui header">Welcome to the Lantik BizkaiApp Backoffice</h1>
                <p className="ui header">Please authenticate yourself!</p>
                <button className="ui button colored red" onClick={() => UserService.doLogin()}>Login</button>
            </div>
        </div>
    );
};

export default AnonymousHome;