import UserService from './UserService';
import axios from "axios";

export const requestGetNewSearch = async (state, page, size, beachName, cityName) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        } 
    })
    
   await authAxios.get(process.env.REACT_APP_URL_FULL_BEACHES, { params: {page: page, size: size, beachName: beachName, cityName: cityName}})
   .then(response =>  {
       state(response.data);
    }).catch(error  =>  {
        console.log(error);
    }) 

}



export const requestGetBeaches = async (state, setIconsClasses, id) => {
   
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        }  
    })
 
   await authAxios.get(process.env.REACT_APP_URL_FULL_BEACHES+`/${id}`,id)
   .then(response =>  {
        state(response.data);
        setIconsClasses(response.data);
   }).catch(error  =>  {
        console.log(error);
    }) 
}

export const requestUpdateBeaches = async (body, id) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    await authAxios.post(process.env.REACT_APP_URL_FULL_BEACHES+`/${id}`, body )
    .catch(error  =>  {
        console.log(error);
    }) 
}

export const requestGetConfiguration = async (state) => {
   
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES + '/configuration';
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        }  
    })
 
   await authAxios.get(process.env.REACT_APP_URL_FULL_BEACHES + '/configuration')
   .then(response =>  state(response.data))
   .catch(error  =>  {
        console.log(error);
    }) 

}

export const requestUpdateConfiguration = async (body) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES + '/configuration';
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    await authAxios.post(process.env.REACT_APP_URL_FULL_BEACHES + '/configuration', body)
    .catch(error  =>  {
        console.log(error);
    }) 
}
