import React from 'react';
import {FormattedMessage} from 'react-intl';

const Intro = () => {
    return (
        <div>
            <h1><FormattedMessage id="intro.titulo" defaultMessage="Bienvenido al backoffice de BizkaiUp"/></h1>
        </div>
    );
};

export default Intro;