import UserService from "./UserService";
import axios from "axios";



export const requestGetNewSearch = async (state, otherState, page, size, sort, status, search) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        } 
    })

   await authAxios.get(process.env.REACT_APP_URL_FULL_NEWS, { params: {page: page, size: size, sort: sort, status: status, search: search }})
   .then(response =>  {
       state(response.data);
       otherState(response.data);
    }).catch(error  =>  { 
        console.log(error);
    }) 

}

export const requestGetNew = async (state) => {
   
    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })
 
   await authAxios.get(process.env.REACT_APP_URL_FULL_NEWS)
   .then(response =>  state(response.data))
   .catch(error  =>  {
        console.log(error);
    }) 
}



export const requestGetNewById =  async (state,id) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })
   await authAxios.get(process.env.REACT_APP_URL_FULL_NEWS+`/${id}`,id)
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
}
 
 
 
 export const requestGetNewPublic =  async (state,status) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            //Fix auth problem
            "Content-Security-Policy": "frame-ancestors none",
            //
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
   await authAxios.get(process.env.REACT_APP_URL_FULL_NEWS, { params: {status: status}})
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
}
 
var idNew;

export const requestPostNew = (body) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.post(process.env.REACT_APP_URL_FULL_NEWS, body)
    .then(response => {idNew = response.data.id})
    .catch(error  =>  {
        console.log(error);
    })
    
}

export const newId = () => {
    return idNew;
}




 export const RequestPutNew = async (id,data) => {
 

    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })


    await authAxios.put(process.env.REACT_APP_URL_FULL_NEWS+`/${id}`, data)
    .then(response => console.log(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
}


export const requestDeleteNew =  (id,state,otherState) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.delete(process.env.REACT_APP_URL_FULL_NEWS+`/${id}`,state)
    .then(response => requestGetNewSearch(state, otherState, 0, 20)) 
    .catch(error  =>  {
        console.log(error);
    }) 
}


export const getTotalNumberOfFiles  = async (state, status, search ) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_NEWS;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Security-Policy": "frame-ancestors none",
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
    await authAxios.get(process.env.REACT_APP_URL_FULL_NEWS + "/numNews", { params: {status: status, search:search}})
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error)   
    }) 

}

  



