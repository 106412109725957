
import data from './data';
import columns from './columns';
import React, {useMemo, useState} from 'react';
import { MaterialReactTable} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FormattedMessage } from 'react-intl';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Box, Button } from '@mui/material';
import Swal from 'sweetalert2'; 

import { NavLink } from 'react-router-dom';
import * as Constantes from '../../helpers/constantes';


import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';


const AppTexts = () => {
    

    const onClickFiltrar = (event) => {

    }

    const [selectedRowId, setSelectedRowId] = useState(null);
    const [disableButton, setDisabledButton] = useState(true);
    

    const handleRowClick = (row) => {
        // Deselecciona la fila anteriormente seleccionada si existe
        console.log("Selected row.id = " + row.id);
        if (selectedRowId === row.id) {
            setSelectedRowId(null);
            setDisabledButton(true);
        } else {
            setSelectedRowId(row.id); // Selecciona la nueva fila
            setDisabledButton(false);
        }
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    
    const onClickConsultar = (event) => {
        setOpen(true);
    }

    const onClickCrear= (event) => {

    }

    const onClickModificar = (event) => {
        
    }

    const onClickEliminar = (event) => {
        modalEliminar();
    }

    function modalEliminar(){
        Swal.fire({
            title: "¿Está seguro de que desea eliminar el texto?",
            //text: "no se podrán deshacer!",
            icon: "question",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#3085d6",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            cancelButtonColor: "#d33",
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#3085d6",
                icon: "success",
                title: "Completado",
                text: "El texto se ha eliminado correctamente",
              })
            }
          })
    }

    const [espana, setEspana] = useState(true);
  
    const handleChange = () => {
      setEspana(!espana);
    };

    return (
    <>
        {/* Contenedor principal */}
        <div className='mainDiv' style={{paddingTop: 25, paddingRight: 15}}>

            {/* Titulo filtro dispositivos */}
            <label className="title"> 
                <FormattedMessage id="appTexts.titulo.filtrar" defaultMessage="Gestión de textos de la App" />
            </label>

            <div className='ui form' style={{marginTop: 15, marginLeft: 15, display: 'flex', flexWrap: 'wrap'}}>



  

                {/* Filtro ID */}
                <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                    <label htmlFor='id'>ID:</label>
                    <input 
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 250}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value=""
                        placeholder=""/>
                </div>

                {/* Filtro SO */}
                <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                    <label htmlFor='servicio' style={{marginRight:10}} >Idioma:</label>
                    <select id='servicio' className="ui select" style={{width:185}} 
                        value="" defaultValue="" name="selectTown">
                        <option key="0"  value="Seleccione un SO">Seleccione un idioma</option>
                        <option key="1" value="Android">Español</option>
                        <option key="2" value="IOS">Euskera</option>
                    </select>
                </div> 

                {/* Filtro Analytic Label */}
                {/* <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                    <label htmlFor='id'>Texto:</label>
                    <input 
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 500}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value=""
                        placeholder=""/>
                </div>  */}

                {/* Descripcion notificacion */}
                <div className='input-column'>
                    <label htmlFor='descripcion'>
                        <FormattedMessage 
                            id="notificationSending.label.textof" 
                            defaultMessage="Texto:" />                        
                    </label>
                    <textarea 
                        id=""
                        name=""
                        value=""

                        style={{resize: 'none'}}
                        rows="3" 
                        cols="50" 
                    />

                </div>

            </div>

            {/* Botones filtro dispositivos */}    
            <div style={{ marginTop:25, marginLeft:14, marginBottom: 35}}>
                <button className="ui red button" onClick={() => onClickFiltrar()}>
                    <FormattedMessage id="notificaciones.buscar" defaultMessage="Filtrar" />
                </button>
                <button className="ui noBorder button">
                    <FormattedMessage id="notificaciones.limpiar" defaultMessage="Limpiar filtro" />
                </button>
            </div>

            {/* Titulo listado notificaciones */}
            <label style={{marginLeft: 10, fontSize: "24px", fontWeight: "bold", color: '#4C4C4C'}}> 
                <FormattedMessage id="appTexts.titulo.listado" defaultMessage="Listado de textos de la App" />
            </label>


            {/* Tabla */}
            <div style={{ marginTop:10, marginLeft:10, marginBottom: 15}}>
            <MaterialReactTable
                columns={columns} 
                data={data}
                enableDensityToggle={false}
                localization={MRT_Localization_ES}
                // enableRowSelection={true}
                // enableSelectAll={false}
                enableMultiRowSelection={false}
                positionToolbarAlertBanner='none'
                getRowId={(row) => row.userId}
                muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => handleRowClick(row),
                    selected: row.id === selectedRowId,
                    sx: {
                    cursor: 'pointer',
                    },
                })}
                state={{ selectedRowId }}                  
                initialState={{
                        columnVisibility: { idpAlias: false },
                        pagination: { pageSize: 5, pageIndex: 0 },
                        density: 'compact',
                        sorting: [
                            {
                              id: 'id', //sort by age by default on page load
                              desc: true,
                            },
                          ],
                }}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#4C4C4C'
                    },
                    }}
                muiTableBodyCellProps={({ cell }) => ({
                    onClick: (event) => {
                    console.info(event, cell.id);
                    var columna = cell.id.split("_")[1];
                    console.log("CELL VALUE: " + cell.getValue());
                    //setShowModal(true);
                    },
                })}
                renderTopToolbarCustomActions={({ table }) => {
                    return (
                        <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'flex-start', paddingTop: 5 }}>
                            <NavLink to={`appTextsForm/${Constantes.ACTION_CREAR}`}> 
                                <Button
                                    // disabled={disableButton}
                                    className="table-button-red"
                                    size='small'
                                    onClick={onClickCrear}
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                >
                                    Crear
                                </Button>
                            </NavLink> 

                            {/* <NavLink to={`appTextsForm/${Constantes.ACTION_CONSULTAR}`}> 
                                <Button
                                    disabled={disableButton}
                                    className="table-button-red"
                                    size='small'
                                    onClick={onClickConsultar}
                                    startIcon={<SearchIcon />}
                                    variant="contained"
                                >
                                    Consultar
                                </Button>
                            </NavLink> */}

                            <NavLink to={`appTextsForm/${Constantes.ACTION_MODIFICAR}`}> 
                                <Button
                                    disabled={disableButton}
                                    className="table-button-red"
                                    size='small'
                                    onClick={onClickModificar}
                                    startIcon={<EditIcon />}
                                    variant="contained"
                                >
                                    Modificar
                                </Button>
                            </NavLink>

                            <Button
                                disabled={disableButton}
                                className="table-button-red"
                                size='small'
                                onClick={onClickEliminar}
                                startIcon={<DeleteIcon />}
                                variant="contained"
                            >
                                Eliminar
                            </Button>
                        </div>
                    );
                    }}

            />
            </div>
                


        </div>  
    </>
    );
};

export default AppTexts;