import UserService from './UserService';
import axios from "axios";


    export const requestGetSectionBlock= async (state) => {

        const apiUrl = process.env.REACT_APP_URL_FULL_SECTIONBLOCK;
        const authAxios = axios.create ({
            baseURL: apiUrl,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + UserService.getToken()
        
            }  
        })
        
        await authAxios.get(process.env.REACT_APP_URL_FULL_SECTIONBLOCK)
        .then(response =>  state(response.data))
        .catch(error  =>  {
            console.log(error);
        }) 

    }

    export const requestPutSectionBlock = (id,body) => {

        console.log("url: " + process.env.REACT_APP_URL_FULL_SECTIONBLOCK);
        console.log("id: " + id);
        console.log("body: " + body );
        console.log(body);
        console.log("---");

        const apiUrl = process.env.REACT_APP_URL_FULL_SECTIONBLOCK;
        const authAxios = axios.create ({
            baseURL: apiUrl,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + UserService.getToken()
        
            }  
        })
    
        authAxios.put(process.env.REACT_APP_URL_FULL_SECTIONBLOCK +`/${id}`, body)
        .then(response => console.log(response.data))
        .catch(error  =>  {
            console.log(error);
        }) 
    }


    export const updateSectionBlock = (id,body) => {
        console.log("updateSectionBlock()");

        console.log("url: " + process.env.REACT_APP_URL_FULL_SECTIONBLOCK);
        console.log("id: " + id);
        console.log("body: " + body );
        console.log(body);
        console.log("---");
      
        const apiUrl = process.env.REACT_APP_URL_FULL_SECTIONBLOCK;
        const request = axios.create ({
            baseURL: apiUrl,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + UserService.getToken()
            }  
        });
      
        return request.put(process.env.REACT_APP_URL_FULL_SECTIONBLOCK +`/${id}`, body);
      }


      export const getSectionBlock = () => {

        const apiUrl = process.env.REACT_APP_URL_FULL_SECTIONBLOCK;
        const authAxios = axios.create ({
            baseURL: apiUrl,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + UserService.getToken()
        
            }  
        });
        
        return authAxios.get(process.env.REACT_APP_URL_FULL_SECTIONBLOCK);
    }