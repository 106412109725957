import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import {requestGetNewById} from '../../services/NewsService';
import es from "date-fns/locale/es"; 
import axios from 'axios';
import UserService from '../../services/UserService'
import "react-datepicker/dist/react-datepicker.css";
import SelectFile from "../media/SelectFile"
import SelectNews from "../featured/SelectNews"
import {FormattedMessage} from 'react-intl';
import { requestGetListFeatured } from '../../services/FeaturedService';


const CreateFeature = () => {
    let selImage = "";
    let selNews = "";

    const [type, setType] = useState();
    const [titleEs, setTitleEs] = useState();
    const [titleEu, setTitleEu] = useState();
    const [linkUrlEs, setLinkUrlEs] = useState("");
    const [linkUrlEu, setLinkUrlEu] = useState("");
    const [news, setNews] = useState("");
    const [fun, setFun] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [active, setActive] = useState("");
    const [priority, setPriority] = useState("");
    const [txtAndroid, setTxtAndroid] = useState("");
    const [txtIos, setTxtIos] = useState("");
    const [publishingDate, setPublishingDate] = useState();
    const [expirationDate, setExpirationDate] = useState();
    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    const [hideNoticiaComponent, setHideNoticiaComponent] = useState(true);
    const [hideOtroComponent, setHideOtroComponent] = useState(true);
    const [hideVideoComponent, setHideVideoComponent] = useState(true);
    const [hideAppComponent, setHideAppComponent] = useState(false);
    const [hideFunComponent, setHideFunComponent] = useState(true);
    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [hideGaleryComponent, setGaleryComponent] = useState(true);
    const [hideNewsComponent, setNewsComponent] = useState(true);
    const [hideImageComponent, setHideImageComponent] = useState(true);
    const [hideImageOptionsComponent, setHideImageOptionsComponent] = useState(true);
    const [hideNoImageComponent, setHideNoImageComponent] = useState(false);
    const [show, setShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [featured, setFeatured] = useState([]);
    


   
    
    let history = useHistory();
    

    React.useEffect(() => {
        requestGetNewById(setNews, news);
        requestGetListFeatured(setFeatured);
        registerLocale("es", es);
        setImageUrl(selImage)
    },[selImage])

   console.log(featured);
    
    function validateValues(){
        
        let valError = false;
        let valErrorLink = false;
        if( type === "" || type === undefined ||
            titleEs === "" || titleEs === undefined ||
            titleEu === "" || titleEu === undefined ||
            active === "" || titleEu === undefined ||
            priority === "" || priority === undefined){

            valError = true;

        }else{            
            if(type === "NOTICIA"){
                if(news === "" || news === undefined) valError = true;
            }else if(type === "OTRO" || type === "VIDEO"){
                if(!isValidHttpUrl(linkUrlEs) || !isValidHttpUrl(linkUrlEs)) valErrorLink = true;
            }else if(type === "FUN_APP"){
                if(fun === "" || fun === undefined) valError = true;
            }else if(type === "APP"){
                if(txtAndroid === "" || txtAndroid === undefined ||
                txtIos === "" || txtIos === undefined) valError = true;
            }
        }
        
        if(valError){
            setErrorMsg(<FormattedMessage id="destacados.anadir.error.camposVacios" defaultMessage="Por favor, rellene los campos vacios."/>)
            setHideErrorComponent(false)
        }else  if(valErrorLink){
            setErrorMsg(<FormattedMessage id="destacados.anadir.error.errorLink" defaultMessage="El campo Link tiene un valor erroneo."/>)
            setHideErrorComponent(false)
        }else{
            if(active === "PUBLICADO") getNumberPublished("PUBLICADO");
            else if(active === "PROGRAMADO") getNumberPublished("PROGRAMADO");
            else addFeatured();
        }
    }

    function getNumberPublished(active){
        const apiUrl = process.env.REACT_APP_URL_FULL_FEATURES;

        const authAxios = axios.create ({
            baseURL: apiUrl,
            headers: {
                //Fix auth problem
               "Content-Security-Policy": "frame-ancestors none",
                //
                "Content-Type": "application/json",
                Authorization: `Bearer ${UserService.getToken()}`
            }
        })

        const data = {
            active: active,
            priority: priority,
            publishingDate: publishingDate,
            expirationDate: expirationDate
        }

        authAxios.post(process.env.REACT_APP_URL_FULL_FEATURES + "/numFiles", data)
        .then(response => validateCalendar(response.data))
        .catch(error => console.log(error));

    }

    

    function isValidHttpUrl(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ 
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
        '(\\#[-a-z\\d_]*)?$','i');
      return !!pattern.test(str);
    }

 
    


    function validateCalendar(res){

        if( res < 1   && priority === "1" || res === 1 && priority !== "1" || res < 1 ) 
        addFeatured() 


        else {

           
            if(active === "PUBLICADO") setErrorMsg(<FormattedMessage id="destacados.anadir.error.dosPublicados" defaultMessage="Ya hay dos destacados en estado publicado."/>)
            if(active === "PROGRAMADO") setErrorMsg(<FormattedMessage id="destacados.anadir.error.dosProgramados" defaultMessage="Ya hay dos destacados programados para esas fechas."/>)
            if(priority === "1") setErrorMsg(statusMessagePublished);
            setHideErrorComponent(false)
        }
        
    }
    function addFeatured() {

        window["urlImage"] = ""
        window["urlImageMini"] = ""
        window["selNews"] = ""
        const data = {
            type: type,
            titleEs: titleEs,
            titleEu: titleEu,
            linkUrlEs: linkUrlEs,
            linkUrlEu: linkUrlEu,
            idFun: fun,
            idNoticia: news.id,
            txtAndroid: txtAndroid,
            txtIos: txtIos,
            imageUrl: imageUrl,
            active: active,
            priority: priority,
            publishingDate: publishingDate,
            expirationDate: expirationDate
        }

        const headers = {
            "Content-Security-Policy": "frame-ancestors none",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        }

        const html = axios.create({baseURL: process.env.REACT_APP_URL_FULL_FEATURES});

        html.post(process.env.REACT_APP_URL_FULL_FEATURES, data, {'headers': headers})
            .then(response => history.push("/featured"));
        
    
    }

    function cancel(){
        window["urlImage"] = ""
        window["urlImageMini"] = ""
        window["selNews"] = ""
        history.goBack()
      }
      function setLang(lang){
        if(lang === "ES"){
            setHideEsComponent(false)
            setHideEuComponent(true)
        }else{
            setHideEsComponent(true)
            setHideEuComponent(false)
        }

    }
    function setNewType(type){
        setType(type)
        if(type === "NOTICIA"){
            setHideNoticiaComponent(false)
            setHideOtroComponent(true)
            setHideAppComponent(true)
            setHideVideoComponent(true)
            setHideFunComponent(true)
            setHideNoImageComponent(false)
            setHideImageComponent(true)
        }else if(type === "OTRO"){
            setHideNoticiaComponent(true)
            setHideOtroComponent(false)
            setHideAppComponent(true)
            setHideVideoComponent(true)
            setHideFunComponent(true)
            setHideNoImageComponent(false)
            setHideImageComponent(true)
        }else if(type === "VIDEO"){
            setHideNoticiaComponent(true)
            setHideOtroComponent(true)
            setHideAppComponent(true)
            setHideVideoComponent(false)
            setHideFunComponent(true)
            setHideNoImageComponent(true)
            setHideImageComponent(true)
        }else if(type === "FUN_APP"){
            setHideNoticiaComponent(true)
            setHideOtroComponent(true)
            setHideAppComponent(true)
            setHideVideoComponent(true)
            setHideFunComponent(false)
            setHideNoImageComponent(false)
            setHideImageComponent(true)
        }else{
            setHideNoticiaComponent(true)
            setHideOtroComponent(true)
            setHideAppComponent(false)
            setHideVideoComponent(true)
            setHideFunComponent(true)
            setHideNoImageComponent(false)
            setHideImageComponent(true)
        }
    }
    function changePublishingDate(date){
        setPublishingDate(date)

        let newExpirationDate = new Date(date);
        var d = newExpirationDate.getDate();
        newExpirationDate.setMonth(newExpirationDate.getMonth() + + 1);
        if (newExpirationDate.getDate() !== d) {
            newExpirationDate.setDate(0);
        }   
        setExpirationDate(newExpirationDate)
    }
    function selectedImage(){
        selImage = window["urlImage"]
        setImageUrl(selImage)
        setHideImageComponent(false)
        setHideNoImageComponent(true)
        hideGallery()
    }
    function showGallery(){
        setShow(true)
        setGaleryComponent(false);
    }
    function hideGallery(){
        setShow(false);
        setGaleryComponent(true);
        setHideImageOptionsComponent(true)
    }

    function selectedNews(){
        selNews = window["selNews"]
        requestGetNewById(setNews, selNews);
        hideNews()
    }
    function showNews(){
        setShow(true)
        setNewsComponent(false);
    }
    function hideNews(){
        setShow(false);
        setNewsComponent(true);
    } 
    function handleSelect(){
        history.push("/featured");
    }
    const BackDrop = ({show}) => 
    <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>
    
    const statusMessagePublished =

<div >
<i className="big exclamation red circle icon"></i>
<p><FormattedMessage id="destacados.error.existeDestacadoPrioridad" defaultMessage="Ya existe un destacado con esta prioridad para esta fecha"/></p>
</div>  


 
  

    return (
        <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}><FormattedMessage id="destacados.destacados" defaultMessage="Destacados"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" ><FormattedMessage id="destacados.anadir.anadirDestacado" defaultMessage="Añadir destacado"/></i>
        </div>
        
        <div className="mainDiv">
        <div className="div-inLine">
            <label className="title">
                <FormattedMessage id="destacados.anadir.anadirDestacado" defaultMessage="AÑADIR DESTACADO"/>
            </label>  
            <div className="red-box">
            <select className="red-Select" name="selectType" onChange={event => setLang(event.target.value)}>
            <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                {(message) => <option value="ES">{message}</option>}
            </FormattedMessage>
            <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                {(message) => <option value="EU">{message}</option>}
            </FormattedMessage>
            </select>
            </div>  
        </div>
        <div className="posLeft2">
        <div className="ui form" style={{marginLeft:15}}>
            <table>
            <tbody>
            <tr><td colSpan="2">
                <label><FormattedMessage id="destacados.anadir.tipo" defaultMessage="Tipo"/> *</label>
                <select value={type} className="ui select" name="selectType" onChange={event => setNewType(event.target.value)}>
                    <FormattedMessage id="destacados.anadir.tipo.seleccione" defaultMessage="Seleccione...">
                        {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.tipo.video" defaultMessage="Video (Enlaces externos)">
                        {(message) => <option value="VIDEO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.tipo.otro" defaultMessage="Otro (Enlaces externos)">
                        {(message) => <option value="OTRO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.tipo.funciApp" defaultMessage="Funcionalidad de la App">
                        {(message) => <option value="FUN_APP">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.tipo.appDipu" defaultMessage="App de la Diputación">
                        {(message) => <option value="APP">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.tipo.noticia" defaultMessage="Noticia">
                        {(message) => <option value="NOTICIA">{message}</option>}
                    </FormattedMessage>
                </select>
            </td></tr>
            <tr><td colSpan="2">
                <span className={`${hideAppComponent ? "hide-AppComponent" : ""}`}>
                <label ><FormattedMessage id="destacados.anadir.ios" defaultMessage="Ios"/> *</label>
                <input type="text" 
                        onChange={event => setTxtIos(event.target.value)}
                        value={txtIos} name="inpIos"></input>
                <label ><FormattedMessage id="destacados.anadir.android" defaultMessage="Android"/> *</label>
                <input type="text" 
                        onChange={event => setTxtAndroid(event.target.value)}
                        value={txtAndroid} name="inpAndoid"></input>
                </span>
                <span className={`${hideFunComponent ? "hide-FunComponent" : ""}`}>
                    <label><FormattedMessage id="destacados.anadir.funcionalidad" defaultMessage="Funcionalidad"/> *</label>
                    <select 
                        value={fun}
                        onChange={event => setFun(event.target.value)}
                        name="selectApp">
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.seleccione" defaultMessage="Seleccione...">
                            {(message) => <option value="">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.agendaCultural" defaultMessage="Agenda Cultural">
                            {(message) => <option value="agendaFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.servicios" defaultMessage="Servicios">
                            {(message) => <option value="servicesFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.playas" defaultMessage="Playas">
                            {(message) => <option value="beachesFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.planesRutas" defaultMessage="Planes y Rutas">
                            {(message) => <option value="planesFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.piscinas" defaultMessage="Piscinas">
                            {(message) => <option value="piscinasFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.noticias" defaultMessage="Noticias">
                            {(message) => <option value="noticiasFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.ultimoBoletin" defaultMessage="Último boletin">
                            {(message) => <option value="boletinUltimoFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.anadir.tipo.funciApp.aTodosBoletines" defaultMessage="A todos los boletines">
                            {(message) => <option value="boletinTodosFragment">{message}</option>}
                        </FormattedMessage>
                         <FormattedMessage id="destacados.anadir.tipo.funciApp.bibliotecaForal" defaultMessage="Biblioteca foral">
                            {(message) => <option value="bibliotecaForalFragment">{message}</option>}
                        </FormattedMessage>
                         <FormattedMessage id="destacados.anadir.tipo.funciApp.videollamada" defaultMessage="Videollamada">
                            {(message) => <option value="videollamadaFragment">{message}</option>}
                        </FormattedMessage>
                        
                    </select>
                </span>
                <span className={`${hideNoticiaComponent ? "hide-NoticiaComponent" : ""}`}>
                    <label><FormattedMessage id="destacados.anadir.tipo.noticia" defaultMessage="Noticia"/> *</label>
                    <div>
                    <input type="text" value={news.titleEs} readOnly style={{width: "60%"}}></input>
                    <button className="ui redBorder button" onClick={() => showNews(true)}>
                    <FormattedMessage id="destacados.anadir.tipo.seleccionarNoticia" defaultMessage="Seleccionar Noticia"/>
                    </button>
                    </div>
                </span>
                <span className={`${hideOtroComponent ? "hide-OtroComponent" : ""}`}>
                    <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                        <FormattedMessage id="destacados.anadir.enlaceExternoCastellano" defaultMessage="Enlace externo Castellano"/> *
                    </label>
                    <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                        onChange={event => setLinkUrlEs(event.target.value)}
                        value={linkUrlEs} name="inpOtro"></input>
                    <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                        <FormattedMessage id="destacados.anadir.enlaceExternoEuskera" defaultMessage="Enlace externo Euskera"/> *
                    </label>
                    <input type="text"  className={`${hideEuComponent ? "hide-EuComponent" : ""}`}
                        onChange={event => setLinkUrlEu(event.target.value)}
                        value={linkUrlEu} name="inpOtro"></input>
                </span>
                <span className={`${hideVideoComponent ? "hide-VideoComponent" : ""}`}>
                    <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                        <FormattedMessage id="destacados.anadir.videoCastellano" defaultMessage="Video Castellano"/> *
                    </label>
                    <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                        onChange={event => setLinkUrlEs(event.target.value)}
                        value={linkUrlEs} name="inpVideo"></input>
                    <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                        <FormattedMessage id="destacados.anadir.videoEuskera" defaultMessage="Video Euskera"/> *
                    </label>
                    <input type="text" className={`${hideEuComponent ? "hide-EuComponent" : ""}`}
                        onChange={event => setLinkUrlEu(event.target.value)}
                        value={linkUrlEu} name="inpVideo"></input>
                </span>
            </td></tr>
            <tr><td colSpan="2" >
                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                    <FormattedMessage id="destacados.anadir.tituloEs" defaultMessage="Titulo Castellano"/> *
                </label>
                <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`} 
                    onChange={event => setTitleEs(event.target.value)}
                    value={titleEs}>                
                </input>
                <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                    <FormattedMessage id="destacados.anadir.tituloEus" defaultMessage="Titulo Euskera"/> *
                </label>
                <input className={`${hideEuComponent ? "hide-EuComponent" : ""}`} 
                    onChange={event => setTitleEu(event.target.value)}
                    value={titleEu}>
                </input>
            </td></tr>
            <tr><td>
                <label><FormattedMessage id="destacados.anadir.estado" defaultMessage="Estado"/> *</label>
                <select value={active} className="ui select" name="selectState" onChange={event => setActive(event.target.value)}>
                    <FormattedMessage id="destacados.anadir.estado.seleccione" defaultMessage="Seleccione...">
                        {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.estado.publicado" defaultMessage="Publicado">
                        {(message) => <option value="PUBLICADO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.estado.programado" defaultMessage="Programado">
                        {(message) => <option value="PROGRAMADO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.anadir.estado.borrador" defaultMessage="Borrador">
                        {(message) => <option value="BORRADOR">{message}</option>}
                    </FormattedMessage>
                </select>
            </td><td>
            
                
            
           
         


            <label><FormattedMessage id="destacados.anadir.orden" defaultMessage="Orden"/> *</label>
                <select value={priority} className="ui select" name="selectOrder" onChange={event => setPriority(event.target.value)}>
                    <FormattedMessage id="destacados.anadir.orden.seleccione" defaultMessage="Seleccione...">
                        {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <option value="1" >1</option>
                    <option value="2" >2</option>
                </select>




                    

                    
                            

                                

                              


       


        
            </td></tr>
            <tr><td>


                <label><FormattedMessage id="destacados.anadir.fechPublica" defaultMessage="Fecha de publicación"/></label>
                <DatePicker 
                    locale="es"
                    dateFormat="dd/MM/yyyy HH:mm:ss" 
                    showTimeSelect
                    minDate={new Date()}
                    selected={publishingDate} 
                    onChange={(date) => changePublishingDate(date)} />
            </td><td>
                <label><FormattedMessage id="destacados.anadir.fechaExpira" defaultMessage="Fecha de expiración"/></label>
                <DatePicker 
                    locale="es"
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    showTimeSelect
                    minDate={publishingDate}
                    selected={expirationDate} 
                    onChange={(date) => setExpirationDate(date)} />
            </td></tr>
            </tbody>
            </table>
        </div>
        </div>
        <div className="posRight2">
            <label style={{padding: "20px"}}>
                <FormattedMessage id="destacados.anadir.imagen" defaultMessage="Imagen"/>
            </label>
            <div className={`${hideNoImageComponent ? "hide-Component" : ""}`} >
                <span style={{paddingLeft:16}}>
                   {/*  <button className='noImage' onClick={() => setHideImageOptionsComponent(!hideImageOptionsComponent)}>
                        <FormattedMessage id="destacados.anadir.anadirImagen" defaultMessage="Añadir imagen"/>
                    </button> */}
                    <button className='noImage' onClick={() => showGallery(false)}>
                        <FormattedMessage id="destacados.anadir.anadirImagen" defaultMessage="Añadir imagen"/>
                    </button>
                </span>
            </div>
            <div className={`${hideImageComponent ? "hide-Component" : ""}`}>
                <span style={{paddingLeft:16, paddingTop:30}}>
                <button className="pencil-icon" onClick={() => showGallery(false)}><i className="ui white pencil icon"></i></button>
                <img style={{maxWidth:350,maxHeight:375,paddingTop:16}} src={imageUrl} alt="Imagen" />
                </span>
            </div>
         {/*    <div className={`${hideImageOptionsComponent ? "hide-Component" : "optionsComponent"}`} >
                <span><ul className="optionsList">
                    <li onClick={() => showGallery(false)}>
                        <FormattedMessage id="destacados.anadir.seleccionarImagen" defaultMessage="Seleccionar Imagen"/>
                    </li>
                    <li>
                        <FormattedMessage id="destacados.anadir.imagenPredefinida" defaultMessage="Imagen predefinida"/>
                    </li>
                </ul></span>
            </div> */}
        </div>
        <div style={{marginBottom:15, marginTop:30, marginLeft:15}}>
        <button className="ui red button" onClick={() => validateValues()}>
            <FormattedMessage id="destacados.anadir.guardar" defaultMessage="Guardar"/>
        </button>
        <button className="ui noBorder button" onClick={() => cancel()}>
            <FormattedMessage id="destacados.anadir.cancelar" defaultMessage="Cancelar"/>
        </button>
        </div>
        <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
            <div><span>{errorMsg}</span></div>
            <div>
                <button className="ui red button" onClick={() => setHideErrorComponent(true)}>
                    <FormattedMessage id="destacados.anadir.aceptar" defaultMessage="Aceptar"/>
                </button>
            </div>
        </div>
        <BackDrop show={show}></BackDrop> 
        <div className={`${hideGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
            <SelectFile></SelectFile>
            <div>
                <button className="ui red button" onClick={() => selectedImage()}>
                    <FormattedMessage id="destacados.anadir.guardar" defaultMessage="Guardar"/>
                </button>
                <button className="ui noBorder button" onClick={() => hideGallery(true)}>
                    <FormattedMessage id="destacados.anadir.cancelar" defaultMessage="Cancelar"/>
                </button>
            </div>        
        </div>
        <div className={`${hideNewsComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>
            <SelectNews></SelectNews>  
            <div>
                <button className="ui red button" onClick={() => selectedNews()}>
                    <FormattedMessage id="destacados.anadir.guardar" defaultMessage="Guardar"/>
                </button>
                <button className="ui noBorder button" onClick={() => hideNews(true)}>
                    <FormattedMessage id="destacados.anadir.cancelar" defaultMessage="Cancelar"/>
                </button>
            </div>        
        </div>
    </div>
    </>
    )
}

export default CreateFeature;