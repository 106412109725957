import UserService from './UserService';
import axios from "axios";

export const requestGetCategories= async (state) => {
   
    const apiUrl = process.env.REACT_APP_URL_FULL_CATEGORIES;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })
 
   await authAxios.get(process.env.REACT_APP_URL_FULL_CATEGORIES)
   .then(response =>  state(response.data))
   .catch(error  =>  {
        console.log(error);
    }) 

}

export const requestPostCategories = (body) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_CATEGORIES;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.post(process.env.REACT_APP_URL_FULL_CATEGORIES, body)
    .then(response => console.log(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
}