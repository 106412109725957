import React, { useState}   from 'react';
import {useHistory} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import {requestUpdateConfiguration} from '../../services/PoolsService';
import axios from "axios";
import UserService from '../../services/UserService';
import SelectFile from "../media/SelectFile";

const ConfPools = () => {

    let selImage = "";
    let selImageEu = "";

    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [errorMsg, setErrorMsg] = useState();
    const [warningTextEs, setWarningTextEs] = useState("");
    const [warningTextEu, setWarningTextEu] = useState("");
    const [imageUrlEs, setImageUrlEs] = useState("");
    const [imageUrlEu, setImageUrlEu] = useState("");
    const [checkTemporada, setCheckTemporada] = useState(false);
    const [hideEsImageComponent, setHideEsImageComponent] = useState(false);
    const [hideEuImageComponent, setHideEuImageComponent] = useState(true);
    const [hideEsNoImageComponent, setHideEsNoImageComponent] = useState(true);
    const [hideEuNoImageComponent, setHideEuNoImageComponent] = useState(true);
    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    const [hideImageOptionsComponent, setHideEsImageOptionsComponent] = useState(true);
    const [hideEuImageOptionsComponent, setHideEuImageOptionsComponent] = useState(true);
    const [hideGaleryComponent, setGaleryComponent] = useState(true);
    const [hideEuGaleryComponent, setGaleryEuComponent] = useState(true);
    const [show, setShow] = useState(false);
    const [showEu, setShowEu] = useState(false);
    

    let history = useHistory();

    React.useEffect(() => {
        getData();
        setImageUrlEs(selImage);
        setImageUrlEu(selImageEu);
        
    },[selImage, selImageEu])

    const getData = async () => {
        const headers = {
            "Content-Security-Policy": "frame-ancestors none",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }

        const url = process.env.REACT_APP_URL_FULL_POOLS + `/configuration`; 
        const data = await (await axios.get(url, { 'headers':  headers })).data;
        const dataConfiguration = await data;
        setWarningTextEs(dataConfiguration[0].avisoEs);
        setWarningTextEu(dataConfiguration[0].avisoEu);
        setCheckTemporada(dataConfiguration[0].state);
        setImageUrlEs(dataConfiguration[0].urlImgEs);
        setImageUrlEu(dataConfiguration[0].urlImgEu);

        

    }

    function validateValues(){
        if(warningTextEs === '' ||
           warningTextEu === '' ||
           imageUrlEs === '' ||
           imageUrlEu === ''){
            setErrorMsg(<FormattedMessage id="playas.error.camposVacios" defaultMessage="Por favor, rellene los campos vacios."/>)
            setHideErrorComponent(false);
        }else updateWarning();
    }

    function updateWarning(){
        const body = {
            id:1,
            avisoEs:warningTextEs,
            avisoEu:warningTextEu,
            state:checkTemporada,
            urlImgEs: imageUrlEs,
            urlImgEu: imageUrlEu
        }
        requestUpdateConfiguration(body).then(() => history.push("/swimming-pools"));
    }

    function cancel(){
        history.goBack()
    }
    function handleSelect(){
        history.push("/swimming-pools");
    }

    

    function setLang(lang){
        if(lang === "ES"){
            setHideEsComponent(false)
            setHideEuComponent(true)
           
          if(imageUrlEs == ''){
            setHideEsNoImageComponent(false)
            setHideEuNoImageComponent(true)
            
            setHideEsImageComponent(true)
            setHideEuImageComponent(true)
          }else{
            setHideEsNoImageComponent(true)
            setHideEuNoImageComponent(true)
            
            setHideEsImageComponent(false)
            setHideEuImageComponent(true)
          }
            
            
           
        
        }else{
            setHideEsComponent(true)
            setHideEuComponent(false)
    
            if(imageUrlEu == ''){
              setHideEsNoImageComponent(true)
              setHideEuNoImageComponent(false)
    
              setHideEsImageComponent(true)
              setHideEuImageComponent(true)
            }else{
              setHideEsNoImageComponent(true)
              setHideEuNoImageComponent(true)
    
              setHideEsImageComponent(true)
              setHideEuImageComponent(false)
            } 
        }
    
    }
    const BackDrop = ({show}) => 
    <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>

    function selectedImage(){
        selImage = window["urlImage"]
        setImageUrlEs(selImage)
        setHideEsImageComponent(false)
        setHideEsNoImageComponent(true)
        hideGallery()
    }
    
    function selectedImageEu(){
        selImageEu = window["urlImage"]
        setImageUrlEu(selImageEu)
        setHideEuImageComponent(false)
        setHideEuNoImageComponent(true)
        hideGalleryEu()
    }
      
    function hideGallery(){
        setShow(false);
        setGaleryComponent(true);
        setHideEsImageOptionsComponent(true)
      }
      
      
      function showGallery(){
        setShow(true)
        setGaleryComponent(false);
      }
      
      function hideGalleryEu(){
        setShowEu(false);
        setGaleryEuComponent(true);
        setHideEuImageOptionsComponent(true)
      }
      
      
      function showGalleryEu(){
        setShowEu(true)
        setGaleryEuComponent(false);
      }
    

    return (
        <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="piscinas" defaultMessage="Piscinas"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" ><FormattedMessage id="piscinas.configurar.configurarPiscinas" defaultMessage="Configurar piscinas" /></i>
        </div>
        <div className='mainDiv' >
            <div className="div-inLine">
                <label className="title">
                    <FormattedMessage id="piscinas.configurar.configurarPiscinas" defaultMessage="Configurar piscinas"/>
                </label>
                <div className="red-box">
                    <select className="ui red-Select select" style={{backGroundColor:"red"}} name="selectType" onChange={(e) => setLang(e.target.value)}>
                    <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                        {(message) => <option value="ES">{message}</option>}
                    </FormattedMessage>
                        <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                        {(message) => <option value="EU">{message}</option>}
                    </FormattedMessage>
                    </select>
                </div>
            </div>
            <div className="ui form">
                <div className="ui grid" style={{marginTop:2,marginLeft:0}}>
                    <div className="four wide column">
                        <label  className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                            <FormattedMessage id="piscinas.configurar.avisoSecundarioEs" defaultMessage="Aviso secundario castellano" />
                        </label>
                        <input required type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                            onChange={event => setWarningTextEs(event.target.value)}
                            value={warningTextEs}>
                        </input>
                        <label  className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                            <FormattedMessage id="piscinas.configurar.avisoSecundarioEu" defaultMessage="Aviso secundario euskera" />
                        </label>
                        <input required type="text"  className={`${hideEuComponent ? "hide-EuComponent" : ""}`}
                            onChange={event => setWarningTextEu(event.target.value)}
                            value={warningTextEu}>
                        </input>
                    </div>
                    <div className="six wide column">
                        <div className="eight wide column">
                            <div className={`${hideEsNoImageComponent   ? "hide-Component" : "imageSpan"}`} >
                                <span>
                                    <button className='noImage' onClick={() => setHideEsImageOptionsComponent(!hideImageOptionsComponent)}><FormattedMessage id="piscinas.configurar.anadirImagen" defaultMessage="Añadir imagen"/></button>
                                   
                                </span>
                                <div className={`${hideImageOptionsComponent ? "hide-Component" : "optionsComponent"}`} style={{ marginLeft:-8}} >
                                    <span>
                                        <ul className="optionsList">
                                        <li onClick={() => showGallery(false)}><FormattedMessage id="piscinas.configurar.seleccionarImagen" defaultMessage="Seleccionar Imagen"/></li>
                                        <li><FormattedMessage id="piscinas.configurar.imagenPredefinida" defaultMessage="Imagen predefinida"/></li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                            <div className={`${hideEsImageComponent ? "hide-Component" : "imageSpan"}`}>
                                <span>
                                <button className="pencil-icon" style={{marginLeft:50}} onClick={() => showGallery(false)}><i className="ui white pencil icon"></i></button>
                                <img className="formImage" style={{marginLeft:50, width:200}} src={imageUrlEs} alt="Imagen" />
                                </span> 
                            </div>
                            <div className={`${hideEuNoImageComponent ? "hide-Component" : "imageSpan"}`} >
                                <span>
                                    <button className='noImage' onClick={() => setHideEuImageOptionsComponent(!hideEuImageOptionsComponent)}><FormattedMessage id="piscinas.configurar.anadirImagen" defaultMessage="Añadir imagen"/></button>
                                </span>
                                <div className={`${hideEuImageOptionsComponent ? "hide-Component" : "optionsComponent"}`}  style={{ marginLeft:-8}} >
                                    <span>
                                        <ul className="optionsList">
                                        <li onClick={() => showGalleryEu(false)}><FormattedMessage id="piscinas.configurar.seleccionarImagen" defaultMessage="Seleccionar Imagen"/></li>
                                        <li><FormattedMessage id="piscinas.configurar.imagenPredefinida" defaultMessage="Imagen predefinida"/></li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                            <div className={`${hideEuImageComponent ? "hide-Component" : "imageSpan"}`}>
                                <span>
                                <button className="pencil-icon" style={{marginLeft:50}} onClick={() => showGalleryEu(false)}><i className="ui white pencil icon"></i></button>
                                <img className="formImage" style={{marginLeft:50, width:200}} src={imageUrlEu} alt="Imagen" />
                                </span>  
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="ui grid" style={{ marginTop:2, marginLeft:0}}>
                    <div className="five wide column">
                    <label >
                            <FormattedMessage id="piscinas.configurar.checkboxTemporada" defaultMessage="¿Esta en temporada?" />
                        </label>
                        <input required type='checkbox'
                            className="checkPiscinas"
                            onChange={event => setCheckTemporada(event.target.checked)}
                            checked={checkTemporada}>
                        </input>
                    </div>
                </div>
            </div>
            <div style={{ marginTop:10, marginLeft:14}}>
                <button className="ui red button" onClick={() => validateValues()}>
                    <FormattedMessage id="piscinas.configurar.guardar" defaultMessage="Guardar" />
                </button>
                <button className="ui noBorder button" onClick={() => cancel()}>
                    <FormattedMessage id="piscinas.configurar.cancelar" defaultMessage="Cancelar" />
                </button>
            </div>
        </div>
        <BackDrop show={show}></BackDrop> 
        <div className={`${hideGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
            <SelectFile></SelectFile>
            <div>
                <button className="ui red button" onClick={() => selectedImage()}><FormattedMessage id="piscinas.configurar.guardar" defaultMessage="Guardar"/></button>
                <button className="ui noBorder button" onClick={() => hideGallery(true)}><FormattedMessage id="piscinas.configurar.cancelar" defaultMessage="Volver"/></button>
                
            </div>   
        </div>
        <div className={`${hideEuGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
            <SelectFile></SelectFile>
            <div>
                <button className="ui red button" onClick={() => selectedImageEu()}><FormattedMessage id="piscinas.configurar.guardar" defaultMessage="Guardar"/></button>
                <button className="ui noBorder button" onClick={() => hideGalleryEu(true)}><FormattedMessage id="piscinas.configurar.cancelar" defaultMessage="Volver"/></button>
            </div>  
                
        </div>
        <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
            <div>
                <i className="big exclamation red circle icon"></i>
                <span>{errorMsg}</span>
            </div>
            <div >
                <button  className="ui red button" onClick={() => setHideErrorComponent(true)}>
                    <FormattedMessage id="piscinas.configurar.aceptar" defaultMessage="Aceptar"/>
                </button>
            </div>
        
        </div>
         
    </>
)
}




export default ConfPools
