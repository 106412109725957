import React, { useState}   from 'react';
import {NavLink} from "react-router-dom";
import {requestGetNewPublic} from '../../services/NewsService';
import Moment from 'moment';
import {FormattedMessage} from 'react-intl';

const News = () => {

  const [news, setNews] = useState([])

  React.useEffect(() => {
    requestGetNewPublic(setNews);
  },[])

  function onChangeValue(e) {
    window["selNews"] = e
  }

  return (
    <div>
        <label className="title"><FormattedMessage id="noticias.selectNews.seleccionaNoticia" defaultMessage="Selecciona una noticia"/></label>  
        <div className="ui  icon input search">
            <input type="text" placeholder="Buscar"/>
            <i className="search link icon"></i>
        </div>
        <div className='paddingTableNews' onChange={e => onChangeValue(e.target.value)}>    
            <table className="ui  table">       
                <thead>
                    <tr className='center aligned row'>
                        <th></th>
                        <th><FormattedMessage id="noticias.selectNews.titulo" defaultMessage="Título"/></th>
                        <th><FormattedMessage id="noticias.selectNews.autor" defaultMessage="Autor"/></th>           
                        <th><FormattedMessage id="noticias.selectNews.fecha" defaultMessage="Fecha"/></th>
                        <th></th>
                    </tr>
                </thead>{
                news.map(data => (
                <tbody >   
                    <tr>
                    <td key={data.id}>
                        <label className="radioContainer">
                        <input type="radio" name="news" value={data.id}></input>
                        <span className="redRadio"><i className="ui check icon" /></span>
                        </label>
                    </td>
                    <td>{data.titleEs}</td>
                    <td className='center aligned row'>{data.author}</td>
                    <td className='center aligned row'>{Moment(data.creationDate).format("DD/MM/YYYY")}</td>
                    <td> 
                        <div className="ui small basic icon buttons">
                            <NavLink to={`view-news/${data.id}`}>  
                                <button className="ui button" ><i className="ui red eye icon"></i></button>
                            </NavLink>
                        </div>
                    </td>
                    </tr>
                </tbody>
                ))}
            </table>
        </div>
        <button className="button-icon" ><i className="ui chevron left icon"></i></button>
        <button className="button-icon"><i className="ui chevron right icon"></i></button>
    </div>
  )  
}

 export default News
