import React from 'react';
import { useState, useEffect } from 'react';
import UserService from '../../services/UserService'
import axios from 'axios';
import Gallery from 'react-grid-gallery';
import {FormattedMessage} from 'react-intl';

const SelectFile = () => {
   
  const [totalPost, setTotalPost] = useState([])
  const [images, addImages] = useState([])
  const [page, setPage] = useState(0)  
  const [size] = useState(10)
  const [sort, setSort] = useState("uploadDate")

  useEffect(() => {
    
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES, { params: {pageIndex: page, pageSize: size, sortBy: sort}})
      .then(res => {
        getTotalNumberOfFiles()
        setImagesGrid(res.data)
      })
    },[]);
    
  function setImagesGrid(data){
    let gridImg = [];

    data.map(item => {

        let element = {
            src: item.urlImage,
            thumbnail: item.urlImageMini,
            isSelected: false, 
            thumbnailWidth: 100,
            thumbnailHeight: 100,
            caption:item.imageName.replace(".min","")
        }

        gridImg.push(element)

    });

    addImages(gridImg);
  }

  function getTotalNumberOfFiles() {
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES + "/numFiles", { params: {pageIndex: page, pageSize: size, sortBy: sort}})
    .then((response) => {
      setTotalPost(response.data);
    })
  }

  function goToPage(nPage) {

    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES, { params: {pageIndex: nPage, pageSize: size, sortBy: sort}})
      .then(res => {
        getTotalNumberOfFiles()
        setPage(nPage)
        addImages(res.data);
        setImagesGrid(res.data);
      })
  }

  function goToSort(nPage, nSort){
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES, { params: {pageIndex: nPage, pageSize: size, sortBy: nSort}})
      .then(res => {
        getTotalNumberOfFiles()
        setPage(nPage)
        setSort(nSort)
        addImages(res.data);
        setImagesGrid(res.data);
      })
  }

  function nextList() {
    goToPage(Number(page)+1);
  }
  function prevList() {
    goToPage(Number(page)-1);
  }
  
  function selectImage(index){
    for(var i = 0; i < images.length; i++) images[i].isSelected = false;
    let element = images[index];
    element.isSelected = true;
    addImages(images);

    window["urlImage"] = element.src;
    window["urlImageMini"] = element.thumbnail;
  }

  return loadTable()

  function loadTable() {
      const pageNumbers = [];

      for (let i = 1; i <= Math.ceil(totalPost / size); i++) {
        pageNumbers.push(i);
      }

      return (
          <div>
            <label className="title" style={{paddingTop: 10}}> 
              <FormattedMessage id="media.selectManager" defaultMessage="Selecciona una imagen" />
            </label>
            <h1></h1>
            <div>
                <div>
                  <select className="ui select" name="select" onChange={e => goToSort(0,e.target.value)} >
                    <FormattedMessage id="media.fecha" defaultMessage="Fecha">
                      {(message) => <option value="uploadDate">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="media.nombre" defaultMessage="Nombre">
                      {(message) => <option value="imageName">{message}</option>}
                    </FormattedMessage>
                  </select>
                </div>
            </div>
            <div >
              <table>
                <tbody>
                  <tr><td>
                    <Gallery 
                      images={images}
                      lightboxWidth={1536}
                      enableLightbox={false}
                      enableImageSelection={true}
                      onSelectImage={index => selectImage(index)}>
                    </Gallery>
                  </td></tr>
                  <tr><td>
                    <button className="button-icon" onClick={prevList}><i className="ui chevron left icon"></i></button>
                    {pageNumbers.map(number => (
                      <button className="button-icon" onClick={() => goToPage(number-1)}>{number}</button>
                    ))}
                    <button className="button-icon" onClick={nextList}><i className="ui chevron right icon"></i></button>
                  </td></tr>  
                </tbody>
              </table>
            </div>
        </div>
      );
  }
}

export default SelectFile;
