import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import * as Constantes from '../../helpers/constantes';
import PersonAdd from '@mui/icons-material/PersonAdd'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TransferListGroups from './TransferListGroups';

const UserForm = () => {

    const datos = {
        "idpUserId": "3",
        "idpAlias": "alias3",
        "username": "user3",
        "firstName": "Eva",
        "lastName": "Johnson",
        "email": "eva.johnson@example.com",
        "enabled": true,
        "groups": "editor"
    }

    const onClickGuardar = (event) => {

        var descripcion;
        if(action == Constantes.ACTION_CREAR){
            descripcion = "El usuario se ha creado correctamente";
        }else if(action == Constantes.ACTION_MODIFICAR){
            descripcion = "El usuario se ha actualizado correctamente";
        }

        Swal.fire({
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#3085d6",
            icon: "success",
            title: "Completado",
            text: descripcion,
          })
    }

    const {action} = useParams();

    let history = useHistory();

    function handleSelect(){
        history.push("/users");
    }

    const onClickGenerarPassword= (event) => {
        
    }

    const [checkedEstado, setCheckedEstado] = React.useState((action == Constantes.ACTION_CREAR ? true : datos.enabled));

    const handleChangeEstado = (event) => {
        setCheckedEstado(event.target.checked);
      };

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    const [checkedResetear, setCheckedResetear] = React.useState(false);

    const handleChangeResetear = (event) => {
      setCheckedResetear(event.target.checked);
    };

    return (
    <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="menu.usuarios" defaultMessage="Usuarios"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" >{
                (action == Constantes.ACTION_CONSULTAR ? "Consultar usuario" :
                action == Constantes.ACTION_CREAR ? "Crear usuario" :
                action == Constantes.ACTION_MODIFICAR ? "Modificar usuario" : "")
            }</i>
        </div>

        <div className='mainDiv' style={{paddingTop: 15, paddingRight: 15}}>

            <label className="title" style={{ display: 'flex'}} > 
            {action === Constantes.ACTION_CONSULTAR ? (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="usuarios.consultar" defaultMessage="Crear usuario" />
                    <PersonSearchIcon/>
                </div>
            ) : action === Constantes.ACTION_MODIFICAR ? (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="usuarios.modificar" defaultMessage="Crear usuario" />
                    <ManageAccountsIcon/>
                </div>
            ) : (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="usuarios.crear" defaultMessage="Crear usuario" />
                    <PersonAdd/>
                </div>
            )}
            </label>

            <div className='ui form' style={{marginTop: 5, marginLeft: 15, display: 'flex', flexWrap: 'wrap'}}>
                
                <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 25}}>
                    <label htmlFor='id'>Nombre de usuario:</label>
                    <input 
                        contentEditable={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 175}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value={(action == Constantes.ACTION_CREAR ? "" : datos.username)}
                        placeholder=""/>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 25}}>
                    <label htmlFor='id'>Nombre:</label>
                    <input 
                        contentEditable={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 200}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value={(action == Constantes.ACTION_CREAR ? "" : datos.firstName)}
                        placeholder=""/>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 25}}>
                    <label htmlFor='id'>Apellido:</label>
                    <input 
                        contentEditable={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 200}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value={(action == Constantes.ACTION_CREAR ? "" : datos.lastName)}
                        placeholder=""/>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 25}}>
                    <label htmlFor='id'>Email:</label>
                    <input 
                        contentEditable={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 300}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value={(action == Constantes.ACTION_CREAR ? "" : datos.email)}
                        placeholder=""/>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 25}}>         
                    <label htmlFor='id' style={{marginRight: 10}}>Estado:</label>
                    <FormControlLabel 
                        label={(checkedEstado ? "Activo" : "Deshabilitado" )} 
                        control={
                            <Switch 
                                contentEditable={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                                defaultChecked 
                                color={(checkedEstado ? "success" : "error" )}      
                                checked={ checkedEstado}
                                onChange={handleChangeEstado} 
                            />
                        } 
                    />
                </div>

                {/* Filtro Estado */}
                <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 100}}>
                    <label htmlFor='servicio' style={{marginRight:10}} >Grupo:</label>
                    <select contentEditable={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                        id='servicio' className="ui select" style={{width: 250}} 
                        value="" defaultValue="" name="selectTown">
                        <option key="0"  value="Seleccione un Topic">Seleccione un grupo</option>                            
                        <option data-tooltip-id="MESSAGE_ACCEPTED" key="1" value="IOS">Grupo 1</option>                        
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 2</option>
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 3</option>
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 4</option>
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 5</option>
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 6</option>
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 7</option>
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 8</option>
                        <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">Grupo 9</option>
                    </select>
                </div> 

                {action !== Constantes.ACTION_CONSULTAR && (
                <>
                    {action == Constantes.ACTION_MODIFICAR && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 15}}>
                            <FormControlLabel 
                                label="Resetear contraseña"
                                control={
                                    <Checkbox 
                                        checked={(checkedResetear ? true : false )} 
                                        onChange={handleChangeResetear}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                        'aria-labelledby': "dfs",
                                        }}
                                    />
                                } 
                            />
                        </div>
                    </>
                    )}

                    { action == Constantes.ACTION_CREAR || checkedResetear ? (
                    <>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 15}}>
                        <label htmlFor='id'>{(checkedResetear) ? "Nueva contraseña:" : "Contraseña"}</label>
                        <input 
                            disabled={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                            className=''
                            id='id'
                            style={{marginLeft: 10, width: 175}}
                            type="password" 
                            maxlength="3" 
                            size="3"
                            value=""
                            placeholder=""/>
                    </div>
                


                    <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 25}}>
                        <button disabled={(action == Constantes.ACTION_CONSULTAR ? true : false)} 
                                className="ui blue button" onClick={() => onClickGenerarPassword()}>
                            <FormattedMessage id="notificaciones.buscar" defaultMessage="Generar" />
                        </button>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginRight: 25}}>
                        <FormControlLabel 
                            label="Contraseña temporal"
                            control={
                                <Checkbox 
                                    disabled={(action == Constantes.ACTION_CONSULTAR ? true : false)}
                                    checked={(checked ? true : false )} 
                                    onChange={handleChange}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                    'aria-labelledby': "dfs",
                                    }}
                                />
                            } 
                        />
                    </div>
                    </>
                    ) : ""}
                </>
                )}

            </div>

            {action === Constantes.ACTION_CONSULTAR ? (
                <div style={{ marginTop:40, marginLeft:14, marginBottom: 20}}>
                    <button className="ui red button" onClick={() => handleSelect()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Volver" />
                    </button>
                </div>
            ) : (
                <div style={{ marginTop:40, marginLeft:14, marginBottom: 20}}>
                    <button className="ui red button" onClick={() => onClickGuardar()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Guardar" />
                    </button>
                    <button className="ui noBorder button" onClick={() => handleSelect()}>
                        <FormattedMessage id="notificaciones.limpiar" defaultMessage="Cancelar" />
                    </button>
                </div>
            )} 


        </div>
    </>
    );
};

export default UserForm;