import React from 'react';
import { FormattedMessage } from 'react-intl';
import Swal from 'sweetalert2';

const Parameters = () => {

    const onClickFiltrar = (event) => {
        Swal.fire({
            title: "Completado",
            text: "Se ha guardado la configuración correctamente",
            icon: 'success',
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
    }

    return (
        <>
        <div className='mainDiv' style={{paddingTop: 25, paddingRight: 15}}>

            <label className="title"> 
                <FormattedMessage id="parametros.titulo" defaultMessage="Gestión de parámetros" />
            </label>

            <div className='ui form' style={{marginTop: 15, marginLeft: 15, display: 'flex', alignItems: 'baseline'}}>

                    {/* Filtro ID */}
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                        <label htmlFor='id'>Tiempo renovar sesión (segundos):</label>
                        <input 
                            className=''
                            id='id'
                            style={{marginLeft: 10, width: 80, textAlign: 'right'}}
                            type="text" 
                            maxlength="3" 
                            size="3"
                            value="60"
                            placeholder=""/>
                    </div>

                    <span id="Minutos"></span>
                    <span id="Segundos"></span>
            </div>

            <div style={{ marginTop:25, marginLeft:14, marginBottom: 35}}>
                <button className="ui red button" onClick={() => onClickFiltrar()}>
                    <FormattedMessage id="notificaciones.buscar" defaultMessage="Guardar" />
                </button>
            </div>

        </div>
        </>
    );
};

export default Parameters;