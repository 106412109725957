import UserService from './UserService';
import axios from "axios";



export const requestGetSwimmingPoolsSearch = async (state, otherState, page, size, poolName, town) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        } 
    })

   await authAxios.get(process.env.REACT_APP_URL_FULL_POOLS, { params: {page: page, size: size, poolName: poolName, cityName: town }})
   .then(response =>  {
       state(response.data);
       otherState(response.data);
    }).catch(error  =>  {
        console.log(error);
    }) 

}

export const requestGetPool = async (state) => {
   
    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })
 
   await authAxios.get(process.env.REACT_APP_URL_FULL_POOLS)
   .then(response =>  state(response.data))
   .catch(error  =>  {
    console.log(error);
}) 

}

export const requestGetPoolById =  async (state,id) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })
   await authAxios.get(process.env.REACT_APP_URL_FULL_POOLS+`/${id}`,id)
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
 
 }
 
 export const requestGetPoolPublic =  async (state,status) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            //Fix auth problem
            "Content-Security-Policy": "frame-ancestors none",
            //
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
   await authAxios.get(process.env.REACT_APP_URL_FULL_POOLS, { params: {status: status}})
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
 
 }
export const requestPostPool = (body) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.post(process.env.REACT_APP_URL_FULL_POOLS, body)
    .then(response => console.log(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
}

 export const RequestPutPool = async (id,data) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })


    await authAxios.put(process.env.REACT_APP_URL_FULL_POOLS+`/${id}`, data)
    .then(response => console.log(response))
    .catch(error  =>  {
        console.log(error);
    }) 
}

export const requestDeleteSeasson =  (id,body) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.delete(process.env.REACT_APP_URL_FULL_POOLS+`/seasson/${id}`)
    .then(response =>  RequestPutPool(id,body)) 
    .catch(error  =>  {
        console.log(error);
    }) 

}

export const requestDeletePool = (id,state,otherState,nPage,size, town) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.delete(process.env.REACT_APP_URL_FULL_POOLS+`/${id}`,state)
    .then(response =>  requestGetSwimmingPoolsSearch(state,otherState, nPage, size,"", town)())  
    .catch(error  =>  {
        console.log(error);
    }) 

 

}

export const getTotalNumberOfFiles  = async (state, poolName ,townFilter ) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Security-Policy": "frame-ancestors none",
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
    await authAxios.get(process.env.REACT_APP_URL_FULL_POOLS + "/numTotal", { params: {poolName: poolName, cityName: townFilter }})
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 

  }

  export const requestPostTown = (body) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_TOWNS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.post(process.env.REACT_APP_URL_FULL_POOLS, body)
    .then(response => console.log(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
}

export const requestGetTown = async (state) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    authAxios.get(process.env.REACT_APP_URL_FULL_POOLS+ '/listtown')
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
}
export const requestGetListPools =  async (state,id) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })
   await authAxios.get(process.env.REACT_APP_URL_FULL_POOLS + '/town', { params: {town: id}})
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
 
 }
export const requestGetTownById =  async (state,idTown,idPool) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })
   await authAxios.get(process.env.REACT_APP_URL_FULL_POOLS + `/${idTown}` + `/${idPool}`,idTown)
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 
 
 }

export const requestUpdateConfiguration = async (body) => {

    const apiUrl = process.env.REACT_APP_URL_FULL_POOLS + '/configuration';
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    await authAxios.post(process.env.REACT_APP_URL_FULL_POOLS + '/configuration', body)
    .catch(error  =>  {
        console.log(error);
    }) 
}
