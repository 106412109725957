import { Tooltip as ReactTooltip } from "react-tooltip";
import { Typography } from "@mui/material";

const columns = [
  {
    accessorKey: "idpUserId",
    header: "ID",
    size: 75,

  },
  {
    accessorKey: "idpAlias",
    header: "Alias",
    size: 150,
  },
  {
    accessorKey: "username", 
    header: "Usuario",
    size: 100,
  },
  {
    accessorKey: "groups", 
    header: "Grupo",
    size: 100,
  },
  {
    accessorKey: "firstName",
    header: "Nombre",
    size: 100,
  },
  {
    accessorKey: "lastName",
    header: "Apellido",
    size: 100,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 100,
  },
  {
    accessorKey: "enabled",
    header: "Estado",
    size: 75,
    Cell: ({ cell }) => (
        <>
            <i data-tooltip-id={cell.id} className={
                cell.getValue() === true ? "circle icon green" :"circle icon grey"}>
            </i>

            <Typography display="inline" id="modal-modal-description">
              {cell.getValue() === true ? "Activo" :"Deshabilitado"}
            </Typography>
            
        </>
      ),
  },
];

export default columns;
