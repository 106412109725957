const data = [
  {
    "idpUserId": "1",
    "idpAlias": "alias1",
    "username": "user1",
    "firstName": "John",
    "lastName": "Doe",
    "email": "john.doe@example.com",
    "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "2",
  "idpAlias": "alias2",
  "username": "user2",
  "firstName": "Alice",
  "lastName": "Smith",
  "email": "alice.smith@example.com",
  "enabled": true
,"groups":"consultor"
},
{
  "idpUserId": "3",
  "idpAlias": "alias3",
  "username": "user3",
  "firstName": "Eva",
  "lastName": "Johnson",
  "email": "eva.johnson@example.com",
  "enabled": false
,"groups":"editor"
},
{
  "idpUserId": "4",
  "idpAlias": "alias4",
  "username": "user4",
  "firstName": "Michael",
  "lastName": "Brown",
  "email": "michael.brown@example.com",
  "enabled": true
,"groups":"editor"
},
{
  "idpUserId": "5",
  "idpAlias": "alias5",
  "username": "user5",
  "firstName": "Linda",
  "lastName": "Wilson",
  "email": "linda.wilson@example.com",
  "enabled": false
,"groups":"consultor"
},
{
  "idpUserId": "6",
  "idpAlias": "alias6",
  "username": "user6",
  "firstName": "David",
  "lastName": "Lee",
  "email": "david.lee@example.com",
  "enabled": false
,"groups":"administrador"
},
{
  "idpUserId": "7",
  "idpAlias": "alias7",
  "username": "user7",
  "firstName": "Sophia",
  "lastName": "Moore",
  "email": "sophia.moore@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "8",
  "idpAlias": "alias8",
  "username": "user8",
  "firstName": "James",
  "lastName": "Anderson",
  "email": "james.anderson@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "9",
  "idpAlias": "alias9",
  "username": "user9",
  "firstName": "Emma",
  "lastName": "Robinson",
  "email": "emma.robinson@example.com",
  "enabled": false
,"groups":"administrador"
},
{
  "idpUserId": "10",
  "idpAlias": "alias10",
  "username": "user10",
  "firstName": "Daniel",
  "lastName": "Wright",
  "email": "daniel.wright@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "11",
  "idpAlias": "alias11",
  "username": "user11",
  "firstName": "Olivia",
  "lastName": "Evans",
  "email": "olivia.evans@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "12",
  "idpAlias": "alias12",
  "username": "user12",
  "firstName": "Lucas",
  "lastName": "Parker",
  "email": "lucas.parker@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "13",
  "idpAlias": "alias13",
  "username": "user13",
  "firstName": "Ava",
  "lastName": "Carter",
  "email": "ava.carter@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "14",
  "idpAlias": "alias14",
  "username": "user14",
  "firstName": "Alexander",
  "lastName": "Baker",
  "email": "alexander.baker@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "15",
  "idpAlias": "alias15",
  "username": "user15",
  "firstName": "Mia",
  "lastName": "Fisher",
  "email": "mia.fisher@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "16",
  "idpAlias": "alias16",
  "username": "user16",
  "firstName": "Benjamin",
  "lastName": "Gonzalez",
  "email": "benjamin.gonzalez@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "17",
  "idpAlias": "alias17",
  "username": "user17",
  "firstName": "Charlotte",
  "lastName": "Hill",
  "email": "charlotte.hill@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "18",
  "idpAlias": "alias18",
  "username": "user18",
  "firstName": "Henry",
  "lastName": "Young",
  "email": "henry.young@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "19",
  "idpAlias": "alias19",
  "username": "user19",
  "firstName": "Amelia",
  "lastName": "Ward",
  "email": "amelia.ward@example.com",
  "enabled": true
,"groups":"administrador"
},
{
  "idpUserId": "20",
  "idpAlias": "alias20",
  "username": "user20",
  "firstName": "Liam",
  "lastName": "Taylor",
  "email": "liam.taylor@example.com",
  "enabled": true
,"groups":"usuario"
}
];
export default data;