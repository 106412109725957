import React, {useState} from 'react'; 
import { useParams, useHistory } from 'react-router-dom';
import { format } from "date-fns";
import Popup from 'reactjs-popup';
import axios from 'axios';
import UserService from '../../services/UserService'
import { Player } from 'video-react';
import "/node_modules/video-react/dist/video-react.css"; 
import {FormattedMessage} from 'react-intl';

const ViewFile = () => {

    const {id} = useParams();

    const [viewFile, setViewFile] = useState([])
    const [fileType, setFileType] = useState([]) 
    const [formatedDate, setFormatedDate] = useState([]) 
    const [showImage, setShowImage] = useState(true)
    const [showVideo, setShowVideo] = useState(true)

    let history = useHistory();

    React.useEffect(() => {
      getData();
    },[id])  

    const getData = async () => {

      const headers = {
        //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + UserService.getToken()
      }
      const url = process.env.REACT_APP_URL_FULL_FILES + `/${id}`; 
      const data = await axios.get(url, { 'headers':  headers })   
      const dataFile = await data.data;
      setFileType(fileClass(dataFile.urlVideo))
      setFormatedDate(formateDate(dataFile.uploadDate))
      setViewFile(dataFile);

    } 

    function formateDate(uploadDate){
        var date = new Date(uploadDate);    
        return  format(date, "dd-MM-yyyy");
      }
    
      function fileClass(video){

        if(video === "" || video === null || video === undefined){
          setShowImage(false)
          setShowVideo(true)
          return "Imagen";
        } else{
          setShowImage(true)
          setShowVideo(false)
          return "Video"
        }

      }
      function copyTextToClipboard(text) {
          if (navigator.clipboard && window.isSecureContext) {
              return navigator.clipboard.writeText(text);
          } else {
              let textArea = document.createElement("textarea");
              textArea.value = text;
              textArea.style.position = "fixed";
              textArea.style.left = "-999999px";
              textArea.style.top = "-999999px";
              document.body.appendChild(textArea);
              textArea.focus();
              textArea.select();
              return new Promise((res, rej) => {
                  document.execCommand('copy') ? res() : rej();
                  textArea.remove();
              });
          }
      }

      function handleSelect(){
        history.push("/files");
      }
    return (
      <>
      <div className="ui large breadcrumb" style={{paddingBottom:15}}>
          <i className="home red icon"></i>
          <a className="section" style={{color: "red"}} onClick={handleSelect}><FormattedMessage id="mediaManager.mediaManager" defaultMessage="Media Manager"/></a>
          <i className="right large angle icon divider"></i>
          <i className="section" >{viewFile.imageName}</i>
      </div>
      <div className="mainDiv">
        <div className="ui form">
          <table>
            <tbody>
            <tr>
              <td rowSpan="10">
                <Popup trigger={
                  <button className="search-icon" ><i className="ui white search icon"></i></button>
                } >
                    <span className={`${showImage ? "hide-Component" : "show-Component"}`}>
                      <img className="largeImage" src={viewFile.urlImage} alt={viewFile.imageName} />
                    </span>
                    <span className={`${showVideo ? "hide-Component" : "show-videoPlayer"}`}>
                      <Player playsInline src={viewFile.urlVideo} />
                    </span>
                </Popup>
                <img className="thumbnailImage" src={viewFile.urlImage} alt={viewFile.imageName} />
              </td>
            </tr>
            <tr className="trTablePopUp"><td><FormattedMessage id="media.nombreM" defaultMessage="NOMBRE" /></td></tr>
            <tr><td>{viewFile.imageName}</td></tr>
            <tr className="trTablePopUp"><td><FormattedMessage id="media.tipoM" defaultMessage="TIPO" /></td></tr>
            <tr><td>{fileType}</td></tr>
            <tr className="trTablePopUp"><td><FormattedMessage id="media.creacionM" defaultMessage="CREACIÓN" /></td></tr>
            <tr><td>{formatedDate}</td></tr>
            <tr className="trTablePopUp">
              <td><FormattedMessage id="media.linkM" defaultMessage="LINK" /> 
              <button className="button-icon" onClick={() => copyTextToClipboard(`${showVideo ? viewFile.urlImage : viewFile.urlVideo}`)}><i className="ui red copy icon"></i></button>
              </td>
            </tr>
            <tr><td>{`${showVideo ? viewFile.urlImage : viewFile.urlVideo}`}</td></tr>
            </tbody>
          </table>
        </div>
        <div>
          <button className="ui noBorder button" onClick={() => history.goBack()}>
            <FormattedMessage id="media.cancelar" defaultMessage="Cancelar" />
          </button>
        </div>
    </div>
    </>
  )
   
}

export default ViewFile;
