import UserService from './UserService';
import axios from "axios";



export const requestGetFeatured = (state, page, size, active, search) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_FEATURES;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
    authAxios.get(process.env.REACT_APP_URL_FULL_FEATURES, { params: {pageIndex: page, pageSize: size, active: active, search: search}})
   .then(response => state(response.data))
   .catch(error  =>  {
        console.log(error);
    }) 

}


export const requestGetListFeatured = (state) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_FEATURES;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
    authAxios.get(process.env.REACT_APP_URL_FULL_FEATURES)
   .then(response => state(response.data))
   .catch(error  =>  {
        console.log(error);
    }) 

}

export const getTotalNumberOfFiles  = async (state, active ) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_FEATURES;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
    await authAxios.get(process.env.REACT_APP_URL_FULL_FEATURES + "/numFiles", { params: {active: active}})
    .then(response => state(response.data))
    .catch(error  =>  {
        console.log(error);
    }) 

  }

export const requestDeleteFeatured = async (id, state) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_FEATURES;

    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            //Fix auth problem
            "Content-Security-Policy": "frame-ancestors none",
            //
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`
        }
    })
    await authAxios.delete(process.env.REACT_APP_URL_FULL_FEATURES + `/${id}`)
   .then(response => requestGetFeatured(state, 0, 20, ""))
   .catch(error  =>  {
        console.log(error);
    }) 

}