const data = [
    {
      "id": "inicioTituloEventos",
      "texto": "En Bilbao Bizkaia vas a encontrar cientos de planes para adentrarte en el azul del Cantábrico, relajarte en el ambiente rural de los valles y los parques naturales, descubrir los sabores de una gastronomía que te enamorará, aprovechar cada instante en nuestras ciudades y conocer nuestra cultura milenaria.",
      "flag": "ES"
    },
    {
      "id": "contactoDireccion",
      "texto": "Dirección",
      "flag": "ES"
    },
    {
      "id": "contactoTelefono",
      "texto": "Teléfono",
      "flag": "ES"
    },
    {
      "id": "inicioTituloNoticias",
      "texto": "Berriak",
      "flag": "EU"
    },
    {
      "id": "inicioTituloEventos",
      "texto": "Bilbao Bizkaian makina bat plan aurkituko dituzu Kantauri itsasoko urdinean sartzeko, haranetako eta parke naturaletako landa-giroan erlaxatzeko, maiteminduko zaituen gastronomia baten zaporeak dastatzeko, gure hirietan une oro aprobetxatzeko eta milaka urteko gure kultura ezagutzeko.",
      "flag": "EU"
    },
    {
      "id": "contactoDireccion",
      "texto": "Helbidea",
      "flag": "EU"
    },
    {
      "id": "contactoTelefono",
      "texto": "Telefonoa",
      "flag": "EU"
    }
      
];
  export default data;