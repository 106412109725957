import { Tooltip as ReactTooltip } from "react-tooltip";
import { Typography } from "@mui/material";

const columns = [
  {
    accessorKey: "id",
    header: "ID",
    size: 75,

  },
  {
    accessorKey: "nombre",
    header: "Nombre",
    size: 150,
  },
  {
    accessorKey: "roles",
    header: "Roles",
    size: 150,
  },

];

export default columns;
