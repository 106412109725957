import React, { useState} from "react";
import {requestSendNotification} from '../services/NotificationService'

const PushNotification = (props) => {


  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [topic, setTopic] = useState("All");
  function send(){
    
    const data = {
     
      id:id,
      title: title,
      token: "",
      body: text,
      topic: topic
    }
    requestSendNotification(data);
  }

    return(
      <div>
        <h1>Push Notifications</h1>
        <div className="ui form">
            <label>Notification ID</label>
            <input 
            type="text"
            value={id}
            onChange= {(e) => setId(e.target.value)}
            />
        </div>
        <div className="ui form">
            <label>Titulo</label>
            <input 
            type="text"
            value={title}
            onChange= {(e) => setTitle(e.target.value)}
            />
        </div>
        <div className="ui form">
            <label>Texto</label>
            <input 
            type="text"
            value={text}
            onChange= {(e) => setText(e.target.value)}
            />
        </div>
        <div className="ui form">
            <label>Topic</label>
            <input 
            type="text"
            value={topic}
            onChange= {(e) => setTopic(e.target.value)}
            />
        </div>
        <div><button className="ui red button" onClick={() => send() }>Mandar</button></div>
      </div>
    )
  }
export default PushNotification;