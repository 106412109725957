
import data from './data';
import columns from './columns';
import React, {useMemo, useState} from 'react';
import { MaterialReactTable} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FormattedMessage } from 'react-intl';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Visibility from '@mui/icons-material/Visibility'
import PersonAdd from '@mui/icons-material/PersonAdd'
import PersonRemove from '@mui/icons-material/PersonRemove'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
//import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Swal from 'sweetalert2'; 

import { NavLink } from 'react-router-dom';
import * as Constantes from '../../helpers/constantes';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

const Groups = () => {
    
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [disableButton, setDisabledButton] = useState(true);
    

    const handleRowClick = (row) => {
        // Deselecciona la fila anteriormente seleccionada si existe
        console.log("Selected row.id = " + row.id);
        if (selectedRowId === row.id) {
            setSelectedRowId(null);
            setDisabledButton(true);
        } else {
            setSelectedRowId(row.id); // Selecciona la nueva fila
            setDisabledButton(false);
        }
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    
    const onClickConsultar = (event) => {
        setOpen(true);
    }

    const onClickCrear= (event) => {

    }

    const onClickModificar = (event) => {
        
    }

    const onClickEliminar = (event) => {
        modalEliminar();
    }

    function modalEliminar(){
        Swal.fire({
            title: "¿Está seguro de que desea eliminar el grupo?",
            //text: "no se podrán deshacer!",
            icon: "question",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#3085d6",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            cancelButtonColor: "#d33",
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#3085d6",
                icon: "success",
                title: "Completado",
                text: "El grupo se ha eliminado correctamente",
              })
            }
          })
    }



    return (
    <>
        <div className='mainDiv' style={{paddingTop: 25, paddingRight: 15}}>

            <label className="title"> 
                <FormattedMessage id="grupos.titulo" defaultMessage="Gestión de grupos" />
            </label>

            <div style={{ marginTop:10, marginLeft:10, marginBottom: 15}}>
            <MaterialReactTable
                columns={columns} 
                data={data}
                enableDensityToggle={false}
                localization={MRT_Localization_ES}
                // enableRowSelection={true}
                // enableSelectAll={false}
                enableMultiRowSelection={false}
                positionToolbarAlertBanner='none'
                getRowId={(row) => row.userId}
                muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => handleRowClick(row),
                    selected: row.id === selectedRowId,
                    sx: {
                    cursor: 'pointer',
                    },
                })}
                state={{ selectedRowId }}                  
                initialState={{
                        columnVisibility: { idpAlias: false },
                        pagination: { pageSize: 5, pageIndex: 0 },
                        density: 'compact',
                }}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#4C4C4C'
                    },
                    }}
                muiTableBodyCellProps={({ cell }) => ({
                    onClick: (event) => {
                    console.info(event, cell.id);
                    var columna = cell.id.split("_")[1];
                    console.log("CELL VALUE: " + cell.getValue());
                    //setShowModal(true);
                    },
                })}
                renderTopToolbarCustomActions={({ table }) => {
                    return (
                        <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'flex-start', paddingTop: 5 }}>
                            <NavLink to={`groupForm/${Constantes.ACTION_CREAR}`}> 
                                <Button
                                    // disabled={disableButton}
                                    className="table-button-red"
                                    size='small'
                                    onClick={onClickCrear}
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    startIcon={<PlaylistAddIcon />}
                                    variant="contained"
                                >
                                    Crear
                                </Button>
                            </NavLink> 

                            <NavLink to={`groupForm/${Constantes.ACTION_CONSULTAR}`}> 
                                <Button
                                    disabled={disableButton}
                                    className="table-button-red"
                                    size='small'
                                    onClick={onClickConsultar}
                                    startIcon={<ManageSearchIcon />}
                                    variant="contained"
                                >
                                    Consultar
                                </Button>
                            </NavLink>

                            <NavLink to={`groupForm/${Constantes.ACTION_MODIFICAR}`}> 
                                <Button
                                    disabled={disableButton}
                                    className="table-button-red"
                                    size='small'
                                    onClick={onClickModificar}
                                    startIcon={<EditNoteIcon />}
                                    variant="contained"
                                >
                                    Modificar
                                </Button>
                            </NavLink>

                            <Button
                                disabled={disableButton}
                                className="table-button-red"
                                size='small'
                                onClick={onClickEliminar}
                                startIcon={<PlaylistRemoveIcon />}
                                variant="contained"
                            >
                                Eliminar
                            </Button>
                        </div>
                    );
                    }}

            />
            </div>

        </div>  
    </>
    );
};

export default Groups;