import { Tooltip as ReactTooltip } from "react-tooltip";
import { Typography } from "@mui/material";

const columns = [
  {
    accessorKey: "id",
    header: "ID",
    size: 200,

  },
  {
    accessorKey: "flag",
    header: "Idioma",
    size: 50,
  },
  {
    accessorKey: "texto",
    header: "Texto",
    size: 200,
  },

];

export default columns;
