import { Tooltip as ReactTooltip } from "react-tooltip";

const columns = [
  {
    accessorKey: "event_timestamp", //access nested data with dot notation
    header: "Timestamp",
    size: 200,

  },
  {
    accessorKey: "message_id",
    header: "ID",
    size: 150,
  },
  {
    accessorKey: "message_type", //normal accessorKey
    header: "Tipo",
    size: 100,
  },
  {
    accessorKey: "sdk_platform",
    header: "SO",
    size: 75,
    Cell: ({ cell }) => (
        <>
            <i data-tooltip-id={cell.id} className={
                cell.getValue() === "ANDROID" ? "ui icon-android icon" :
                cell.getValue() === "IOS" ? "ui icon-ios icon" : 
                "ui icon-question icon"}>
            </i>
            <ReactTooltip
                id={cell.id}
                place="bottom"
                content={
                cell.getValue() === "ANDROID" ? "Android" :
                cell.getValue() === "IOS" ? "IOS" : 
                "Desconocido"}
            />
        </>
      ),
  },
  {
    accessorKey: "topic",
    header: "Topic",
    size: 100,
  },
  {
    accessorKey: "event",
    header: "Estado",
    size: 125,
    Cell: ({ cell }) => (
        <>
            <span data-tooltip-id={cell.id}>{cell.getValue()}<sup> ?</sup></span>
            <ReactTooltip
                id={cell.id}
                place="bottom"
                content="El servidor de FCM recibió el mensaje y la solicitud es válida"
            />
        </>
    ),
  },
  {
    accessorKey: "analytics_label",
    header: "Etiqueta Analytics",
    size: 100,
  },
];

export default columns;
