import React, { useState, useEffect}   from 'react';
import {NavLink} from "react-router-dom";
import {requestGetNewSearch} from '../../services/BeachesService';
import Moment from 'moment';
import {FormattedMessage} from 'react-intl';
import UserService from '../../services/UserService';
import {langContext} from './../../context/langContext';
import axios from "axios";

const Beaches = () => {

    const [totalPost, setTotalPost] = useState()
    const [beaches, setBeaches] = useState([]);
    const [dinamicStatus, setDinamicStatus] = useState();
    const [searchBeach, setSearchBeach] = useState("");
    const [searchCity, setSearchCity] = useState("");
    const [page, setPage] = useState(0);
    const [size] = useState(10);
    const [hideInSeassonComponent, setHideInSeassonComponent] = useState(true);
    const [hideOutSeassonComponent, setHideOutSeassonComponent] = useState(false);
    const [warningTextEs, setWarningTextEs] = useState("");
    const [warningTextEu, setWarningTextEu] = useState("");
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const idioma = React.useContext(langContext);
    const [lastLanguage, setlastLanguage] = useState(idioma.localePorDefecto);

    useEffect(() => {
      requestGetNewSearch(setBeaches, page, size, searchBeach, searchCity);
      getConfiguration();
      getTotalNumber(searchBeach, searchCity);
    },[])

    const getConfiguration = async () => {
      const headers = {
          "Content-Security-Policy": "frame-ancestors none",
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + UserService.getToken()
      }
   
      const url = process.env.REACT_APP_URL_FULL_BEACHES + `/configuration`; 
      const data = await (await axios.get(url, { 'headers':  headers })).data;
      const dataConfiguration = await data;

      setWarningTextEs(dataConfiguration[0].warningEs);
      setWarningTextEu(dataConfiguration[0].warningEu);
      setInitialDate(new Date(dataConfiguration[0].initialDate));
      setFinalDate(new Date(dataConfiguration[0].finalDate));

      seassonLabel(dataConfiguration[0]);
    }
    function seassonLabel(dataConfiguration){
        const initialDate = Moment(new Date(dataConfiguration.initialDate));
        const finalDate = Moment(new Date(dataConfiguration.finalDate));
        const todayDate =  Moment(Date.now());

        if(todayDate.isAfter(initialDate) && todayDate.isBefore(finalDate)){
          setHideInSeassonComponent(false);
          setHideOutSeassonComponent(true);
        }else{
          setHideInSeassonComponent(true);
          setHideOutSeassonComponent(false);
        }
    }
    function getTotalNumber(beachName, cityName) {
      const html = axios.create({
        baseURL: process.env.REACT_APP_URL_FULL_BEACHES,
        headers: {
          //Fix auth problem
             "Content-Security-Policy": "frame-ancestors none",
              //
          "Content-type": "application/json",
          "Authorization": "Bearer " + UserService.getToken()
        }
      });
      html.get(process.env.REACT_APP_URL_FULL_BEACHES + "/numTotal", { params: {beachName: beachName, cityName: cityName}})
      .then((response) => {
        setTotalPost(response.data);
      })
  }
    function goToPage(nPage) {
      if(nPage < 0) nPage = 0
  
      requestGetNewSearch(setBeaches, nPage, size, searchBeach, searchCity)
      setPage(nPage)
    }
    function nextList() {
      var pageNumber = pageNumbers.length + 1;
      if(pageNumber === Number(page)+1){
        goToPage(Number(page));
      }else{
        goToPage(Number(page)+1);
      }
    }
    function prevList() {
      goToPage(Number(page)-1);
    }
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPost / size); i++) {
      pageNumbers.push(i);
    }

    function searchByCity(cityName){
      setSearchCity(cityName);
      setPage(0);
      requestGetNewSearch(setBeaches, 0, size, searchBeach, cityName);
      getTotalNumber(searchBeach, cityName);
    }
    
    function searchByBeach(){
      setPage(0);
      requestGetNewSearch(setBeaches, 0, size, searchBeach, searchCity);
      getTotalNumber(searchBeach, searchCity);
    }
    const handleChange = e => {
      setSearchBeach(e.target.value);
    }

    function limpiaBusqueda(){
      setPage(0);
      setSearchCity("");
      setSearchBeach("");
      requestGetNewSearch(setBeaches, 0 , size, "", "" );
      getTotalNumber("", "");
    }

    let emptyTable;

    if(beaches.length > 0){
      emptyTable = 
        beaches.map(data => (
          <tbody>
            <tr key={data.id}>
              <td className='center aligned row'>{data.beachName}</td>
              <td className='center aligned row'>{data.cityName}</td>
              <td className='center aligned row'><img className="thumbnailImageMini" src={data.urlImgMini} alt={data.beachName}/></td>
              <td> 
                <div className="ui small basic icon buttons">
                  <NavLink to={`view-beaches/${data.id}`}>  
                  <button className="ui button" ><i className="ui black eye icon"></i></button>
                  </NavLink>
                  <NavLink to={`edit-beaches/${data.id}`}>  
                  <button className="ui button"><i className="ui black pencil alternate icon"></i></button>
                  </NavLink>
                  </div>
              </td>
            </tr>
          </tbody>
        ))
    }else  if(null !== searchCity && searchCity !== ""){
      emptyTable = <tbody><tr><td colSpan="3" style={{textAlign:'center', fontSize: '25px', padding: '20px'}}> <FormattedMessage id="playas.noExistenPlayasLocalidad" defaultMessage={"No existen playas para la localidad de "+ searchCity}  values={{searchCity: searchCity}}/></td></tr></tbody>;
    }else{
      emptyTable = <tbody><tr><td colSpan="3" style={{textAlign:'center', fontSize: '25px', padding: '20px'}}> <FormattedMessage id="playas.noExistenPlayas" defaultMessage="No existen playas"/></td></tr></tbody>;
    }
    
    function cambiolistaIdioma(){
      if(lastLanguage !== idioma.localePorDefecto || lastLanguage === ""){
        setlastLanguage(idioma.localePorDefecto);
      }
     
    }
return (
<>
<p style={{color: "red", fontSize:20}}>v0.4</p>
  <div className='mainDiv'>
    <label className="title" style={{paddingTop: 10}}>
      <FormattedMessage id="playas.titulo" defaultMessage="PLAYAS" onChange={cambiolistaIdioma()}/>
    </label>
    <div>
      <div className='headerLine'>
          <NavLink to={"conf-beaches"}>
          <button className="ui red button">
            <FormattedMessage id="playas.configurarPlaya" defaultMessage="CONFIGURAR"/>
          </button>
          </NavLink>
          <div style={{paddingLeft: 5}} className={`${hideOutSeassonComponent ? "hide-AppComponent" : "outSeasson icon input"}`} >
            <i className="icon-alert yellow icon" style={{paddingTop: 35}}></i>
            <label style={{position: 'relative', top: -12}}><FormattedMessage id="playas.fueraTemporada" defaultMessage="Fuera de temporada"/></label>
          </div>
          <div style={{paddingLeft: 10}} className={`${hideInSeassonComponent ? "hide-AppComponent" : "inSeasson icon input"}`} >
            <i className="icon-check-green icon" style={{paddingTop: 20}}></i>
            <label style={{position: 'relative', top: -10}}><FormattedMessage id="playas.enTemporada" defaultMessage="En temporada" /></label>
          </div>
          <span style={{float:'right', marginRight:30}}>
            <select className="ui select" style={{width:200}} value={searchCity} name="selectState"  onChange={e => searchByCity(e.target.value)} >
                <FormattedMessage id="playas.selectLocalidad" defaultMessage="Selecciona una localidad">
                  {(message) => <option  selected disabled value="">{message}</option>}
                </FormattedMessage>
                <option value="BAKIO">BAKIO</option>
                <option value="BARRIKA">BARRIKA</option>
                <option value="BARRIKA Y SOPELA">BARRIKA Y SOPELA</option>
                <option value="BERMEO">BERMEO</option>
                <option value="BUSTURIA">BUSTURIA</option>
                <option value="EA">EA</option>
                <option value="GAUTEGIZ DE ARTEAGA">GAUTEGIZ DE ARTEAGA</option>
                <option value="GETXO">GETXO</option>
                <option value="GETXO Y SOPELA">GETXO Y SOPELA</option>
                <option value="GORLIZ">GORLIZ</option>
                <option value="IBARRANGELU">IBARRANGELU</option>
                <option value="ISPASTER">ISPASTER</option>
                <option value="LEKEITIO">LEKEITIO</option>
                <option value="LEMOIZ">LEMOIZ</option>
                <option value="MENDEXA">MENDEXA</option>
                <option value="MUNDAKA">MUNDAKA</option>
                <option value="MUSKIZ Y ZIERBENA">MUSKIZ Y ZIERBENA</option>
                <option value="ONDARROA">ONDARROA</option>
                <option value="PLENTZIA">PLENTZIA</option>
                <option value="SOPELA">SOPELA</option>
                <option value="SUKARRIETA">SUKARRIETA</option>
            </select>
            <div style={{marginLeft:30, marginRight:30}} className="ui icon input">
              <FormattedMessage id="playas.buscar" defaultMessage="Buscar">
                {placeholder=> <input type="text" value={searchBeach} placeholder={placeholder} onChange={handleChange}/> }
              </FormattedMessage>
              <i className="search link black icon" style={{paddingTop:40}} onClick={() => searchByBeach()}></i>
            </div>
            <button className="ui red button" onClick={() => limpiaBusqueda()}>
              <FormattedMessage id="playas.limpiarBusqueda" defaultMessage="Limpiar filtro"/>
            </button>
          </span>
      </div>
    </div>
    <div style={{paddingTop:10}}>
      <label>
        <b><FormattedMessage id="playas.avisoSecundario" defaultMessage="Aviso secundario" /></b>
        <br></br>
        {lastLanguage === 'eus-ES' ? warningTextEu : warningTextEs}
      </label>
    </div>
    <div className='paddingTableNews'>    
      <table className="ui  table">       
        <thead>
          <tr className='center aligned row'>
            <th><FormattedMessage id="playas.nombrePlaya" defaultMessage="Nombre playa"/></th>
            <th><FormattedMessage id="playas.poblacion" defaultMessage="Población"/></th>
            <th><FormattedMessage id="playas.fotografia" defaultMessage="Fotografía"/></th>
            <th></th>
          </tr>     
        </thead>      
     
        {emptyTable}
      
    </table>
    <div className="content" style={{textAlign:"center"}}>
        <div>
          <button className="button-icon" onClick={prevList}><i className="ui chevron left icon"></i></button>
          {pageNumbers.map(number => (
            page === (number-1) ? <button className="ui red button" style={{padding:'auto'}} onClick={() => goToPage(number-1)}>{number}</button> : <button className="button-icon" onClick={() => goToPage(number-1)}>{number}</button> 
              
          ))}
          <button className="button-icon" onClick={nextList}><i className="ui chevron right icon"></i></button>
        </div>
      </div>
    </div>
  </div>
  </>
    
) 
       
} 
 export default Beaches

