import React, {useState} from 'react';
import MensajesEuskera from './../lang/eus-ES.json';
import MensajesCastellano from './../lang/es-ES.json';
import {IntlProvider} from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({children}) => {
	let localePorDefecto;
	let mensajesPorDefecto;
	const lang = localStorage.getItem('lang');

	if(lang){
		localePorDefecto = lang

		if(lang === 'es-ES'){
			mensajesPorDefecto = MensajesCastellano;
		} else if(lang === 'eus-ES'){
			mensajesPorDefecto = MensajesEuskera
		} else {
			localePorDefecto = 'es-ES'
			mensajesPorDefecto = MensajesCastellano
		}
	}

	const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
	const [locale, establecerLocale] = useState(localePorDefecto);

	const establecerLenguaje = (lenguaje) => {
		switch (lenguaje){
			case 'es-ES':
				establecerMensajes(MensajesCastellano);
				establecerLocale('es-ES');
				localStorage.setItem('lang', 'es-ES');
				break;
			case 'eus-ES':
				establecerMensajes(MensajesEuskera);
				establecerLocale('eus-ES');
				localStorage.setItem('lang', 'eus-ES');
				break;
			default:
				establecerMensajes(MensajesCastellano);
				establecerLocale('es-ES');
				localStorage.setItem('lang', 'es-ES');
		}
	}


	return (
		<langContext.Provider value={{establecerLenguaje: establecerLenguaje, localePorDefecto: localePorDefecto}}>
			<IntlProvider locale={locale} messages={mensajes}>
				{children}
			</IntlProvider>
		</langContext.Provider>
	);
}
 
export {LangProvider, langContext};