import {FormattedMessage} from 'react-intl';
import React, { useState, useEffect, useContext } from 'react';
import es from "date-fns/locale/es";
import {registerLocale} from "react-datepicker";
import { blue } from '@mui/material/colors';
import { getLibraryConfig, updateLibraryConfig, updateLibraryConfigLocalhost } from '../../services/LibraryService';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { requestPutSectionBlock, requestGetSectionBlock, updateSectionBlock, getSectionBlock } from '../../services/ConfigureHomeService';
import { langContext } from '../../context/langContext';

const Library = () => {

    const FIELDS = {
        ID: "id",
        INPUT_MENSAJE_CASTELLANO: "esMessage",
        INPUT_MENSAJE_EUSKERA: "euMessage",
        INPUT_FECHA_INICIO: "startDate",
        INPUT_FECHA_FIN: "endDate",
    };

    const MAX_LENGTH = {
        [FIELDS.INPUT_MENSAJE_CASTELLANO]: 200,
        [FIELDS.INPUT_MENSAJE_EUSKERA]: 200
    }

    const INFO_ICONS = {
        INFO_CIRCLE: "info-circle",
        EXCLAMATION_TRIANGLE: "exclamation-triangle",
        EXCLAMATION_CIRCLE: "exclamation-circle",
        CALENDAR: "calendar"
    }

    const VISIBILITY_ICONS = {
        VISIBLE: "visible",
        NOT_VISIBLE: "notVisible"
    }

    const getLibraryConfigInitialState = () => {
        return {
            [FIELDS.ID]: 1, // Hardcode ID biblioteca
            [FIELDS.INPUT_MENSAJE_CASTELLANO]: "",
            [FIELDS.INPUT_MENSAJE_EUSKERA]: "",
            [FIELDS.INPUT_FECHA_INICIO]: "",
            [FIELDS.INPUT_FECHA_FIN]: "",
        };
    };

    const getLibraryConfigValidationInitialState = () =>{
        return {
            [FIELDS.INPUT_MENSAJE_CASTELLANO]: true,
            [FIELDS.INPUT_MENSAJE_EUSKERA]: true,
            [FIELDS.INPUT_FECHA_INICIO]: true,
            // [FIELDS.INPUT_FECHA_FIN]: true,
        };        
    }

    const getInfoIconsInitialState = () => {
        return {
            [INFO_ICONS.INFO_ICONS]: '',
            [INFO_ICONS.EXCLAMATION_TRIANGLE]: '',
            [INFO_ICONS.EXCLAMATION_CIRCLE]: '',
            [INFO_ICONS.CALENDAR]: ''
        };
    };

    const getVisibilityIconsInitialState = () => {
        return {
            [VISIBILITY_ICONS.VISIBLE]: '',
            [VISIBILITY_ICONS.NOT_VISIBLE]: ''
        };
    };

    const { establecerLenguaje } = useContext(langContext);
    const [libraryConfig, setLibraryConfig] = useState(getLibraryConfigInitialState());
    const [libraryConfigValidation, setLibraryConfigValidation] = useState(getLibraryConfigValidationInitialState());
    const [infoMessageId, setInfoMessageId] = useState("biblioteca.null");
    const [visibilityMessageId, setVisibilityMessageId] = useState("biblioteca.null");
    const [dayNumberDifference, setDayNumberDifference] = useState(0);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [infoIcons, setInfoIcons] = useState(getInfoIconsInitialState());
    const [visibilityIcon, setVisibilityInfoIcon] = useState(getVisibilityIconsInitialState())
    const [infoMsgTextColor, setInfoMsgTextColor] = useState("black");
    const [minRangeEndDate, setMinRangeEndDate] = useState("");
    const [maxRangeStartDate, setMaxRangeStartDate] = useState("");
    const [sectionBlock, setSectionBlock] = useState([]);
  
    const getVisibilityIcons = (icon) => {
        const newIcons = getVisibilityIconsInitialState();
        switch (icon) {
            case VISIBILITY_ICONS.VISIBLE:
                newIcons[VISIBILITY_ICONS.VISIBLE] = 'eye icon green';
                break;
            case VISIBILITY_ICONS.NOT_VISIBLE:
                newIcons[VISIBILITY_ICONS.NOT_VISIBLE] = 'eye slash icon red';
                break;           
            default:
                break;
        }
        return newIcons;
    };
    
    const getInfoIcons = (icon) => {
        const newIcons = getInfoIconsInitialState();
        switch (icon) {
            case INFO_ICONS.INFO_CIRCLE:
                newIcons[INFO_ICONS.INFO_CIRCLE] = 'info circle icon blue';
                setInfoMsgTextColor("#2185d0");
                break;
            case INFO_ICONS.EXCLAMATION_TRIANGLE:
                newIcons[INFO_ICONS.EXCLAMATION_TRIANGLE] = 'exclamation triangle icon darkorange';
                setInfoMsgTextColor("darkorange");
                break;
            case INFO_ICONS.EXCLAMATION_CIRCLE:
                newIcons[INFO_ICONS.EXCLAMATION_CIRCLE] = 'exclamation circle icon red';
                setInfoMsgTextColor("#db2828");
                break;
            case INFO_ICONS.CALENDAR:
                newIcons[INFO_ICONS.CALENDAR] = 'calendar alternate icon violet';
                setInfoMsgTextColor("#6435c9");
                break;                
            default:
                break;
        }
        return newIcons;
    };    

    const handleLibraryConfigInputChange = (event) => {
        const { name, value} = event.target;
        setLibraryConfigInputValue(name, value)
        setLibraryConfigValidationValue(name, value);
    };

    const setLibraryConfigInputValue = (name, value) => {
        if(name == FIELDS.INPUT_MENSAJE_CASTELLANO || name == FIELDS.INPUT_MENSAJE_EUSKERA ){
            if (value.length <= MAX_LENGTH[name]) {
                setLibraryConfig({...libraryConfig, [name]: value});
            }
        }else{
            setLibraryConfig({...libraryConfig, [name]: value});
        }
    }

    const setLibraryConfigValidationValue = (name, value) =>{
        if(name == FIELDS.INPUT_MENSAJE_CASTELLANO && value !== "") {
            setLibraryConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_CASTELLANO]: true }));
        }
        if(name == FIELDS.INPUT_MENSAJE_EUSKERA && value !== ""){
            setLibraryConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_EUSKERA]: true }));
        }
        if(name == FIELDS.INPUT_FECHA_INICIO && value !== ""){
            setLibraryConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_INICIO]: true }));
            setMinRangeEndDate(value);
        }
        if(name == FIELDS.INPUT_FECHA_FIN && value !== ""){
            setMaxRangeStartDate(value);
        }        
    }

    const showSuccessAlert = (successMsg) => {
        setSuccessMessage(successMsg);
        setShowSuccessMessage(true);
        // Timer para ocultar el mensaje tras 5 segundos
        setTimeout (() => {
            setShowSuccessMessage(false);
        }, 5000); 
    }

    const showErrorAlert = (error, errorMsg) => {
        console.log(error);
        if(error.response.status == 0){
            errorMsg += error.code + " " + error.message;
        }else{
            errorMsg +=  error.response.status + " " + error.response.statusText;
        }
        setErrorMessage(errorMsg);
        setShowErrorMessage(true); 
    }

    const onClickGuardar = () => {
        setLibraryConfigValidation(getLibraryConfigValidationInitialState());
    
        let isRequiredFieldsValidate = true;
    
        if (libraryConfig[FIELDS.INPUT_MENSAJE_CASTELLANO] === "") {
            setLibraryConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_CASTELLANO]: false }));
            isRequiredFieldsValidate = false;
        }
        if(libraryConfig[FIELDS.INPUT_MENSAJE_EUSKERA] === ""){
            setLibraryConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_EUSKERA]: false }));
            isRequiredFieldsValidate = false;
        }
        if(libraryConfig[FIELDS.INPUT_FECHA_INICIO] === ""){
            setLibraryConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_INICIO]: false }));
            isRequiredFieldsValidate = false;
        }

        if (isRequiredFieldsValidate) {

            // let formattedStartDate = new Date(libraryConfig[FIELDS.INPUT_FECHA_INICIO]).toISOString().replace("Z", "+00:00");
            // libraryConfig[FIELDS.INPUT_FECHA_INICIO] = formattedStartDate;

            // if(libraryConfig[FIELDS.INPUT_FECHA_FIN] != ""){
            //     let formattedEndDate = new Date(libraryConfig[FIELDS.INPUT_FECHA_FIN]).toISOString().replace("Z", "+00:00");
            //     libraryConfig[FIELDS.INPUT_FECHA_FIN] = formattedEndDate;
            // }
            
            updateLibraryConfig(libraryConfig)
                .then(result =>{
                    setSeasonMessage(libraryConfig);
                    getSectionBlock()
                        .then(result => {
                            console.log(result)
                            console.log(result.data)
                            let id = 4 // ID Sección de reservas
                            let body = getSectionBlockBody(result.data);
                            updateSectionBlock(id, body)
                                .then(result => {
                                    requestGetSectionBlock(setSectionBlock);
                                    showSuccessAlert("Se ha actualizado la configuración de reserva de biblioteca correctamente");
                                })
                                .catch(error => {
                                    showErrorAlert(error, "Error al actualizar la configuración de bloques: ");    
                                });
                        })
                        .catch(error => {
                            showErrorAlert(error, "Error al obtener la configuración de bloques: ");  
                        });                 
                })
                .catch(error => {
                    showErrorAlert(error, "Error al actualizar la configuración de reserva de la biblioteca: ");                  
                });
        }

    }

    const setVisibilityMessage = (isVisible) =>{
        if(isVisible){
            setVisibilityInfoIcon(getVisibilityIcons(VISIBILITY_ICONS.VISIBLE));
            setVisibilityMessageId("biblioteca.visibilityMsgVisible")
        }else{
            setVisibilityInfoIcon(getVisibilityIcons(VISIBILITY_ICONS.NOT_VISIBLE));
            setVisibilityMessageId("biblioteca.visibilityMsgOculto")
        }

    }

    const getVisibilityValue = () => {
        let momentCurrentDate = moment().startOf('day');
        let momentStartDate = moment(libraryConfig[FIELDS.INPUT_FECHA_INICIO]).startOf('day');
        let momentValidationDate = moment(libraryConfig[FIELDS.INPUT_FECHA_FIN]).startOf('day').add(7, 'days');
       
        if(momentCurrentDate.isBefore(momentStartDate) || momentCurrentDate.isAfter(momentValidationDate)){
            return false;
        }else{
            return true;
        }        
    }

    const setSeasonMessage = (libraryConfigData) => {
        console.log("setSeasonMessage()");
        console.log(libraryConfigData);
        let momentCurrentDate = moment().startOf('day');
        let momentStartDate = moment(libraryConfigData[FIELDS.INPUT_FECHA_INICIO]).startOf('day');
        let momentValidationDate = moment(libraryConfigData[FIELDS.INPUT_FECHA_FIN]).startOf('day').add(7, 'days');
        let momentEndDate = null;

        if(libraryConfigData[FIELDS.INPUT_FECHA_FIN] != null && libraryConfigData[FIELDS.INPUT_FECHA_FIN] != ""){
            momentEndDate = moment(libraryConfigData[FIELDS.INPUT_FECHA_FIN]).startOf('day');
        }

        if(momentCurrentDate.isBefore(momentStartDate)){
            //  actual < inicio *** false 
            let dayNumberDifference = momentStartDate.diff(momentCurrentDate, 'days');
            if(dayNumberDifference > 1){
                setInfoMessageId("biblioteca.infoMsg.inicioPlanificadoDiasRestantes");
            }else {
                setInfoMessageId("biblioteca.infoMsg.inicioPlanificadoDiaRestante");
            }            
            setDayNumberDifference(dayNumberDifference);
            setInfoIcons(getInfoIcons(INFO_ICONS.CALENDAR));
        } else if(momentCurrentDate.isAfter(momentStartDate)){
            if(momentEndDate != null){
                if(momentCurrentDate.isSame(momentEndDate)){
                    //  actual > inicio & actual = fin *** true
                    setInfoMessageId("biblioteca.infoMsg.finalizaHoy");
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
                } else if(momentCurrentDate.isBefore(momentEndDate)){
                    //  actual > inicio & actual < fin *** true
                    let dayNumberDifference = momentEndDate.diff(momentCurrentDate, 'days');
                    if(dayNumberDifference > 1){
                        setInfoMessageId("biblioteca.infoMsg.diasRestantes");
                    }else{
                        setInfoMessageId("biblioteca.infoMsg.diaRestante");
                    }                    
                    setDayNumberDifference(dayNumberDifference);
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));                       
                } else if(momentCurrentDate.isAfter(momentEndDate)){
                    if(momentCurrentDate.isSame(momentValidationDate)){
                        //  actual > fin & actual = 7dias *** true
                        setInfoMessageId("biblioteca.infoMsg.finalizaHoyAlerta");
                        setInfoIcons(getInfoIcons(INFO_ICONS.EXCLAMATION_TRIANGLE));                        
                    } else if(momentCurrentDate.isBefore(momentValidationDate)){
                        //  actual > fin & actual < 7dias *** true
                        let dayNumberDifference = momentValidationDate.diff(momentCurrentDate, 'days');
                        setDayNumberDifference(dayNumberDifference);
                        if(dayNumberDifference > 1){
                            setInfoMessageId("biblioteca.infoMsg.finalizadoDiasRestantes");
                        }else{
                            setInfoMessageId("biblioteca.infoMsg.finalizadoDiaRestante");
                        }                        
                        setInfoIcons(getInfoIcons(INFO_ICONS.EXCLAMATION_TRIANGLE));                        
                    } else if(momentCurrentDate.isAfter(momentValidationDate)){
                        //  actual > fin & actual > 7dias *** false
                        setInfoMessageId("biblioteca.infoMsg.finalizado");
                        setInfoIcons(getInfoIcons(INFO_ICONS.EXCLAMATION_CIRCLE));
                    }
                }
            } else{
                //  actual > inicio & fin = null *** true
                setInfoMessageId("biblioteca.infoMsg.indefinida");
                setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
            }
        } else if(momentCurrentDate.isSame(momentStartDate)){
            if(momentEndDate != null){
                if(momentCurrentDate.isSame(momentEndDate)){
                    //  actual = inicio & actual = fin *** true
                    setInfoMessageId("biblioteca.infoMsg.finalizaHoy");
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
                }else if(momentCurrentDate.isBefore(momentEndDate)){
                    //  actual = inicio & actual < fin *** true
                    let dayNumberDifference = momentEndDate.diff(momentCurrentDate, 'days');
                    setDayNumberDifference(dayNumberDifference);
                    setInfoMessageId("biblioteca.infoMsg.diasRestantes");
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));                    
                }
            } else{
                //  actual = inicio & fin = null *** true 
                setInfoMessageId("biblioteca.infoMsg.indefinida");
                setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
            }
        }
    }

    const loadLibraryConfig = () => {
        getLibraryConfig()
            .then(result => {
                console.log("getLibraryConfig(): ");
                console.log(result);
                let libraryConfigData = result.data[0];

                const formattedStartDate = moment(libraryConfigData[FIELDS.INPUT_FECHA_INICIO]).format('YYYY-MM-DD');

                libraryConfigData[FIELDS.INPUT_FECHA_INICIO] = formattedStartDate;
                setMinRangeEndDate(libraryConfigData[FIELDS.INPUT_FECHA_INICIO]);
                
                if(libraryConfigData[FIELDS.INPUT_FECHA_FIN] != null && libraryConfigData[FIELDS.INPUT_FECHA_FIN] != ""){
                    const formattedEndDate = moment(libraryConfigData[FIELDS.INPUT_FECHA_FIN]).format('YYYY-MM-DD');
                    libraryConfigData[FIELDS.INPUT_FECHA_FIN] = formattedEndDate;
                    setMaxRangeStartDate(libraryConfigData[FIELDS.INPUT_FECHA_FIN] );
                }
        
                setLibraryConfig(libraryConfigData);
                setSeasonMessage(libraryConfigData);

            }).catch(error => {
                showErrorAlert(error, "Error al obtener la configuración de la biblioteca: ");                  
            });
    };

    /*
    const loadLibraryConfigLocalhost = () => {
        var libraryConfigData = {
            "id": 1,
            "startDate": "2024-02-01T11:00:00.000+00:00",
            "endDate": "",
            "esMessage": "Hasta nuevo aviso, ya no es necesaria cita previa ni reserva de puesto para estudio con material propio en la Biblioteca Foral.",
            "euMessage": "Beste abisu bat jaso arte, ez da beharrezkoa aldez aurretik hitzordurik egitea, ezta lanpostua erreserbatzea ere, Foru Liburutegian norberaren materialarekin ikasteko."
        }

        const formattedStartDate = moment(libraryConfigData[FIELDS.INPUT_FECHA_INICIO]).format('YYYY-MM-DD');
        libraryConfigData[FIELDS.INPUT_FECHA_INICIO] = formattedStartDate;
        setMinRangeEndDate(libraryConfigData[FIELDS.INPUT_FECHA_INICIO]);

        console.log(libraryConfigData[FIELDS.INPUT_FECHA_FIN]);

        if(libraryConfigData[FIELDS.INPUT_FECHA_FIN] != null && libraryConfigData[FIELDS.INPUT_FECHA_FIN] != ""){
            console.log("entro");
            const formattedEndDate = moment(libraryConfigData[FIELDS.INPUT_FECHA_FIN]).format('YYYY-MM-DD');
            libraryConfigData[FIELDS.INPUT_FECHA_FIN] = formattedEndDate;
            setMaxRangeStartDate(libraryConfigData[FIELDS.INPUT_FECHA_FIN] );
        }

        setLibraryConfig(libraryConfigData);
        setSeasonMessage(libraryConfigData);
    }
    */

    const getSectionBlockBody = (sectionBlockUpdate) =>{
        let body;
        let libraryVisibility = getVisibilityValue();

        for(let i = 0; i < sectionBlockUpdate.length; i++){
            if(sectionBlockUpdate[i]["idSection"] == 4){ // reservas
                body = sectionBlockUpdate[i];
            }
        }
        for(let i = 0; i < body["block"].length; i++){
            if(body["block"][i]["idBlock"] == 9){ // biblioteca_foral
                body["block"][i]["visible"] = libraryVisibility;
            }    
        }

        console.log("BODY: ");
        console.log(body);
        return body;
    }

    const findVisibilityByIdBlock = (idBlock) => {
        // Iterar sobre cada sección en el JSON
        for (let section of sectionBlock) {
            // Verificar si la sección tiene bloques
            if (section.block && section.block.length > 0) {
                // Iterar sobre cada bloque de la sección
                for (let block of section.block) {
                // Verificar si el idBlock coincide
                    if (block.idBlock === idBlock) {
                        // Retornar el valor de "visible"
                        return block.visible;
                    }
                }
            }
        }
        return null;
    };

    useEffect(() => {
        establecerLenguaje('es-ES');
        registerLocale("es", es);
        requestGetSectionBlock(setSectionBlock);
        //loadLibraryConfigLocalhost();
        loadLibraryConfig();
    }, []); 


    useEffect(() => {
        if (sectionBlock.length > 0) {
            console.log("El sectionBlock se ha llenado:", sectionBlock);
            let visibility = findVisibilityByIdBlock(9); // biblioteca_foral
            setVisibilityMessage(visibility);
        }
    }, [sectionBlock]);

    return (
        <>
            {/* Contenedor principal */}
            <div className='mainDiv' style={{paddingTop: 15, paddingRight: 15}}>

                {/* Mensaje alerta error */}
                <Collapse in={showErrorMessage}>
                    <Alert 
                        style={{marginLeft: 10, marginRight: 10,  marginBottom: 15, display: 'flex', alignItems: 'center'}} 
                        onClose={() => {setShowErrorMessage(false)}} 
                        severity="error"
                    >{errorMessage}
                    </Alert>
                </Collapse>

                {/* Mensaje alerta exito */}
                <Collapse in={showSuccessMessage}>
                    <Alert 
                        style={{marginLeft: 10, marginRight: 10, marginBottom: 15, display: 'flex', alignItems: 'center'}} 
                        onClose={() => {setShowSuccessMessage(false)}} 
                        severity="success"
                    >{successMessage}
                    </Alert>
                </Collapse>

                {/* Titulo */}
                <label className="title">
                    <FormattedMessage id="biblioteca.title" defaultMessage="Configuración reserva de biblioteca" />
                </label>

                {/* Subtitulo - Visibilidad en la App */}
                <label className="sub-title title-flx"> 
                    <FormattedMessage id="biblioteca.subtitle.visibilidad" defaultMessage="Visibilidad en la App" />
                </label>

                {/* Sección - Visibilidad en la App */}
                <div className='' style={{marginBottom: 25}}>
                    <span className="input-group" style={{color: 'black', marginLeft:15}}>
                        <i className={visibilityIcon[VISIBILITY_ICONS.VISIBLE]}></i>
                        <i className={visibilityIcon[VISIBILITY_ICONS.NOT_VISIBLE]}></i>
                        <FormattedMessage
                            id={visibilityMessageId}
                            defaultMessage=""
                                />
                    </span>
                </div>    

                {/* Subtitulo - Temporada */}
                <div className='input-column-container'>
                    <label className="" style={{color: '#4C4C4C', fontSize: 18, fontWeight: 'bold'}}> 
                        <FormattedMessage id="biblioteca.subtitle.temporada" defaultMessage="Temporada" />
                    </label>
                   
                    <span className="" style={{color: infoMsgTextColor}}>
                        <i className={infoIcons[INFO_ICONS.INFO_CIRCLE]}></i>
                        <i className={infoIcons[INFO_ICONS.EXCLAMATION_TRIANGLE]}></i>
                        <i className={infoIcons[INFO_ICONS.EXCLAMATION_CIRCLE]}></i>
                        <i className={infoIcons[INFO_ICONS.CALENDAR]}></i>
                        <FormattedMessage
                            id={infoMessageId}
                            defaultMessage=""
                            values={{ dayNumberDifference: dayNumberDifference}}
                                />
                    </span>
                </div>

                {/* Sección - Temporada */}
                <div className='ui form input-group-container'>
                    {/* Fecha inicio */}
                    <div className='input-group'>
                        <label className='label baseline required' htmlFor={FIELDS.INPUT_FECHA_INICIO}>
                            <i className="flag icon green" style={{color: blue}}/>
                            <FormattedMessage 
                                id="biblioteca.label.fechaInicio" 
                                defaultMessage="Fecha inicio:"/>
                        </label>
                        <input 
                            type="date" 
                            id={FIELDS.INPUT_FECHA_INICIO}
                            name={FIELDS.INPUT_FECHA_INICIO}
                            value={libraryConfig[FIELDS.INPUT_FECHA_INICIO]}
                            className={(libraryConfigValidation[FIELDS.INPUT_FECHA_INICIO])?'':'required-field'}
                            onChange={handleLibraryConfigInputChange}
                            style={{width:150}}
                            min="2024-01-01"
                            max={maxRangeStartDate}
                        />                                
                    </div>
                    {/* Fecha fin */}
                    <div className='input-group'>
                        <label className='label baseline' htmlFor={FIELDS.INPUT_FECHA_FIN}>
                            <i className="flag checkered icon" style={{color: blue}}/>
                            <FormattedMessage 
                                id="biblioteca.label.fechaFin" 
                                defaultMessage="Fecha fin:"/>
                        </label>
                        <input 
                            type="date" 
                            id={FIELDS.INPUT_FECHA_FIN}
                            name={FIELDS.INPUT_FECHA_FIN}
                            value={libraryConfig[FIELDS.INPUT_FECHA_FIN]}
                            onChange={handleLibraryConfigInputChange}
                            style={{width:150}}
                            min={minRangeEndDate}
                        />                        
                    </div>                    
                </div> 

                {/* Subtitulo - Mensaje */}
                <label className="sub-title title-flx"> 
                    <FormattedMessage id="biblioteca.subtitle.mensaje" defaultMessage="Mensaje" />
                </label>

                {/* Sección - Mensajes español y euskera */}
                <div className='ui form input-column-container'>
                    {/* Español */}
                    <div className='input-column'>
                        <label htmlFor='titulo' className='label required'>
                            <img 
                                src={require('../../images/es-flag.png')} 
                                alt='mySvgImage' 
                                width={20}
                                style={{marginRight: 5, marginLeft: 3, marginBottom: 4}}
                            />
                            <FormattedMessage 
                                id="biblioteca.label.spanish" 
                                defaultMessage="Español:" />
                        </label>
                        <textarea 
                            id={FIELDS.INPUT_MENSAJE_CASTELLANO}
                            name={FIELDS.INPUT_MENSAJE_CASTELLANO}
                            value={libraryConfig[FIELDS.INPUT_MENSAJE_CASTELLANO]}
                            className={(libraryConfigValidation[FIELDS.INPUT_MENSAJE_CASTELLANO])?'':'required-field'}
                            onChange={handleLibraryConfigInputChange} 
                            style={{resize: 'none'}}
                            rows="4" 
                            cols="50" 
                        />
                        <span className={(libraryConfig[FIELDS.INPUT_MENSAJE_CASTELLANO].length < MAX_LENGTH[FIELDS.INPUT_MENSAJE_CASTELLANO]) ? 'input-length-counter' : 'input-length-counter-red'}>
                            {libraryConfig[FIELDS.INPUT_MENSAJE_CASTELLANO].length}/{MAX_LENGTH[FIELDS.INPUT_MENSAJE_CASTELLANO]}
                        </span>
                    </div>
                    {/* Euskera */}
                    <div className='input-column'>
                        <label htmlFor='descripcion' className='label required'>
                            <img 
                                src={require('../../images/eu-flag.png')} 
                                alt='mySvgImage' 
                                width={20}
                                style={{marginRight: 5, marginLeft: 3, marginBottom: 4}}
                            />
                            <FormattedMessage 
                                id="biblioteca.label.basque" 
                                defaultMessage="Euskera:" />                        
                        </label>
                        <textarea 
                            id={FIELDS.INPUT_MENSAJE_EUSKERA}
                            name={FIELDS.INPUT_MENSAJE_EUSKERA}
                            value={libraryConfig[FIELDS.INPUT_MENSAJE_EUSKERA]}
                            className={(libraryConfigValidation[FIELDS.INPUT_MENSAJE_EUSKERA])?'':'required-field'}
                            onChange={handleLibraryConfigInputChange} 
                            style={{resize: 'none'}}
                            rows="4" 
                            cols="50" 
                        />
                        <span className={(libraryConfig[FIELDS.INPUT_MENSAJE_EUSKERA].length < MAX_LENGTH[FIELDS.INPUT_MENSAJE_EUSKERA]) ? 'input-length-counter' : 'input-length-counter-red'}>
                            {libraryConfig[FIELDS.INPUT_MENSAJE_EUSKERA].length}/{MAX_LENGTH[FIELDS.INPUT_MENSAJE_EUSKERA]}
                        </span>
                    </div>
                </div>   

                {/* Botón guardar */}
                <div style={{ marginTop:5, marginLeft:14, marginBottom: 15}}>
                    <button className="ui red button" onClick={() => onClickGuardar()}>
                        <FormattedMessage id="biblioteca.button.guardar" defaultMessage="Guardar" />
                    </button>
                </div>

            </div>
        </>
    );
};

export default Library;