import React, {useState} from 'react'; 
import { useParams } from 'react-router-dom';
import { requestGetPoolById } from '../../services/PoolsService';
import Moment from 'moment';
import Popup from 'reactjs-popup';
import {FormattedMessage} from 'react-intl';
import DatePicker,{registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";


const ViewSwimmingPools = () => {

    const {id} = useParams();

    const [viewSwimmingPools, setViewSwimmingPools] = useState('') 
    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    
    React.useEffect(() => {
     requestGetPoolById(setViewSwimmingPools, id);
    },[id])


    function setLang(lang){
        if(lang === "ES"){
            setHideEsComponent(false)
            setHideEuComponent(true)
        }else{
            setHideEsComponent(true)
            setHideEuComponent(false)
        }

    }

    let history = useHistory();

    function handlePushNavigation(){
        history.push("/swimming-pools");
    }

    let emptySeasson;

    if(null != viewSwimmingPools.poolsSeasson && viewSwimmingPools.poolsSeasson.length > 0){
        emptySeasson = viewSwimmingPools.poolsSeasson.map(data => (
            <div className="ui three column divided grid" style={{marginLeft:0}}> 
                <div className="four wide column" >
                    <div className="ui segment">
                        <label><b><FormattedMessage id="piscinas.anadir.temporada.inicio" defaultMessage="INICIO"/></b></label>
                        <DatePicker disabled wrapperClassName='date-picker-viewSwimmingPool' dateFormat="dd/MM/yyyy" value={Moment(data.init).format("DD/MM/YYYY")}/>
                    </div>
                </div>
                <div  className="four wide column">
                    <div className="ui segment">
                        <label><b><FormattedMessage id="piscinas.detalle.fin" defaultMessage="FIN"/></b></label>
                        <DatePicker disabled wrapperClassName='date-picker-viewSwimmingPool' dateFormat="dd/MM/yyyy" value={Moment(data.finale).format("DD/MM/YYYY")} />
                    </div>
                </div>
                <div  className="eight wide column">
                    <div className="ui segment" style={{float:'left'}}>
                        {data.poolsSchedule.map(dataS => (
                            <div className="one wide column"> 
                                <table>
                                    <tbody>
                                        <tr style={{border:510, borderStyle:'solid'}}> 
                                            <td>
                                                <label><b><FormattedMessage id="piscinas.detalle.desde" defaultMessage="DESDE"/></b></label>
                                                <DatePicker disabled wrapperClassName='date-picker-viewSwimmingPool' showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="hh:mm"  value={(dataS.open)}/>
                                            </td>
                                            <td>
                                                <label><b><FormattedMessage id="piscinas.detalle.hasta" defaultMessage="HASTA"/></b></label>
                                                <DatePicker disabled wrapperClassName='date-picker-viewSwimmingPool' showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="hh:mm" value={(dataS.close)}/> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ));
    }else{
        emptySeasson = <div className="ui grid"> <div className="sixteen wide column" style={{paddingLeft:30}}><label><b><FormattedMessage id="piscinas.detalle.noHayFranjasDisponibles" defaultMessage="No hay franjas disponibles"/></b></label></div></div>;
    }

    return (
        <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                <i className="home red icon"></i>
                <a className="section" style={{color: "red"}} onClick={handlePushNavigation}><FormattedMessage id="piscinas.detalle.piscinas" defaultMessage="Piscinas" /></a>
                <i className="right large angle icon divider"></i>
                <i className="section" >{viewSwimmingPools.poolNameEs}</i>
            </div>
            <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>  
                <i className="home red icon"></i>
                <a className="section" style={{color: "red"}} onClick={handlePushNavigation}><FormattedMessage id="piscinas.detalle.piscinas" defaultMessage="Piscinas" /></a>
                <i className="right large angle icon divider"></i>
                <i className="section" >{viewSwimmingPools.poolNameEu}</i>
            </div>
        </div>
        <div className="mainDiv" style={{height:'auto', paddingBottom:20}}>

            <div className='ui grid' >
                <div className='six wide column'>
                    <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                        <div className="middle aligned content">
                            <div>
                                <h1 className='title'>{viewSwimmingPools.poolNameEs}</h1>
                            </div>
                        </div>
                    
                    </div>
                    <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`} >
                        <div className="middle aligned content">
                            <div>
                                <h1 className='title'>{viewSwimmingPools.poolNameEu}</h1>
                            </div>
                        </div>
                    </div>                
                </div>
                <div className="red-box" style={{paddingTop:20, height:50, marginRight:8, width:800}} >
                    <select className="ui red-Select select" style={{marginLeft:600}} name="selectType" onChange={event => setLang(event.target.value)}>
                        <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                            {(message) => <option value="ES">{message}</option>}
                        </FormattedMessage>
                            <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                            {(message) => <option value="EU">{message}</option>}
                        </FormattedMessage>
                    </select>
                </div> 
            </div>


            <div className='ui grid' >
                <div className="ui vertically divided grid">
                    <Popup trigger={<button className='search-icon' style={{marginLeft:400}} ><i style={{paddingLeft:12}} className="ui white search icon"></i></button>} >
                        <img src={viewSwimmingPools.urlImg} alt="Imagen" />
                    </Popup>
                    <img style={{maxWidth:450, maxHeight:450,paddingLeft:16, float:"left"}}src={viewSwimmingPools.urlImg} alt="Imagen" />
                </div>
                <div className='ten wide column'>
                    <label><b><FormattedMessage id="piscinas.detalle.municipio" defaultMessage="MUNICIPIO" /></b></label>
                    <div>{viewSwimmingPools.cityName}</div>
                    <div className='lineaNew'></div>
                    <br></br>
                    <div className="ui grid">
                        <div className='left floated six wide column'>
                            <label><b><FormattedMessage id="piscinas.detalle.codigoMunicipio" defaultMessage="CÓDIGO DE MUNICIPIO" /></b></label>
                            <div>
                                <label>{viewSwimmingPools.cityCode}</label>
                            </div>
                        </div>

                        <div className='right floated eight wide column'>
                            <label><b><FormattedMessage id="piscinas.detalle.idInstalación" defaultMessage="ID INSTALACIÓN" /></b></label>
                            <div>
                                <label>{viewSwimmingPools.facilityCode}</label>
                            </div>
                        </div>
                    </div>
                    <div className="ui grid">
                        <div className='left floated eight wide column'>
                            <label ><b><FormattedMessage id="piscinas.detalle.urlWEB" defaultMessage="URL WEB" /></b> <a style={{borderSpacing:300,borderRadius:5,borderStyle:'solid'}} href={viewSwimmingPools.webUrl}><i className="external alternate icon"></i></a></label>
                            <div>
                                <label><b>{viewSwimmingPools.webUrl}</b></label>
                            </div>
                        </div>
                        <div className='right floated eight wide column'>
                            <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}><b><FormattedMessage id="piscinas.detalle.nombreWebES" defaultMessage="NOMBRE WEB CASTELLANO"/></b></label>
                            <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}><b><FormattedMessage id="piscinas.detalle.nombreWebEU" defaultMessage="NOMBRE WEB EUSKERA"/></b></label>
                            <div>
                                <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>{viewSwimmingPools.webNameEu}</label>
                                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>{viewSwimmingPools.webNameEs}</label>
                            </div>
                        </div>
                        <div className='lineaNew'></div>
                    </div>

                    <div className='ui divided items'>
                        <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                            <div className="middle aligned content">
                                <label><b><FormattedMessage id="piscinas.detalle.descripcionES" defaultMessage="DESCRIPCIÓN CASTELLANO" /></b></label>
                                <div>  
                                    <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`} dangerouslySetInnerHTML={{__html: viewSwimmingPools.descriptionEs}}></label>
                                </div>
                                <br/>
                            </div>
                        </div>
                        <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                            <div className= "four wide column">
                            <label><b><FormattedMessage id="piscinas.detalle.descripcionEU" defaultMessage="DESCRIPCIÓN EUSKERA" /></b></label>
                                <div>
                                    <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`} dangerouslySetInnerHTML={{__html: viewSwimmingPools.descriptionEu}}></label>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='ui grid' style={{marginTop:40}} >
                <div className= "four wide column" style={{paddingLeft:30}}>
                    <label><b><FormattedMessage id="piscinas.detalle.estado" defaultMessage="ESTADO"/></b></label>
                    <div>
                        <label>{viewSwimmingPools.state ? <FormattedMessage id="piscinas.detalle.abierta" defaultMessage="Abierta"/> : <FormattedMessage id="piscinas.detalle.cerrada" defaultMessage="Cerrada"/>}</label>
                    </div>
                </div>
                <div className="four wide column">
                    <label><b><FormattedMessage id="piscinas.detalle.limiteAforo" defaultMessage="LÍMITE AFORO"/></b></label>
                    <div>
                        <label>{viewSwimmingPools.maxOccupation}</label>
                    </div>
                </div>
                <div className="four wide column">
                    <label><b><FormattedMessage id="piscinas.detalle.ocupacionActual" defaultMessage="OCUPACIÓN ACTUAL"/></b></label>
                    <div>
                        <label>{viewSwimmingPools.actualOccupation}</label>
                    </div>
                </div>
                <div className="four wide column">
                    <label><b><FormattedMessage id="piscinas.detalle.fechaActualizacion" defaultMessage="FECHA ACTUALIZACIÓN"/></b></label>
                    <div>
                        <label>{Moment(viewSwimmingPools.updateDate).format("DD/MM/YYYY")}</label>
                    </div>

                </div>
                <div className='lineaNew'></div>
            </div>
           

            <br/><br/>
            <div><label style={{fontSize:18,paddingLeft:15}}><b><FormattedMessage id="piscinas.detalle.temporada" defaultMessage="TEMPORADA"/></b></label>  </div>
            <br/>
            
            {emptySeasson}

        </div>
        </>
    )     
}
         

  
 export default ViewSwimmingPools;