const data = [{
  "event_timestamp": "2023-09-26 09:22:46.893141 UTC",
  "message_id": "0:1695720166899946%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:48.231651 UTC",
  "message_id": "1695730968237359",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892178 UTC",
  "message_id": "0:1695720166900045%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.549540 UTC",
  "message_id": "",
  "message_type": "TOPIC",
  "sdk_platform": "UNKNOWN_OS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:48.191777 UTC",
  "message_id": "1695730968243238",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:47.738483 UTC",
  "message_id": "",
  "message_type": "TOPIC",
  "sdk_platform": "UNKNOWN_OS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892223 UTC",
  "message_id": "1695720166896129",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892361 UTC",
  "message_id": "1695720166896369",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892878 UTC",
  "message_id": "0:1695720166901090%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892928 UTC",
  "message_id": "0:1695720166901382%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892126 UTC",
  "message_id": "1695720166896740",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892712 UTC",
  "message_id": "1695720166897197",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892679 UTC",
  "message_id": "1695720166897669",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892516 UTC",
  "message_id": "1695720166897672",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892790 UTC",
  "message_id": "1695720166897838",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.893306 UTC",
  "message_id": "1695720166898406",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.895169 UTC",
  "message_id": "1695720166898517",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892807 UTC",
  "message_id": "1695720166898784",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892897 UTC",
  "message_id": "1695720166899464",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.895447 UTC",
  "message_id": "1695720166900271",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.895556 UTC",
  "message_id": "1695720166901050",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.896542 UTC",
  "message_id": "1695720166901129",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.896154 UTC",
  "message_id": "1695720166902569",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.908382 UTC",
  "message_id": "1695720166912695",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.893055 UTC",
  "message_id": "1695720166912848",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.908486 UTC",
  "message_id": "1695720166912882",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.907795 UTC",
  "message_id": "1695720166913221",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.907437 UTC",
  "message_id": "1695720166913283",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.908976 UTC",
  "message_id": "1695720166915347",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.911791 UTC",
  "message_id": "1695720166915383",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.907518 UTC",
  "message_id": "1695720166915855",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.910447 UTC",
  "message_id": "1695720166916464",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.911893 UTC",
  "message_id": "1695720166918183",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.911388 UTC",
  "message_id": "1695720166918981",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.908477 UTC",
  "message_id": "1695720166919602",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.907921 UTC",
  "message_id": "1695720166926513",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.908032 UTC",
  "message_id": "1695720166926689",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.923856 UTC",
  "message_id": "1695720166927644",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.923623 UTC",
  "message_id": "1695720166927742",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.908533 UTC",
  "message_id": "1695720166928708",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.924754 UTC",
  "message_id": "1695720166929375",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.927188 UTC",
  "message_id": "1695720166930701",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.927513 UTC",
  "message_id": "1695720166931050",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.928275 UTC",
  "message_id": "1695720166932554",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.928256 UTC",
  "message_id": "1695720166933878",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.907580 UTC",
  "message_id": "1695720166934250",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.931636 UTC",
  "message_id": "1695720166935581",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.907194 UTC",
  "message_id": "1695720166938958",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.911494 UTC",
  "message_id": "1695720166939055",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.896115 UTC",
  "message_id": "1695720166947575",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.908365 UTC",
  "message_id": "1695720166949206",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892370 UTC",
  "message_id": "1695720166953335",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.895411 UTC",
  "message_id": "1695720167614713",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.366040 UTC",
  "message_id": "1695720168369738",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.366043 UTC",
  "message_id": "1695720168371173",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.365878 UTC",
  "message_id": "1695720168371937",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.368635 UTC",
  "message_id": "1695720168373508",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.369239 UTC",
  "message_id": "1695720168374679",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.368653 UTC",
  "message_id": "1695720168375370",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.367058 UTC",
  "message_id": "1695720168377457",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.380279 UTC",
  "message_id": "1695720168384182",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.380648 UTC",
  "message_id": "1695720168384333",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.380843 UTC",
  "message_id": "1695720168385229",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381039 UTC",
  "message_id": "1695720168385415",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.380652 UTC",
  "message_id": "1695720168385929",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381897 UTC",
  "message_id": "1695720168386761",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381746 UTC",
  "message_id": "1695720168386806",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.382169 UTC",
  "message_id": "1695720168387017",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381986 UTC",
  "message_id": "1695720168387064",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.382506 UTC",
  "message_id": "1695720168387876",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381729 UTC",
  "message_id": "1695720168388080",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381777 UTC",
  "message_id": "1695720168389260",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381225 UTC",
  "message_id": "1695720168396241",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381702 UTC",
  "message_id": "1695720168397157",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.380892 UTC",
  "message_id": "1695720168400956",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.384391 UTC",
  "message_id": "1695720168401940",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.396733 UTC",
  "message_id": "1695720168401968",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.396579 UTC",
  "message_id": "1695720168402045",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.396740 UTC",
  "message_id": "1695720168402055",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.398040 UTC",
  "message_id": "1695720168402223",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.399326 UTC",
  "message_id": "1695720168402930",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.400027 UTC",
  "message_id": "1695720168402979",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.385563 UTC",
  "message_id": "1695720168402986",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.399003 UTC",
  "message_id": "1695720168403415",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.399279 UTC",
  "message_id": "1695720168404161",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.399534 UTC",
  "message_id": "1695720168405022",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.401285 UTC",
  "message_id": "1695720168405044",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.399283 UTC",
  "message_id": "1695720168405520",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381513 UTC",
  "message_id": "1695720168406723",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.401830 UTC",
  "message_id": "1695720168407086",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.403990 UTC",
  "message_id": "1695720168410334",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.401868 UTC",
  "message_id": "1695720168410377",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.400124 UTC",
  "message_id": "1695720168412050",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381672 UTC",
  "message_id": "1695720168416772",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381627 UTC",
  "message_id": "1695720168420407",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:48.381923 UTC",
  "message_id": "1695720168421811",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-10",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.718458 UTC",
  "message_id": "1695720169721591",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.717908 UTC",
  "message_id": "1695720169721754",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.717991 UTC",
  "message_id": "1695720169722730",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.718115 UTC",
  "message_id": "1695720169722905",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.719229 UTC",
  "message_id": "1695720169723885",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.718509 UTC",
  "message_id": "1695720169725167",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.721171 UTC",
  "message_id": "1695720169725745",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.721162 UTC",
  "message_id": "1695720169725953",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.721199 UTC",
  "message_id": "1695720169726917",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.733148 UTC",
  "message_id": "1695720169737771",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.738159 UTC",
  "message_id": "1695720169741661",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.738204 UTC",
  "message_id": "1695720169741956",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.747570 UTC",
  "message_id": "1695720169751430",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.747737 UTC",
  "message_id": "1695720169751451",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.747769 UTC",
  "message_id": "1695720169751621",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.747927 UTC",
  "message_id": "1695720169751814",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.753496 UTC",
  "message_id": "1695720169757220",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.753185 UTC",
  "message_id": "1695720169758267",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.754797 UTC",
  "message_id": "1695720169759111",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.738254 UTC",
  "message_id": "1695720169762773",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.762614 UTC",
  "message_id": "1695720169767358",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.766042 UTC",
  "message_id": "1695720169770711",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.753283 UTC",
  "message_id": "1695720169772301",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:49.774520 UTC",
  "message_id": "1695720169793791",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-12",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.691791 UTC",
  "message_id": "1695720170696834",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.692800 UTC",
  "message_id": "1695720170697153",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.692106 UTC",
  "message_id": "1695720170697290",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.695307 UTC",
  "message_id": "1695720170698824",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.694545 UTC",
  "message_id": "1695720170699198",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.695932 UTC",
  "message_id": "1695720170700267",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.696008 UTC",
  "message_id": "1695720170700809",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.696006 UTC",
  "message_id": "1695720170701274",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.692687 UTC",
  "message_id": "1695720170703440",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.692824 UTC",
  "message_id": "1695720170704928",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.707216 UTC",
  "message_id": "1695720170711139",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.707720 UTC",
  "message_id": "1695720170712914",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.710146 UTC",
  "message_id": "1695720170713972",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.711675 UTC",
  "message_id": "1695720170714623",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.708791 UTC",
  "message_id": "1695720170715048",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.712457 UTC",
  "message_id": "1695720170715243",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.711003 UTC",
  "message_id": "1695720170715514",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.712281 UTC",
  "message_id": "1695720170716507",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.709112 UTC",
  "message_id": "1695720170721843",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.707919 UTC",
  "message_id": "1695720170723699",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.708691 UTC",
  "message_id": "1695720170724873",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.707812 UTC",
  "message_id": "1695720170725973",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.723112 UTC",
  "message_id": "1695720170726908",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.722964 UTC",
  "message_id": "1695720170727662",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.723358 UTC",
  "message_id": "1695720170728919",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.710427 UTC",
  "message_id": "1695720170729229",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.726500 UTC",
  "message_id": "1695720170731315",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.726629 UTC",
  "message_id": "1695720170731727",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.726568 UTC",
  "message_id": "1695720170732479",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.723276 UTC",
  "message_id": "1695720170732482",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.726759 UTC",
  "message_id": "1695720170732898",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.730387 UTC",
  "message_id": "1695720170733430",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.731313 UTC",
  "message_id": "1695720170735774",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.731658 UTC",
  "message_id": "1695720170737183",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.730745 UTC",
  "message_id": "1695720170749198",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.745616 UTC",
  "message_id": "1695720170751402",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.748934 UTC",
  "message_id": "1695720170754237",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.748955 UTC",
  "message_id": "1695720170755837",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.725927 UTC",
  "message_id": "1695720170780743",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:50.707576 UTC",
  "message_id": "1695720171642455",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-17",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377472 UTC",
  "message_id": "1695720172380284",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377460 UTC",
  "message_id": "1695720172380752",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377063 UTC",
  "message_id": "1695720172381577",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377551 UTC",
  "message_id": "1695720172382403",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377527 UTC",
  "message_id": "1695720172383151",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377899 UTC",
  "message_id": "1695720172383174",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377701 UTC",
  "message_id": "1695720172383684",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380021 UTC",
  "message_id": "1695720172383701",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380363 UTC",
  "message_id": "1695720172384175",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.381107 UTC",
  "message_id": "1695720172384333",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380715 UTC",
  "message_id": "1695720172384686",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.379699 UTC",
  "message_id": "1695720172385004",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380709 UTC",
  "message_id": "1695720172385062",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380759 UTC",
  "message_id": "1695720172385628",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380568 UTC",
  "message_id": "1695720172385966",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380117 UTC",
  "message_id": "1695720172386122",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.381122 UTC",
  "message_id": "1695720172386195",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.380697 UTC",
  "message_id": "1695720172386704",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.381095 UTC",
  "message_id": "1695720172386803",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.381014 UTC",
  "message_id": "1695720172389197",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.381093 UTC",
  "message_id": "1695720172395024",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.391979 UTC",
  "message_id": "1695720172396175",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.391737 UTC",
  "message_id": "1695720172396450",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392450 UTC",
  "message_id": "1695720172397774",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.377746 UTC",
  "message_id": "1695720172398110",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392057 UTC",
  "message_id": "1695720172398338",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392853 UTC",
  "message_id": "1695720172398407",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392578 UTC",
  "message_id": "1695720172398556",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392853 UTC",
  "message_id": "1695720172398594",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.394972 UTC",
  "message_id": "1695720172399513",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392360 UTC",
  "message_id": "1695720172399753",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.396146 UTC",
  "message_id": "1695720172400338",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.391399 UTC",
  "message_id": "1695720172407055",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392224 UTC",
  "message_id": "1695720172409534",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.407065 UTC",
  "message_id": "1695720172412079",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.407314 UTC",
  "message_id": "1695720172412091",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.407297 UTC",
  "message_id": "1695720172412575",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.408389 UTC",
  "message_id": "1695720172412883",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.407673 UTC",
  "message_id": "1695720172413699",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.411959 UTC",
  "message_id": "1695720172417999",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.393947 UTC",
  "message_id": "1695720172418686",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.393847 UTC",
  "message_id": "1695720172420411",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.397265 UTC",
  "message_id": "1695720172429470",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.430896 UTC",
  "message_id": "1695720172437547",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.447556 UTC",
  "message_id": "1695720172451394",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.446573 UTC",
  "message_id": "1695720172452435",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.447739 UTC",
  "message_id": "1695720172452533",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.442893 UTC",
  "message_id": "1695720172454155",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.443287 UTC",
  "message_id": "1695720172460295",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.381094 UTC",
  "message_id": "1695720172763053",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.391914 UTC",
  "message_id": "1695720172763482",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.394539 UTC",
  "message_id": "1695720172797159",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:52.392831 UTC",
  "message_id": "1695720173363867",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-18",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.128104 UTC",
  "message_id": "1695720174131961",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.128976 UTC",
  "message_id": "1695720174132807",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.128478 UTC",
  "message_id": "1695720174133733",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.128646 UTC",
  "message_id": "1695720174134773",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.131817 UTC",
  "message_id": "1695720174135114",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.132056 UTC",
  "message_id": "1695720174135721",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.131564 UTC",
  "message_id": "1695720174136171",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.131859 UTC",
  "message_id": "1695720174138101",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.143711 UTC",
  "message_id": "1695720174148898",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.146246 UTC",
  "message_id": "1695720174149036",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.143425 UTC",
  "message_id": "1695720174149282",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.146253 UTC",
  "message_id": "1695720174149997",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.147565 UTC",
  "message_id": "1695720174150175",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.148535 UTC",
  "message_id": "1695720174151786",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.147077 UTC",
  "message_id": "1695720174152772",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.147979 UTC",
  "message_id": "1695720174153013",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.158888 UTC",
  "message_id": "1695720174161669",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.158634 UTC",
  "message_id": "1695720174162252",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.158858 UTC",
  "message_id": "1695720174162531",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.159820 UTC",
  "message_id": "1695720174163652",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.147425 UTC",
  "message_id": "1695720174163788",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.163305 UTC",
  "message_id": "1695720174167163",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.163295 UTC",
  "message_id": "1695720174167184",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.148251 UTC",
  "message_id": "1695720174168676",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.163581 UTC",
  "message_id": "1695720174168863",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.163783 UTC",
  "message_id": "1695720174169377",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.147105 UTC",
  "message_id": "1695720174170651",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.143593 UTC",
  "message_id": "1695720174171294",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.162629 UTC",
  "message_id": "1695720174171559",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.166142 UTC",
  "message_id": "1695720174171833",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.197921 UTC",
  "message_id": "1695720174201863",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.159183 UTC",
  "message_id": "1695720174213871",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.213050 UTC",
  "message_id": "1695720174218113",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.212654 UTC",
  "message_id": "1695720174218329",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:54.147955 UTC",
  "message_id": "1695720174288978",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-19",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.211420 UTC",
  "message_id": "1695720175215079",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.212156 UTC",
  "message_id": "1695720175215645",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.212312 UTC",
  "message_id": "1695720175215674",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.211435 UTC",
  "message_id": "1695720175215830",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.214885 UTC",
  "message_id": "1695720175217791",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.214883 UTC",
  "message_id": "1695720175218989",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.214949 UTC",
  "message_id": "1695720175219838",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.215454 UTC",
  "message_id": "1695720175220507",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.215193 UTC",
  "message_id": "1695720175220612",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.212585 UTC",
  "message_id": "1695720175222215",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.227450 UTC",
  "message_id": "1695720175230216",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.227264 UTC",
  "message_id": "1695720175231859",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.214769 UTC",
  "message_id": "1695720175233180",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.230454 UTC",
  "message_id": "1695720175234461",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.231535 UTC",
  "message_id": "1695720175234990",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.215738 UTC",
  "message_id": "1695720175236121",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.234312 UTC",
  "message_id": "1695720175238228",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.244189 UTC",
  "message_id": "1695720175247460",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.247710 UTC",
  "message_id": "1695720175250992",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.246588 UTC",
  "message_id": "1695720175251069",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.248097 UTC",
  "message_id": "1695720175252685",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.246877 UTC",
  "message_id": "1695720175252900",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.249746 UTC",
  "message_id": "1695720175253799",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.249942 UTC",
  "message_id": "1695720175254336",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.247804 UTC",
  "message_id": "1695720175254403",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.247777 UTC",
  "message_id": "1695720175255350",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.250900 UTC",
  "message_id": "1695720175255671",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:55.276674 UTC",
  "message_id": "1695720175287473",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-5",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:56.081588 UTC",
  "message_id": "1695720176090247",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:56.116307 UTC",
  "message_id": "1695720176118896",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:56.101603 UTC",
  "message_id": "1695720176119595",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:56.113509 UTC",
  "message_id": "1695720176120199",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:56.113042 UTC",
  "message_id": "1695720176134341",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:56.131209 UTC",
  "message_id": "1695720176135249",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:56.128205 UTC",
  "message_id": "1695720176145134",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.085071 UTC",
  "message_id": "1695721967088125",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.085384 UTC",
  "message_id": "1695721967090073",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.085581 UTC",
  "message_id": "1695721967090259",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088415 UTC",
  "message_id": "1695721967091623",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088228 UTC",
  "message_id": "1695721967091984",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088232 UTC",
  "message_id": "1695721967092092",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.085496 UTC",
  "message_id": "1695721967092670",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088838 UTC",
  "message_id": "1695721967092728",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088025 UTC",
  "message_id": "1695721967092829",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088293 UTC",
  "message_id": "1695721967092845",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088280 UTC",
  "message_id": "1695721967093121",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088296 UTC",
  "message_id": "1695721967093308",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088163 UTC",
  "message_id": "1695721967093406",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088519 UTC",
  "message_id": "1695721967093613",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088097 UTC",
  "message_id": "1695721967094258",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088539 UTC",
  "message_id": "1695721967094275",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088558 UTC",
  "message_id": "1695721967094864",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.099869 UTC",
  "message_id": "1695721967103878",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.088321 UTC",
  "message_id": "1695721967103948",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.100696 UTC",
  "message_id": "1695721967105282",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.100776 UTC",
  "message_id": "1695721967105408",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.101020 UTC",
  "message_id": "1695721967105769",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.101322 UTC",
  "message_id": "1695721967106003",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.100392 UTC",
  "message_id": "1695721967107380",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.104874 UTC",
  "message_id": "1695721967108592",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.105488 UTC",
  "message_id": "1695721967109745",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.103967 UTC",
  "message_id": "1695721967110616",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.104635 UTC",
  "message_id": "1695721967110832",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.105106 UTC",
  "message_id": "1695721967111319",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.101880 UTC",
  "message_id": "1695721967114482",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.105476 UTC",
  "message_id": "1695721967114680",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.104953 UTC",
  "message_id": "1695721967115733",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.100527 UTC",
  "message_id": "1695721967116660",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.099872 UTC",
  "message_id": "1695721967117740",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.116857 UTC",
  "message_id": "1695721967120162",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.103805 UTC",
  "message_id": "1695721967120723",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.116693 UTC",
  "message_id": "1695721967121440",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.116701 UTC",
  "message_id": "1695721967121467",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.116487 UTC",
  "message_id": "1695721967121488",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.101721 UTC",
  "message_id": "1695721967121863",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.116941 UTC",
  "message_id": "1695721967122277",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.116997 UTC",
  "message_id": "1695721967122484",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.117032 UTC",
  "message_id": "1695721967122642",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.103336 UTC",
  "message_id": "1695721967122792",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.117169 UTC",
  "message_id": "1695721967122881",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.115688 UTC",
  "message_id": "1695721967123323",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.085202 UTC",
  "message_id": "1695721967123453",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.120113 UTC",
  "message_id": "1695721967125189",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.120654 UTC",
  "message_id": "1695721967126347",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.124111 UTC",
  "message_id": "1695721967130853",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.103527 UTC",
  "message_id": "1695721967131321",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.116231 UTC",
  "message_id": "1695721967132374",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.104754 UTC",
  "message_id": "1695721967137969",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.139184 UTC",
  "message_id": "1695721967146293",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.103694 UTC",
  "message_id": "1695721967146642",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.151149 UTC",
  "message_id": "1695721967157718",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.139184 UTC",
  "message_id": "1695721967173299",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.103471 UTC",
  "message_id": "1695721967181985",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.120623 UTC",
  "message_id": "1695721967453482",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:47.104605 UTC",
  "message_id": "1695721967519319",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-4",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.405428 UTC",
  "message_id": "1695721968409304",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.405047 UTC",
  "message_id": "1695721968409308",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.405105 UTC",
  "message_id": "1695721968410286",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.406095 UTC",
  "message_id": "1695721968411075",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.405620 UTC",
  "message_id": "1695721968411727",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.408568 UTC",
  "message_id": "1695721968415054",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.410316 UTC",
  "message_id": "1695721968415179",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.410440 UTC",
  "message_id": "1695721968416641",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.413586 UTC",
  "message_id": "1695721968417170",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.413330 UTC",
  "message_id": "1695721968418010",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.413550 UTC",
  "message_id": "1695721968419312",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.420220 UTC",
  "message_id": "1695721968425437",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.424894 UTC",
  "message_id": "1695721968427615",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.425308 UTC",
  "message_id": "1695721968428501",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.425416 UTC",
  "message_id": "1695721968429699",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.408596 UTC",
  "message_id": "1695721968430139",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.405241 UTC",
  "message_id": "1695721968432100",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.430524 UTC",
  "message_id": "1695721968435002",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.425262 UTC",
  "message_id": "1695721968438737",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.420137 UTC",
  "message_id": "1695721968442208",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:52:48.420474 UTC",
  "message_id": "1695721968457033",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-7",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.006438 UTC",
  "message_id": "1695722867011062",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.006810 UTC",
  "message_id": "1695722867012602",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.009080 UTC",
  "message_id": "1695722867013315",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.010000 UTC",
  "message_id": "1695722867013613",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.008867 UTC",
  "message_id": "1695722867013926",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.009207 UTC",
  "message_id": "1695722867014296",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.010473 UTC",
  "message_id": "1695722867014479",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.009177 UTC",
  "message_id": "1695722867014583",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.009279 UTC",
  "message_id": "1695722867014852",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.008825 UTC",
  "message_id": "1695722867015002",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.010512 UTC",
  "message_id": "1695722867015106",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.021281 UTC",
  "message_id": "1695722867024970",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.020698 UTC",
  "message_id": "1695722867026466",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.009085 UTC",
  "message_id": "1695722867027599",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.024310 UTC",
  "message_id": "1695722867028444",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.024018 UTC",
  "message_id": "1695722867028505",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.024096 UTC",
  "message_id": "1695722867028521",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.025729 UTC",
  "message_id": "1695722867029758",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.025400 UTC",
  "message_id": "1695722867030086",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.024887 UTC",
  "message_id": "1695722867030257",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.024352 UTC",
  "message_id": "1695722867030477",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.025628 UTC",
  "message_id": "1695722867031101",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.026797 UTC",
  "message_id": "1695722867032525",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.024467 UTC",
  "message_id": "1695722867037974",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.026696 UTC",
  "message_id": "1695722867039888",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.039543 UTC",
  "message_id": "1695722867043222",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.039497 UTC",
  "message_id": "1695722867043311",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.039455 UTC",
  "message_id": "1695722867044007",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.025774 UTC",
  "message_id": "1695722867045282",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.040771 UTC",
  "message_id": "1695722867045642",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.041119 UTC",
  "message_id": "1695722867046213",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.042811 UTC",
  "message_id": "1695722867048524",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.025536 UTC",
  "message_id": "1695722867050180",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.042224 UTC",
  "message_id": "1695722867052022",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.046739 UTC",
  "message_id": "1695722867052676",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.039554 UTC",
  "message_id": "1695722867060939",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.046084 UTC",
  "message_id": "1695722867064183",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.095768 UTC",
  "message_id": "1695722867099401",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.027074 UTC",
  "message_id": "1695722867103292",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 10:07:47.039227 UTC",
  "message_id": "1695722867427297",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-3",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.934960 UTC",
  "message_id": "1695727367939072",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.950162 UTC",
  "message_id": "1695727367956115",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.951709 UTC",
  "message_id": "1695727367957492",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.953475 UTC",
  "message_id": "1695727367958611",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.964787 UTC",
  "message_id": "1695727367973038",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.968407 UTC",
  "message_id": "1695727367973607",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.981028 UTC",
  "message_id": "1695727367994493",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.980835 UTC",
  "message_id": "1695727368025815",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 11:22:47.934897 UTC",
  "message_id": "1695727368294292",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-30",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:48.192399 UTC",
  "message_id": "1695730968205198",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:48.216204 UTC",
  "message_id": "1695730968221486",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:48.216290 UTC",
  "message_id": "1695730968223252",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:48.219915 UTC",
  "message_id": "1695730968225218",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 12:22:48.219758 UTC",
  "message_id": "1695730968225396",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "IOS",
  "topic": "pl-9",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.891997 UTC",
  "message_id": "0:1695720166900726%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892421 UTC",
  "message_id": "0:1695720166900853%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.891962 UTC",
  "message_id": "0:1695720166900856%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.893175 UTC",
  "message_id": "0:1695720166901420%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892938 UTC",
  "message_id": "0:1695720166901504%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892071 UTC",
  "message_id": "0:1695720166901964%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.895175 UTC",
  "message_id": "0:1695720166902178%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.895253 UTC",
  "message_id": "0:1695720166902762%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}, {
  "event_timestamp": "2023-09-26 09:22:46.892765 UTC",
  "message_id": "0:1695720166902964%38c1f67ff9fd7ecd",
  "message_type": "DATA_MESSAGE",
  "sdk_platform": "ANDROID",
  "topic": "pl-1",
  "event": "MESSAGE_ACCEPTED",
  "analytics_label": ""
}];

  export default data;