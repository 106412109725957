import UserService from './UserService';
import axios from "axios";

export const getLibraryConfig = async () => {
  console.log("getLibraryConfig()");

  let idLibraryConfig = "1"; // Por defecto solamente va a haber una biblioteca
  let url = process.env.REACT_APP_URL_FULL_LIBRARY_CONFIG + "/" + idLibraryConfig; 

  const request = axios.create({
    baseURL: url,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    }
  });

  console.log("request.get()");
  return request.get(process.env.REACT_APP_URL_FULL_LIBRARY_CONFIG, "");
}  


export const updateLibraryConfig = (libraryConfig) => {
  console.log("updateLibraryConfig()");

  const request = axios.create({
    baseURL: process.env.REACT_APP_URL_FULL_LIBRARY_CONFIG,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    },
  });

  return request.put(process.env.REACT_APP_URL_FULL_LIBRARY_CONFIG, libraryConfig);
}

  
export const updateLibraryConfigLocalhost = (libraryConfig) => {
  console.log("updateLibraryConfigLocalhost()");
  return Promise.resolve("La operación se completó exitosamente.");
}
