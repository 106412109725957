import React from "react";
import {BrowserRouter} from "react-router-dom";
import "./app.css"
import Homepage from "./pages/Homepage";
import RenderOnAnonymous from "./helpers/RenderOnAnonymous";
import AnonymousHome from "./pages/AnonymousPage";
import RenderOnAuthenticated from "./helpers/RenderOnAuthenticad";

function App() {

    return (
        <div>
             
            <BrowserRouter>
                <RenderOnAnonymous>
                    <AnonymousHome/>
                </RenderOnAnonymous>
                <RenderOnAuthenticated>
                    <Homepage/>
                </RenderOnAuthenticated>  
            </BrowserRouter>
        </div>
    );
}

export default App;
