import React, { useState}   from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory, useParams} from "react-router-dom";
import SelectFile from "../media/SelectFile"
import UserService from '../../services/UserService';
import axios from "axios";
import {requestUpdateBeaches} from '../../services/BeachesService';

const EditBeaches = () => {

    const {id} = useParams();

    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [errorMsg, setErrorMsg] = useState();
    const [hideNoImageComponent, setHideNoImageComponent] = useState(false);
    const [hideImageOptionsComponent, setHideImageOptionsComponent] = useState(true);
    const [hideGaleryComponent, setGaleryComponent] = useState(true);
    const [hideImageComponent, setHideImageComponent] = useState(true);
    const [show, setShow] = useState(false);
    const [fotografo, setFotografo] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [imageUrlMini, setImageUrlMini] = useState("");
    const [beachName, setBeachName] = useState("");

    let history = useHistory();
    let selImage = "";
    let selImageMini = "";

    React.useEffect(() => {
        getData();
        setImageUrl(selImage);
    },[selImage])

    const getData = async () => {
        const headers = {
            "Content-Security-Policy": "frame-ancestors none",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }

        const url = process.env.REACT_APP_URL_FULL_BEACHES+`/${id}`; 
        const data = await (await axios.get(url, { 'headers':  headers })).data;
        const dataBeach = await data;

        setFotografo(dataBeach.photograph);
        setImageUrlMini(dataBeach.urlImgMini);
        setBeachName(dataBeach.beachName);
        if(selImage === undefined || selImage === ""){
            setImageUrl(dataBeach.urlImg)
            setHideImageComponent(false)
            setHideNoImageComponent(true)
        }else{
            setImageUrl(selImage)
            setHideImageComponent(true)
            setHideNoImageComponent(false)
        }

    }

    function selectedImage(){
        selImage = window["urlImage"]
        selImageMini = window["urlImageMini"];

        setImageUrl(selImage)
        setImageUrlMini(selImageMini)
        setHideImageComponent(false)
        setHideNoImageComponent(true)
        hideGallery()
    }
    function showGallery(){
        setShow(true)
        setGaleryComponent(false);
    }
    function hideGallery(){
        setShow(false);
        setGaleryComponent(true);
        setHideImageOptionsComponent(true)
    }
    function cancel(){
        window["urlImage"] = "";
        window["urlImageMini"] = "";
        history.goBack();
    }
    function validateValues(){
        if(fotografo === "" || fotografo === undefined ||
            imageUrl === "" || imageUrl === undefined ){
                
            setErrorMsg(<FormattedMessage id="playas.error.camposVacios" defaultMessage="Por favor, rellene los campos vacios."/>)
            setHideErrorComponent(false);

        }else updateBeach();
    }
    function updateBeach(){
        window["urlImage"] = ""
        window["urlImageMini"] = ""
        const data = {
            photograph: fotografo,
            urlImg: imageUrl,
            urlImgMini: imageUrlMini
        }
        requestUpdateBeaches(data, id).then(() => history.push("/beaches"));;
    }
    function handleSelect(){
        history.push("/beaches");
    }
    const BackDrop = ({show}) => 
    <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>

    return (
        <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}><FormattedMessage id="playas.tituloMinus" defaultMessage="Playas"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" >{beachName}</i>
        </div>
        <div className='mainDiv'>
          <label className="title" style={{paddingTop: 10}}> <FormattedMessage id="playas.editarPlaya" defaultMessage="EDITAR PLAYA"/></label> 
          <div style={{height:300}}>  
            <div className='ui form posLeft'>
                <div>
                    <label><b><FormattedMessage id="playas.nombre" defaultMessage="NOMBRE" /></b></label>
                </div>
                <div>
                    <span>{beachName}</span>
                </div>
                <div>
                    <label><b><FormattedMessage id="playas.fotografo" defaultMessage="Fotógrafo" /></b></label>
                </div>
                <div>
                    <input required type="text" className="" 
                        onChange={event => setFotografo(event.target.value)}
                        value={fotografo}>
                    </input>
                </div>
            </div>
            <div className='posRight'>
                <div>
                    <label><b><FormattedMessage id="playas.fotografia" defaultMessage="Fotografía" /></b></label>
                </div>
                <div className={`${hideNoImageComponent ? "hide-Component" : "imageSpan"}`} >
                   <span>
                        <button className='noImage' onClick={() => setHideImageOptionsComponent(!hideImageOptionsComponent)}>
                            <FormattedMessage id="playas.anadirImagen" defaultMessage="Añadir imagen" />
                        </button>
                    </span>
                </div>
                <div className={`${hideImageComponent ? "hide-Component" : "imageSpan"}`}>
                    <span>
                    <button className="pencil-icon" onClick={() => showGallery(false)}><i className="ui white pencil icon"></i></button>
                    <img className="formImage" src={imageUrl} alt="Imagen" />
                    </span>
                </div>
                <div className={`${hideImageOptionsComponent ? "hide-Component" : "optionsComponent"}`} >
                    <span><ul className="optionsList">
                        <li onClick={() => showGallery(false)}><FormattedMessage id="playas.seleccionarImagen" defaultMessage="Seleccionar Imagen" /></li>
                        <li><FormattedMessage id="playas.imagenPredefinida" defaultMessage="Imagen predefinida" /></li>
                    </ul></span>
                </div>
            </div>
          </div>
          <div>
                <button className="ui red button" onClick={() => validateValues()}>
                    <FormattedMessage id="playas.guardar" defaultMessage="Guardar" />
                </button>
                <button className="ui noBorder button" onClick={() => cancel()}>
                    <FormattedMessage id="playas.cancelar" defaultMessage="Cancelar" />
                </button>
          </div> 
          <BackDrop show={show}></BackDrop> 
          <div className={`${hideGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
                <SelectFile></SelectFile>
                <div>
                    <button className="ui red button" onClick={() => selectedImage()}>
                        <FormattedMessage id="playas.guardar" defaultMessage="Guardar" />
                    </button>
                    <button className="ui noBorder button" onClick={() => hideGallery(true)}>
                        <FormattedMessage id="playas.cancelar" defaultMessage="Cancelar" />
                    </button>
                </div>        
          </div>
          <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
            <div><span>{errorMsg}</span></div>
            <div>
                <button className="ui red button" onClick={() => setHideErrorComponent(true)}>
                <FormattedMessage id="playas.aceptar" defaultMessage="Aceptar"/>
                </button>
            </div>
          </div>
        </div>
        </>
    )
}

export default EditBeaches