import UserService from './UserService';
import axios from "axios";

export const requestSendNotification = (data) => {
    const html = axios.create({
        baseURL: process.env.REACT_APP_URL_FULL_NOTIFICATION,
        headers: {
          "Content-Security-Policy": "frame-ancestors none",
          "Content-type": "application/json",
          "Authorization": "Bearer " + UserService.getToken()
        }
      });
      html.post(process.env.REACT_APP_URL_FULL_NOTIFICATION, data);
}

export const  requestSuscribe = (data) => {
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_NOTIFICATION,
      headers: {
        "Content-Security-Policy": "frame-ancestors none",
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.put(process.env.REACT_APP_URL_FULL_NOTIFICATION, data);
  } 


export const getServices = async () => {
  console.log("getServices()");
  
  const request = axios.create({
    baseURL: process.env.REACT_APP_URL_FULL_NOTIFICATION_SERVICES,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    }
  });

  console.log("request.get()");
  return request.get(process.env.REACT_APP_URL_FULL_NOTIFICATION_SERVICES, "");
}  


export const filterDevices = async (filterArray) => {
  console.log("filterDevices()");

  let getParams = {
    params: filterArray
  }

  const request = axios.create({
    baseURL: process.env.REACT_APP_URL_FULL_NOTIFICATION_DEVICES,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    },
  });

  return request.get(process.env.REACT_APP_URL_FULL_NOTIFICATION_DEVICES, getParams);
}


export const sendCustomNotification = (filter, notification) => {
  console.log("sendCustomNotification()");

  let postData = {
    filter: filter,
    notification: notification
  }

  const request = axios.create({
    baseURL: process.env.REACT_APP_URL_FULL_NOTIFICATION_CUSTOM,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    },
  });

  return request.post(process.env.REACT_APP_URL_FULL_NOTIFICATION_CUSTOM, postData);
}

  
