import React, {useState} from 'react'; 
import { useParams } from 'react-router-dom';
import { requestGetNewById } from '../../services/NewsService';
import Moment from 'moment';
import Popup from 'reactjs-popup';
import {FormattedMessage} from 'react-intl';
import {useHistory} from "react-router-dom";


  const ViewNews = () => {

    const {id} = useParams();

    const [viewNews, setViewNews] = useState('') 
    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    
    React.useEffect(() => {
     requestGetNewById(setViewNews, id)
   
    },[id])  
    

    
    function createList () {

        let list = ' '

        if(viewNews!== '') {
          viewNews.categories.map((option) => (
           list = list + ' ' + ' | ' + ' ' + option.nameEs
         
          ))
          }

          return list;

    }

    function createListEu () {

      let list = ' '

      if(viewNews!== '') {
        viewNews.categories.map((option) => (
         list = list + ' ' + ' | ' + ' ' + option.nameEu 
       
        ))
        }

        return list;

  }

     function setLang(lang){
      if(lang === "ES"){
          setHideEsComponent(false)
          setHideEuComponent(true)
      }else{
          setHideEsComponent(true)
          setHideEuComponent(false)
      }
  
  }

  let history = useHistory();

  function handlePushNavigation(){
    history.push("/news");
}

  return (

    <>

<div className="ui large breadcrumb" style={{paddingBottom:15}}>
      <i className="home red icon"></i>
      <a className="section" style={{color: "red"}} onClick={handlePushNavigation}><FormattedMessage id="noticias.detalle.noticias" defaultMessage="Noticias"/></a>
       <i className="right large angle icon divider"></i>
     <i className="section" >{viewNews.titleEs}</i>
    </div>
<div className="mainDiv" style={{height:'auto', paddingBottom:30}}>

<div className='ui grid' >
<div className='six wide column'>
               <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                <div className="middle aligned content">
              <div>
                <h1 className='title'>{viewNews.titleEs}</h1>
            </div>
          </div>
         
        </div>
        

        <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`} >
         <div className="middle aligned content">
          <div>
            <h1 className='title'>{viewNews.titleEu}</h1>
        </div>
      </div>
    </div>

   
      </div>
     
     
 
     
    
      <div className="red-box" style={{paddingTop:20, height:50, marginRight:8, width:800}} >
            <select className="ui red-Select select" style={{marginLeft:600}} name="selectType" onChange={event => setLang(event.target.value)}>
              <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                {(message) => <option value="ES">{message}</option>}
              </FormattedMessage>
                <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                {(message) => <option value="EU">{message}</option>}
              </FormattedMessage>
            </select>
            </div> 



     


            </div>


            <div className='ui grid' >
            
            <div className="ui divided items">
            <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
              <div>
            <Popup trigger={
                  <button className='search-icon'  style={{marginTop:60, marginLeft:350}}><i className="ui white search icon"></i></button>
                } >
                <img style={{width:500}} src={viewNews.mainImage} alt="Imagen" /> 
        </Popup>
        <div>
                <img style={{width:400, paddingLeft:16, float:"left"}}src={viewNews.mainImage} alt="Imagen" />
                </div>
                </div>

           
           
            </div>

            <div className="ui divided items">
            <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
              <div>
            <Popup trigger={
                  <button className='search-icon'  style={{marginTop:60, marginLeft:350}}><i className="ui white search icon"></i></button>
                } >
                <img style={{width:500}} src={viewNews.mainImageEu} alt="Imagen" /> 
        </Popup>
                <img style={{width:400, paddingLeft:16, float:"left"}}src={viewNews.mainImageEu} alt="Imagen" />
                
                </div>
                </div>

           
                </div>
            </div>

           
            
            <div className='ten wide column'>
            
            <label><b><FormattedMessage id="noticias.detalle.estado" defaultMessage="ESTADO"/></b></label>
              <div>
                {viewNews.status}
               </div>
               <div className='lineaNew'></div>
               <div className='ui divided items'>
            <label><b><FormattedMessage id="noticias.detalle.fechUltiActu" defaultMessage="FECHA / HORA ÚLTIMA ACTUALIZACIÓN"/></b></label>
            <div>
                {Moment(viewNews.updateDate).format("DD/MM/YYYY HH:mm")}
              </div>
              <div className='lineaNew'></div>
            </div>

            <div className='ui divided items'>
              <label><b><FormattedMessage id="noticias.detalle.fechPublica" defaultMessage="FECHA / HORA PUBLICACIÓN"/></b></label>
      <div>
      {Moment(viewNews.publishingDate).format("DD/MM/YYYY HH:mm")}
      </div>
      <div className='lineaNew'></div>
      </div>

      <div className='four wide column'>
      <label><b><FormattedMessage id="noticias.detalle.fechExpirac" defaultMessage="FECHA / HORA EXPIRACIÓN"/></b></label>
      <div>
      {Moment(viewNews.expirationDate).format("DD/MM/YYYY HH:mm")}
      </div>
      <div className='lineaNew'></div>
      </div>
{/* 
<div className='ui divided items'>
      <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
      <div className="middle aligned content">
        <label> <b><FormattedMessage id="noticias.detalle.categoriasES" defaultMessage="CATEGORÍAS CASTELLANO"/></b></label>
          <div>
            <a className='ui red basic horizontal label' style={{backgroundColor: "#FFFFFF"}}>{createList()} </a> 
          </div>
      </div>
      
   </div>
 

   

   <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
    <div className= "four wide column">
        <label> <b><FormattedMessage id="noticias.detalle.categoriasEUS" defaultMessage="CATEGORÍAS EUSKERA"/></b></label>
          <div>
            <a className='ui red basic horizontal label' style={{backgroundColor: "#FFFFFF"}}>{createListEu()}</a>
         </div>
      </div>
  </div>
  </div> */}
  <div className='lineaNew'></div>
</div>
</div>


        <div className='ui divided items body' style={{paddingLeft:10}} >

            <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
<div className="middle aligned content">
<label><b><FormattedMessage id="noticias.detalle.cuerpoTextoES" defaultMessage="CUERPO DE TEXTO CASTELLANO"/></b></label>
<p key={viewNews.id}  dangerouslySetInnerHTML={{__html: viewNews.bodyEs}}></p>
   </div>
</div>


<div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
<div className="middle aligned content">
<label><b><FormattedMessage id="noticias.detalle.cuerpoTextoEUS" defaultMessage="CUERPO DE TEXTO EUSKERA"/></b></label>
<p key={viewNews.id}  dangerouslySetInnerHTML={{__html: viewNews.bodyEu}}></p>
   </div>
</div>
</div>
  
        </div>
        </>
  )     
}
         

  
 export default ViewNews;
