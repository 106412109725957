import React, { useState}   from 'react';
import {useHistory} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import DatePicker from "react-datepicker";
import {requestUpdateConfiguration} from '../../services/BeachesService';
import axios from "axios";
import UserService from '../../services/UserService'

const ConfBeaches = () => {

    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [errorMsg, setErrorMsg] = useState();
    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    const [warningTextEs, setWarningTextEs] = useState("");
    const [warningTextEu, setWarningTextEu] = useState("");
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());

    let history = useHistory();

    React.useEffect(() => {
        getData();
    },[])
    const getData = async () => {
        const headers = {
            "Content-Security-Policy": "frame-ancestors none",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }

        const url = process.env.REACT_APP_URL_FULL_BEACHES + `/configuration`; 
        const data = await (await axios.get(url, { 'headers':  headers })).data;
        const dataConfiguration = await data;

        setWarningTextEs(dataConfiguration[0].warningEs);
        setWarningTextEu(dataConfiguration[0].warningEu);
        setInitialDate(new Date(dataConfiguration[0].initialDate));
        setFinalDate(new Date(dataConfiguration[0].finalDate));

    }
    function setLang(lang){
        if(lang === "ES"){
            setHideEsComponent(false)
            setHideEuComponent(true)
        }else{
            setHideEsComponent(true)
            setHideEuComponent(false)
        }
    }
    function validateValues(){
        if(warningTextEs === '' ||
           warningTextEu === '' ||
           initialDate === '' ||
           finalDate === ''){
            setErrorMsg(<FormattedMessage id="playas.error.camposVacios" defaultMessage="Por favor, rellene los campos vacios."/>)
            setHideErrorComponent(false);
        }else updateWarning();
    }
    function updateWarning(){
        const body = {
            id:1,
            warningEs:warningTextEs,
            warningEu:warningTextEu,
            initialDate:initialDate,
            finalDate:finalDate
        }
        requestUpdateConfiguration(body).then(() => history.push("/beaches"));
    }

    function cancel(){
        history.goBack()
    }
    function handleSelect(){
        history.push("/beaches");
    }
    return (
        <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="playas.tituloMinus" defaultMessage="Playas"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" ><FormattedMessage id="playas.playasMayus" defaultMessage="Configurar playas"/></i>
        </div>
        <div className='mainDiv'>
            <div className="div-inLine">
                <label className="title" style={{paddingTop: 10}}>
                    <FormattedMessage id="playas.playasMayus" defaultMessage="Configurar playas"/>
                </label>
                <div className="red-box">
                    <select className="ui red-Select select" name="selectType" onChange={event => setLang(event.target.value)}>
                        <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                            {(message) => <option value="ES">{message}</option>}
                        </FormattedMessage>
                            <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                            {(message) => <option value="EU">{message}</option>}
                        </FormattedMessage>
                    </select>
                </div>  
            </div>
            <div className="ui form">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                                    <FormattedMessage id="playas.avisoSecundarioEs" defaultMessage="Aviso secundario castellano" />
                                </label>
                                <input required type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                                    onChange={event => setWarningTextEs(event.target.value)}
                                    value={warningTextEs}>
                                </input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                                    <FormattedMessage id="playas.avisoSecundarioEu" defaultMessage="Aviso secundario euskera" />
                                </label>
                                <input required type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                                    onChange={event => setWarningTextEu(event.target.value)}
                                    value={warningTextEu}>
                                </input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                                    <b><FormattedMessage id="playas.temporada" defaultMessage="Temporada" /></b>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                                    <FormattedMessage id="playas.inicio" defaultMessage="Inicio" />
                                </label>
                            </td>
                            <td>
                                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                                    <FormattedMessage id="playas.fin" defaultMessage="Fin" />
                                </label>
                            </td>
                            <td></td>
                        </tr>
                        <tr>                       
                            <td>
                            <DatePicker 
                                locale="es"
                                dateFormat="dd/MM/yyyy" 
                                selected={new Date(initialDate)} 
                                onChange={(date) => setInitialDate(date)} />
                            </td>
                            <td>
                            <DatePicker 
                                locale="es"
                                dateFormat="dd/MM/yyyy" 
                                selected={new Date(finalDate)} 
                                onChange={(date) => setFinalDate(date)} />
                            </td>                    
                        </tr>            
                    </tbody>
                </table>
            </div>
            <div>
                <button className="ui red button" onClick={() => validateValues()}>
                    <FormattedMessage id="playas.guardar" defaultMessage="Guardar" />
                </button>
                <button className="ui noBorder button" onClick={() => cancel()}>
                    <FormattedMessage id="playas.cancelar" defaultMessage="Cancelar" />
                </button>
            </div>
            <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
                <div><span>{errorMsg}</span></div>
                <div>
                    <button className="ui red button" onClick={() => setHideErrorComponent(true)}>
                    <FormattedMessage id="playas.aceptar" defaultMessage="Aceptar"/>
                    </button>
                </div>
            </div>
          </div>
          </>
    )
}




export default ConfBeaches