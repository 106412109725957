import React, {useState} from 'react'; 
import {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import {FormattedMessage} from 'react-intl';
import { useHistory} from 'react-router-dom';
import { requestPostTown, requestGetListPools, requestGetTownById } from '../../services/PoolsService';


const CreatePool = () => {

    const [townCode, setTownCode] = useState("");
    const [townNameEs, setTownNameEs] = useState("");
    const [townNameEu, setTownNameEu] = useState("");
    const [poolCode, setPoolCode] = useState("0");
    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [errorMsg, setErrorMsg] = useState();
    const [muncipioExistente, setMunicipioExistente] = useState();
    const [pools, setPools] = useState([]);
    const fieldsMessageCamposObligatorios = 
    <div >
        <i className="big exclamation red circle icon"></i>
        <p><FormattedMessage id="piscinas.anadir.error.camposObligatorios" defaultMessage="Los campos marcados con asterisco son "/><b><FormattedMessage id="piscinas.anadir.error.obligatorios" defaultMessage="obligatorios."/></b></p>
    </div>

    const fieldsMessageMinicipioExistente = 
    <div >
       <i className="big exclamation red circle icon"></i>
       <p><FormattedMessage id="piscinas.anadir.error.muncipioExistente" defaultMessage="Las piscinas del municipio elegido ya estan listadas"/></p>
    </div>

    React.useEffect(() => {
        registerLocale("es", es);
    },[])

    function validateFields () {

        let valError = false;

        if( townCode  === "" || townCode  === undefined || townCode === null) {
    
            valError = true;
        }
        if( poolCode  === "0" || poolCode  === "" || poolCode  === undefined || poolCode === null) {
    
            valError = true;
        }

        if(valError){
          setErrorMsg(fieldsMessageCamposObligatorios);
          setHideErrorComponent(false);
        
        }else if(muncipioExistente !== 0){
            setErrorMsg(fieldsMessageMinicipioExistente);
            setHideErrorComponent(false);
        }else{
            createPool();
        }
    }

    let history = useHistory();
    function handleClick() {
        history.push("/swimming-pools");
    }    

    function createPool () {
             
        const town = {
            townCode:townCode,
            townNameEs:townNameEs,
            townNameEu:townNameEu,
        }

        const body = {
            town:town,
            pool:poolCode
        }
        requestPostTown(body);

        setTimeout (() => {
            history.push("/swimming-pools");
        }, 1000);
    }

     function handlePushNavigation(){
         history.push("/swimming-pools");
     }

     function searchPools(e){
        setDatosTown(e);
        requestGetListPools(setPools, e.target.value.substring(0,3));
        setPoolCode("0");
     }
    function setDatosTown(e){
        setTownCode(e.target.value.substring(0,3));
        setTownNameEs(e.target.value.substring(3,e.target.value.length));
        setTownNameEu("");
    }
    function setDatosPool(e){
        setPoolCode(e.target.value);
        requestGetTownById(setMunicipioExistente,townCode,e.target.value);
    }

    let msgSelect = 
        <FormattedMessage id="piscinas.anadir.noPool" defaultMessage="No hay piscinas disponibles">
            {(message) => <option disabled value="0">{message}</option>}
        </FormattedMessage>;
    let emptySelect;
    if(pools.length > 0){
        msgSelect = 
            <FormattedMessage id="piscinas.anadir.selectPool" defaultMessage="Selecciona piscina">
                {(message) => <option value="0" selected>{message}</option>}
            </FormattedMessage>
        emptySelect = pools.map(data => (
            <option value={data.IDInstalacion}>{data.Nombre}</option>
        ));
    }

    return (
        <>

        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handlePushNavigation}><FormattedMessage id="piscinas.anadir.piscinas" defaultMessage="Piscinas" /></a>
            <i className="right large angle icon divider"></i>
            <i className="section"><FormattedMessage id="piscinas.anadir" defaultMessage="Añadir piscinas" /></i>
        </div>
        <div className="mainDiv" style={{paddingTop:15}}>
            <div className="div-inLine">
                <label className="title"><FormattedMessage id="piscinas.anadir" defaultMessage="Añadir municipio piscina"/></label>  
            </div>
            <div className="ui grid"  style={{marginTop:'auto', paddingTop:10}}>
                <div className= "five wide column"  style={{ marginLeft:14}}>
                    <label><b><FormattedMessage id="piscinas.anadir.municipio" defaultMessage="Municipio *"/></b> </label>                              
                    <select className="ui select" style={{width:300}} name="selectTown" defaultValue={""} onChange={e => searchPools(e)} >
                        <FormattedMessage id="piscinas.anadir.selectMunicipio" defaultMessage="Selecciona un municipio">
                            {(message) => <option disabled value="">{message}</option>}
                        </FormattedMessage>
                        <option value="001ABADIÑO">ABADIÑO</option>
                        <option value="002ABANTO Y CIERVANA-ABANTO ZIERBENA">ABANTO Y CIERVANA-ABANTO ZIERBENA</option>
                        <option value="003AMOREBIETA-ETXANO">AMOREBIETA-ETXANO</option>
                        <option value="004AMOROTO">AMOROTO</option>
                        <option value="005ARAKALDO">ARAKALDO</option>
                        <option value="006ARANTZAZU">ARANTZAZU</option>
                        <option value="023ARTEA">ARTEA</option>
                        <option value="914ARRATZU">ARRATZU</option>
                        <option value="007MUNITIBAR-ARBATZEGI GERRIKAITZ">MUNITIBAR-ARBATZEGI GERRIKAITZ</option>
                        <option value="008ARTZENTALES">ARTZENTALES</option>
                        <option value="009ARRANKUDIAGA-ZOLLO">ARRANKUDIAGA-ZOLLO</option>
                        <option value="010ARRIETA">ARRIETA</option>
                        <option value="011ARRIGORRIAGA">ARRIGORRIAGA</option>
                        <option value="911AJANGIZ">AJANGIZ</option>
                        <option value="912ALONSOTEGI">ALONSOTEGI</option>
                        <option value="070AULESTI">AULESTI</option>
                        <option value="012BAKIO">BAKIO</option>
                        <option value="013BARAKALDO">BARAKALDO</option>
                        <option value="014BARRIKA">BARRIKA</option>
                        <option value="015BASAURI">BASAURI</option>
                        <option value="016BERANGO">BERANGO</option>
                        <option value="017BERMEO">BERMEO</option>
                        <option value="018BERRIATUA">BERRIATUA</option>
                        <option value="019BERRIZ">BERRIZ</option>
                        <option value="020BILBAO">BILBAO</option>
                        <option value="021BUSTURIA">BUSTURIA</option>
                        <option value="022KARRANTZA HARANA/VALLE DE CARRANZA">KARRANTZA HARANA/VALLE DE CARRANZA</option>
                        <option value="026DIMA">DIMA</option>
                        <option value="901DERIO">DERIO</option>                        
                        <option value="027DURANGO">DURANGO</option>
                        <option value="028EA">EA</option>
                        <option value="902ERANDIO">ERANDIO</option>
                        <option value="029ETXEBARRI">ETXEBARRI</option>
                        <option value="030ETXEBARRIA">ETXEBARRIA</option>
                        <option value="031ELANTXOBE">ELANTXOBE</option>
                        <option value="032ELORRIO">ELORRIO</option>
                        <option value="033EREÑO">EREÑO</option>
                        <option value="034ERMUA">ERMUA</option>
                        <option value="035FRUIZ">FRUIZ</option>
                        <option value="036GALDAKAO">GALDAKAO</option>
                        <option value="037GALDAMES">GALDAMES</option>
                        <option value="038GAMIZ-FIKA">GAMIZ-FIKA</option>
                        <option value="039GARAI">GARAI</option>
                        <option value="040GATIKA">GATIKA</option>
                        <option value="041GAUTEGIZ ARTEAGA">GAUTEGIZ ARTEAGA</option>
                        <option value="042GORDEXOLA">GORDEXOLA</option>
                        <option value="043GORLIZ">GORLIZ</option>
                        <option value="044GETXO">GETXO</option>
                        <option value="045GUEÑES">GUEÑES</option>
                        <option value="046GERNIKA-LUMO">GERNIKA-LUMO</option>
                        <option value="047GIZABURUAGA">GIZABURUAGA</option>
                        <option value="048IBARRANGELU">IBARRANGELU</option>
                        <option value="049ISPASTER">ISPASTER</option>
                        <option value="910IURRETA">IURRETA</option>
                        <option value="050IZURTZA">IZURTZA</option>
                        <option value="051LANESTOSA">LANESTOSA</option>
                        <option value="052LARRABETZU">LARRABETZU</option>
                        <option value="053LAUKIZ">LAUKIZ</option>
                        <option value="054LEIOA">LEIOA</option>
                        <option value="055LEMOA">LEMOA</option>
                        <option value="056LEMOIZ">LEMOIZ</option>
                        <option value="057LEKEITIO">LEKEITIO</option>
                        <option value="058MALLABIA">MALLABIA</option>
                        <option value="059MAÑARIA">MAÑARIA</option>
                        <option value="060MARKINA-XEMEIN">MARKINA-XEMEIN</option>
                        <option value="061MARURI-JATABE">MARURI-JATABE</option>
                        <option value="062MENDATA">MENDATA</option>
                        <option value="063MENDEXA">MENDEXA</option>
                        <option value="064MEÑAKA">MEÑAKA</option>
                        <option value="065UGAO-MIRABALLES">UGAO-MIRABALLES</option>
                        <option value="066MORGA">MORGA</option>
                        <option value="067MUXIKA">MUXIKA</option>
                        <option value="068MUNDAKA">MUNDAKA</option>
                        <option value="069MUNGIA">MUNGIA</option>
                        <option value="071MUSKIZ">MUSKIZ</option>
                        <option value="072OTXANDIO">OTXANDIO</option>
                        <option value="073ONDARROA">ONDARROA</option>
                        <option value="074URDUÑA/ORDUÑA">URDUÑA/ORDUÑA</option>
                        <option value="075OROZKO">OROZKO</option>
                        <option value="076SUKARRIETA">SUKARRIETA</option>
                        <option value="077PLENTZIA">PLENTZIA</option>
                        <option value="078PORTUGALETE">PORTUGALETE</option>
                        <option value="079ERRIGOITI">ERRIGOITI</option>
                        <option value="080VALLE DE TRAPAGA-TRAPAGARAN">VALLE DE TRAPAGA-TRAPAGARAN</option>
                        <option value="081LEZAMA">LEZAMA</option>
                        <option value="082SANTURTZI">SANTURTZI</option>
                        <option value="083ORTUELLA">ORTUELLA</option>
                        <option value="084SESTAO">SESTAO</option>
                        <option value="085SOPELA">SOPELA</option>
                        <option value="086SOPUERTA">SOPUERTA</option>
                        <option value="087TRUCIOS-TURTZIOZ">TRUCIOS-TURTZIOZ</option>
                        <option value="088UBIDE">UBIDE</option>
                        <option value="089URDULIZ">URDULIZ</option>
                        <option value="090BALMASEDA">BALMASEDA</option>
                        <option value="091ATXONDO">ATXONDO</option>
                        <option value="092BEDIA">BEDIA</option>
                        <option value="093AREATZA">AREATZA</option>
                        <option value="094IGORRE">IGORRE</option>
                        <option value="095ZALDIBAR">ZALDIBAR</option>
                        <option value="096ZALLA">ZALLA</option>
                        <option value="097ZARATAMO">ZARATAMO</option>
                        <option value="903LOIU">LOIU</option>
                        <option value="904SONDIKA">SONDIKA</option>
                        <option value="905ZAMUDIO">ZAMUDIO</option>
                        <option value="906FORUA">FORUA</option>
                        <option value="907KORTEZUBI">KORTEZUBI</option>
                        <option value="908MURUETA">MURUETA</option>
                        <option value="909NABARNIZ">NABARNIZ</option>
                        <option value="913ZIERBENA">ZIERBENA</option>
                        <option value="915ZIORTZA-BOLIBAR">ZIORTZA-BOLIBAR</option>
                        <option value="024ZEANURI">ZEANURI</option>
                        <option value="025ZEBERIO">ZEBERIO</option>
                    </select>
                </div>
                <div className= "five wide column"  style={{ marginLeft:14}}>
                    <label><b><FormattedMessage id="piscinas.anadir.pool" defaultMessage="Piscina *"/></b> </label>
                    <select className="ui select" style={{width:300}} name="selectPool" value={poolCode} onChange={e => setDatosPool(e)}>
                        {msgSelect}
                        {emptySelect}
                    </select>
                </div>
            </div>
            <div style={{paddingLeft:15, paddingTop:18, paddingBottom:5}}>
                <button className="ui red button"  onClick={() => validateFields()}><FormattedMessage id="piscinas.anadir.guardar" defaultMessage="GUARDAR"/></button>
                <button className="ui noBorder button"  onClick={() => handleClick()}><FormattedMessage id="piscinas.anadir.cancelar" defaultMessage="CANCELAR"/></button>
            </div>
            <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
                <div><span>{errorMsg}</span></div>
                <div><button className="ui red button" style={{marginTop:17}} onClick={() => setHideErrorComponent(true)}><FormattedMessage id="piscinas.anadir.aceptar" defaultMessage="Aceptar"/></button></div>
            </div> 
    
        </div>

        </>
     
    )
}
export default CreatePool;
