const data = [
    {
        "id": "1",
        "nombre": "Grupo 1",
        "roles": "offline_access, admin, default-roles-backoffice-devm, uma_authorization"
    },
    {
        "id": "2",
        "nombre": "Grupo 2",
        "roles": "user, editor, viewer"
    },
    {
        "id": "3",
        "nombre": "Grupo 3",
        "roles": "guest, reader"
    },
    {
        "id": "4",
        "nombre": "Grupo 4",
        "roles": "manager, contributor"
    },
    {
        "id": "5",
        "nombre": "Grupo 5",
        "roles": "admin, viewer, uma_authorization"
    },
    {
        "id": "6",
        "nombre": "Grupo 6",
        "roles": "editor, contributor"
    },
    {
        "id": "7",
        "nombre": "Grupo 7",
        "roles": "guest, reader"
    },
    {
        "id": "8",
        "nombre": "Grupo 8",
        "roles": "user, viewer"
    },
    {
        "id": "9",
        "nombre": "Grupo 9",
        "roles": "admin, manager"
    },
    {
        "id": "10",
        "nombre": "Grupo 10",
        "roles": "editor, contributor"
    }
];
  export default data;