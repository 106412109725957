import React, {useState} from 'react'; 
import { useParams } from 'react-router-dom';
import { requestGetBeaches } from '../../services/BeachesService';
import { format } from "date-fns";
import Popup from 'reactjs-popup';
import {FormattedMessage} from 'react-intl';
import {useHistory} from "react-router-dom";
import UserService from '../../services/UserService'
import axios from "axios";

  const ViewBeaches = () => {

  const {id} = useParams();

  const [viewBeaches, setViewBeaches] = useState('');
  const [hideEsComponent, setHideEsComponent] = useState(false);
  const [hideEuComponent, setHideEuComponent] = useState(true);
  const [warningTextEs, setWarningTextEs] = useState("");
  const [warningTextEu, setWarningTextEu] = useState("");
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [iconFlag, setIconFlag] = useState("ui icon icon-flag-grey");
  const [iconWeather, setIconWeather] = useState("ui icon icon-weather-NoData");
  const [iconWind, setIconWind] = useState("ui icon icon-wind-noData");
  const [iconOccupation, setIconOccupation] = useState("ui icon icon-occupation-noData");
  const [iconParking, setIconParking] = useState("ui icon icon-parking-noData");
  const [iconWaves, setIconWaves] = useState("ui icon icon-waves-noData");
  const [iconTemp, setIconTemp] = useState("ui icon icon-temperature-NoData");
  const [language, setLanguage] = useState("ui icon icon-temperature-NoData");
  let history = useHistory();

  React.useEffect(() => {
      setLanguage(localStorage.getItem('lang'));
      requestGetBeaches(setViewBeaches, setIconsClasses, id);
      getConfiguration();
      
  },[id])

  const getConfiguration= async () => {
      const headers = {
          "Content-Security-Policy": "frame-ancestors none",
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + UserService.getToken()
    }

    const url = process.env.REACT_APP_URL_FULL_BEACHES + `/configuration`; 
    const data = await (await axios.get(url, { 'headers':  headers })).data;
    const dataConfiguration = await data;
    setWarningTextEs(dataConfiguration[0].warningEs);
    setWarningTextEu(dataConfiguration[0].warningEu);
    setInitialDate(new Date(dataConfiguration[0].initialDate));
    setFinalDate(new Date(dataConfiguration[0].finalDate));
  }
  
  function setIconsClasses(res){
    
    if(res.flagEs === "Bandera verde") setIconFlag("ui icon icon-flag-green");
    else if(res.flagEs === "Bandera roja") setIconFlag("ui icon icon-flag-red");
    else if(res.flagEs === "Bandera amarilla") setIconFlag("ui icon icon-flag-yellow");

    if(res.weatherEs === "Nuboso") setIconWeather("ui icon icon-cloud");
    else if(res.weatherEs === "Variable") setIconWeather("ui icon icon-variable");
    else if(res.weatherEs === "Lluvia") setIconWeather("ui icon icon-rain");
    else if(res.weatherEs === "Soleado") setIconWeather("ui icon icon-sun");

    if(res.windEs === "Flojo") setIconWind("ui icon icon-wind-low");
    else if(res.windEs === "Moderado") setIconWind("ui icon icon-wind-mod");
    else if(res.windEs === "Fuerte") setIconWind("ui icon icon-wind-strong");

    if(res.occupationEs === "Completo") setIconOccupation("ui icon icon-occupation-high");
    else if(res.occupationEs === "Baja") setIconOccupation("ui icon icon-occupation-low");
    else if(res.occupationEs === "Media") setIconOccupation("ui icon icon-occupation-medium");

    if(res.parkingEs === "Completo") setIconParking("ui icon icon-parking-full");
    else if(res.parkingEs === "Baja") setIconParking("ui icon icon-parking-low");
    else if(res.parkingEs === "Media") setIconParking("ui icon icon-parking-mid");
    else if(res.parkingEs === "SinRegistro") setIconParking("ui icon icon-parking-not");

    if(res.wavesEs === "Flojo") setIconWaves("ui icon icon-waves-low");
    else if(res.wavesEs === "Moderado") setIconWaves("ui icon icon-waves-mod");
    else if(res.wavesEs === "Fuerte") setIconWaves("ui icon icon-waves-strong");

    else if(res.temperatureEs !== "Sin Datos") setIconTemp("ui icon icon-temperature");
  }

  function setLang(lang){
      if(lang === "ES"){
          setHideEsComponent(false)
          setHideEuComponent(true)
      }else{
          setHideEsComponent(true)
          setHideEuComponent(false)
      }
  }
  function cancel(){
        history.goBack();
  }
  function formateDate(uploadDate){
      if(uploadDate !== null && uploadDate !== "" && uploadDate !== undefined){
        var date = new Date(uploadDate);    
        return  format(date, "dd-MM-yyyy");
      } else return "-"
  }
  function formatHour(uploadDate){
      if(uploadDate !== null && uploadDate !== "" && uploadDate !== undefined){
        var date = new Date(uploadDate);    
        return format(date, "HH:mm");
      } else return "-"
  }
  function handleSelect(){
    history.push("/beaches");
  }
  return (

    <>
    <div className="ui large breadcrumb" style={{paddingBottom:15}}>
        <i className="home red icon"></i>
        <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="playas.tituloMinus" defaultMessage="Playas"/></a>
        <i className="right large angle icon divider"></i>
        <i className="section" >{viewBeaches.beachName}</i>
    </div>
    <div className="mainDiv">
      <div className='ui grid' >
        <div className="middle aligned content">
          <div><h1 className='title'>{viewBeaches.beachName}</h1></div>
        </div>
        <div className="red-box" style={{paddingTop:20, height:50, marginRight:8, width:800}} >
          <select className="ui red-Select select" style={{marginLeft:600}} name="selectType" onChange={event => setLang(event.target.value)}>
            <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
            {(message) => <option value="ES">{message}</option>}
            </FormattedMessage>
            <FormattedMessage id="euskera" defaultMessage="EUSKERA">
            {(message) => <option value="EU">{message}</option>}
            </FormattedMessage>
          </select>
        </div> 
      </div>
      <div className="posLeft">
        <div className='ui grid' >
          <div className="ui divided items">
            <Popup trigger={<button className='search-icon'><i className="ui white search icon"></i></button>}>
              <img style={{width:500}} src={viewBeaches.urlImg} alt="Imagen" />
            </Popup>
            <img style={{width:400, paddingLeft:16, float:"left"}}  src={viewBeaches.urlImg} alt="Imagen" />
            <span><FormattedMessage id="playas.fotografo" defaultMessage="Fotógrafo" />: {viewBeaches.photograph}</span>
          </div>         
        </div>
      </div>
      <div className="posRight">
        <div className='ui grid'>
          <table className='playasTable'>
            <tbody>
              <tr>
                <td colSpan="2"><label><FormattedMessage id="playas.nombre" defaultMessage="NOMBRE" /></label></td>
                <td colSpan="2"><label><FormattedMessage id="playas.poblacion" defaultMessage="POBLACIÓN" /></label></td>
                <td colSpan="2"><label><FormattedMessage id="playas.fechaActua" defaultMessage="FECHA ACTUALIZACIÓN" /></label></td>
              </tr>
              <tr>
                <td colSpan="2">{viewBeaches.beachName}</td>
                <td colSpan="2">{viewBeaches.cityName}</td>
                <td colSpan="2">{formateDate(viewBeaches.updateTime)}</td>
              </tr>
              <tr>
                <td rowSpan="2"><i className={iconWeather}></i></td>
                <td><label><FormattedMessage id="playas.tiempo" defaultMessage="TIEMPO" /></label></td>
                <td rowSpan="2"><i className={iconTemp}></i></td>
                <td><label><FormattedMessage id="playas.temperatura" defaultMessage="TEMPERATURA" /></label></td>
                <td rowSpan="2"><i className={iconWind}></i></td>
                <td><label><FormattedMessage id="playas.viento" defaultMessage="VIENTO" /></label></td>
              </tr>
              <tr>
                <td>{language !== "es-Es" ? viewBeaches.weatherEs : viewBeaches.weatherEu}</td>
                <td>{language !== "es-Es" ? viewBeaches.temperatureEs : viewBeaches.temperatureEu}</td>
                <td>{language !== "es-Es" ? viewBeaches.windEs : viewBeaches.windEu}</td>
              </tr>
              <tr>
                <td rowSpan="2"><i className={iconOccupation}></i></td>
                <td><label><FormattedMessage id="playas.ocupacion" defaultMessage="OCUPACIÓN" /></label></td>
                <td rowSpan="2"><i className={iconParking}></i></td>
                <td><label><FormattedMessage id="playas.aparcamiento" defaultMessage="APARCAMIENTO" /></label></td>
                <td rowSpan="2"><i className="ui icon-asistido icon"></i></td>
                <td><label><FormattedMessage id="playas.banoAsistido" defaultMessage="BAÑO ASISTIDO" /></label></td>
              </tr>
              <tr>
                <td>{language !== "es-Es" ? viewBeaches.occupationEs : viewBeaches.occupationEu}</td>
                <td>{language !== "es-Es" ? viewBeaches.parkingEs : viewBeaches.parkingEu}</td>
                <td>{language !== "es-Es" ? viewBeaches.assistedEs : viewBeaches.assistedEu}</td>
              </tr>
              <tr>
                <td rowSpan="2"><i className={iconFlag}></i></td>
                <td><label><FormattedMessage id="playas.bandera" defaultMessage="BANDERA" /></label></td>
                <td rowSpan="2"><i className="ui icon-temp-water icon"></i></td>
                <td><label><FormattedMessage id="playas.agua" defaultMessage="AGUA" /></label></td>
                <td rowSpan="2"><i className={iconWaves}></i></td>
                <td><label><FormattedMessage id="playas.oleaje" defaultMessage="OLEAJE" /></label></td>
              </tr>
              <tr>
                <td>{language !== "es-Es" ? viewBeaches.flagEs : viewBeaches.flagEu}</td>
                <td>{viewBeaches.tempWater}</td>
                <td>{language !== "es-Es" ? viewBeaches.wavesEs : viewBeaches.wavesEu}</td>
              </tr>
              <tr>
                <td rowSpan="2"><i className="ui icon-hightide icon"></i></td>
                <td colSpan="3"><label><FormattedMessage id="playas.pleamar" defaultMessage="PLEAMAR" /></label></td>
                <td rowSpan="2"><i className="ui icon-lowtide icon"></i></td>
                <td colSpan="2"><label><FormattedMessage id="playas.bajamar" defaultMessage="BAJAMAR" /></label></td>
              </tr>
              <tr>
                <td colSpan="3">
                  {formatHour(viewBeaches.highTideTime1)}h ({viewBeaches.highTide1})/
                  {formatHour(viewBeaches.highTideTime2)}h ({viewBeaches.highTide2})
                </td>
                <td colSpan="2">
                  {formatHour(viewBeaches.lowTideTime1)}h ({viewBeaches.lowTide1})/
                  {formatHour(viewBeaches.lowTideTime2)}h ({viewBeaches.lowTide2})
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
    <div className='ui grid' style={{marginTop:50}}>
      <div className='six wide column'>
        <label><b><FormattedMessage id="playas.aviso" defaultMessage="AVISO" /></b></label>
        <p>{language !== "es-Es" ? viewBeaches.warningEs : viewBeaches.warningEu}</p>
        <label><b><FormattedMessage id="playas.avisoSecundario" defaultMessage="AVISO SECUNDARIO" /></b></label>
        <p>{language !== "es-Es" ? viewBeaches.warningTextEs : viewBeaches.warningTextEu}</p>
        <label><b><FormattedMessage id="playas.temporada" defaultMessage="TEMPORADA" /></b></label>
      </div>
    </div>
    <div className='ui grid' style={{marginTop:50}}>
          <table className="playasTable">
            <thead>
              <tr>
                <td><b><label><FormattedMessage id="playas.franja" defaultMessage="FRANJA" /></label></b></td>
                <td><b><label><FormattedMessage id="playas.inicio" defaultMessage="INICIO" /></label></b></td>
                <td><b><label><FormattedMessage id="playas.fin" defaultMessage="FIN" /></label></b></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><FormattedMessage id="playas.summer" defaultMessage="Verano" /></td>
                <td>{formateDate(initialDate)}</td>
                <td>{formateDate(finalDate)}</td>
              </tr>
            </tbody>
          </table>
    </div>
    <div className='grid'>
      <button className="ui noBorder button" onClick={() => cancel()}>
        <FormattedMessage id="playas.volver" defaultMessage="Volver" />
      </button>
    </div> 
  </div>
  </>
)}
         
export default ViewBeaches;
